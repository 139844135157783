import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  const onRedirectCallback = (appState) => {
    console.log('appState :', appState.returnTo);
/*    localStorage.setItem(
      'ztoAppState',
      appState.returnTo == '/' ? '/homepage' : appState.returnTo
    );*/
      localStorage.setItem('ztoAppState', '/homepage');

  //  history.push(appState?.returnTo || window.location.pathname);
  };
  let instancename = window.location.hostname.split('.')[0];
  console.log('instancename :', instancename);
  // si on est sur localhost on force la connexion sur sisley pour éviter d'avoir des erreurs de connexion en dev
  if (
    instancename == 'localhost' ||
    instancename == 'recette-quable-portail' ||
    instancename == 'dev-quable-portail'
  )
    instancename = 'sisley';

  const [loaded, setLoaded] = useState(false);
  const [extraParams, setExtraParams] = useState({});
  const instance = axios.create('');
  const auth0API = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/api/v2/';

  useEffect(() => {
    let instance_data_url =
      process.env.REACT_APP_QUABLE_API_PROTOCOL +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/instances/' +
      instancename;
    instance
      .get(instance_data_url)
      .then((resp) => {
        const titlePortal = resp.data.parameters.title;
        const logoLogin = resp.data.parameters.logoUrl;
        const backgroundUrl = resp.data.parameters.loginBackgroundUrl;
        const colorG = resp.data.parameters.theme;
          localStorage.setItem('logoUrl',resp.data.parameters.logoUrl)
          localStorage.setItem('colorG',resp.data.parameters.theme)
        setExtraParams(
          JSON.stringify({
            title: titlePortal,
            logo: logoLogin,
            backgroundUrl: backgroundUrl,
            theme: colorG,
          })
        );
        setLoaded(true);
      })
      .catch((err) => {});
  });

  //const extraParams=JSON.stringify({title:"PORTAIL SISLEY",logo:"https://images.prismic.io/sisley-demo%2F9059bdef-eaa9-412c-a117-4ab7b02dcabf_logo-sisley.png?auto=compress,format"})

  if (loaded) {
    return (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        audience={auth0API}
        onRedirectCallback={onRedirectCallback}
        zentoParams={extraParams}
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return (
      <div className='center'>
        <Loader type='TailSpin' color='black' height={100} width={100} />
      </div>
    );
  }
};

export default Auth0ProviderWithHistory;
