import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import '../styles/components/MaterialUITreeView.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { setMediasTreeViewExpanded } from '../redux/actions/mediasActions';
import { setCanalTreeViewExpanded } from '../redux/actions/canalActions';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    width: 400,
    // minWidth: 300,
  },
  label1: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color: 'black',
    fontSize: 13,
    padding: '6px 0px 6px 8px',
    '&:hover': {},
    '&:focus': {},
  },
  label2: {
    fontFamily: 'Noto Sans',
    backgroundColor: 'white',
    color: 'black',
    fontSize: 13,
    padding: '6px 0px 6px 8px',
    '&:hover': {},
    '&:focus': {},
  },
  // content: {
  //   '&:hover': {},
  //   '&:focus': {},
  // },
  // selected: {
  //   '&:focus': {},
  // },
  // expanded: {},
});

const MaterialUITreeView = (props) => {
  const classes = useStyles();

  function handleClick(index) {
    // let newExpanded = expanded;
    // let justDeleted = false;
    // newExpanded = newExpanded.filter((item) => {
    //   if (item === index) {
    //     justDeleted = true;
    //   }
    //   return item !== index && index > item.length;
    // });
    // if (!justDeleted) {
    //   newExpanded.push(index);
    // }
    // setExpanded(newExpanded);
  }

  function onNodeSelect(event, nodeId) {
    let newExpanded = props.expanded;
    let justDeleted = false;
    newExpanded = newExpanded.filter((item) => {
      if (item === nodeId) {
        justDeleted = true;
      }
      return item !== nodeId && nodeId.length > item.length;
    });
    let newPath = props.path.slice(0, newExpanded.length);
    if (!justDeleted) {
      newExpanded.push(nodeId);
      newPath = props.path.slice(0, newExpanded.length);
    }
    if (
      newExpanded.length < props.expanded.length &&
      newExpanded[newExpanded.length - 1] ===
        props.expanded[newExpanded.length - 1]
    ) {
      props.updateHistory(
        props.path.slice(1, props.expanded.indexOf(nodeId) + 1).join('+')
      );
    } else if (newExpanded.length > props.expanded.length) {
      let p = [...props.path];
      p.push(props.currentElts[parseInt(nodeId[nodeId.length - 1])][3]);
      props.updateHistory(p.slice(1, p.length).join('+'));
    } else if (newExpanded.length > 1) {
      newPath = [
        ...newPath,
        props.currentPathItems[newExpanded.length - 1][1][0][
          parseInt(nodeId[nodeId.length - 1])
        ][3],
      ];
      props.updateHistory(newPath.slice(1, newPath.length).join('+'));
    } else {
      newPath = [
        ...newPath,
        props.currentPathItems[newExpanded.length - 1][1][
          parseInt(nodeId[nodeId.length - 1])
        ][3],
      ];
      props.updateHistory(newPath.slice(1, newPath.length).join('+'));
    }
  }

  function setArborescence(item, parentIndex, index) {
    if (
      Array.isArray(item) &&
      Array.isArray(item[1]) &&
      !Array.isArray(item[0]) &&
      item.length === 2
    ) {
      return (
        <TreeItem
          onClick={() => handleClick(index)}
          className='testtest'
          classes={
            (parentIndex.toString() + index.toString()).length === 1
              ? {
                  label: classes.label1,
                }
              : { label: classes.label2 }
          }
          nodeId={parentIndex.toString() + index.toString()}
          label={item[0]}
        >
          {setArborescence(
            item[1],
            parentIndex.toString() + index.toString(),
            ''
          )}
        </TreeItem>
      );
    } else if (Array.isArray(item)) {
      return item.map((produit, i) =>
        setArborescence(produit, parentIndex.toString() + index.toString(), i)
      );
    } else {
      return (
        <TreeItem
          classes={
            (parentIndex.toString() + index.toString()).length === 1
              ? {
                  label: classes.label1,
                }
              : { label: classes.label2 }
          }
          nodeId={parentIndex.toString() + index.toString()}
          label={item}
          icon={<FontAwesomeIcon icon={faFolder} style={{ color: 'black' }} />}
        ></TreeItem>
      );
    }
  }

  return (
    <TreeView
      classes={{
        root: classes.root,
      }}
      defaultCollapseIcon={
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ color: 'black', width: 15, height: 15 }}
        />
      }
      defaultExpandIcon={
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{ color: 'black', width: 15, height: 15 }}
        />
      }
      expanded={props.expanded}
      onNodeSelect={(event, nodeId) => onNodeSelect(event, nodeId)}
    >
      {props.items.map((item, index) => setArborescence(item, '', index))}
    </TreeView>
  );
};

MaterialUITreeView.propTypes = {
  items: PropTypes.array,
};

export default connect()(MaterialUITreeView);
