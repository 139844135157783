import axios from 'axios'
import {
  REQUEST_CANAL,
  RECEIVE_CANAL,
  REQUEST_CANAL_FAILED,
  UPDATE_PATH,
  UPDATE_VISIBLE_PATH_ITEMS,
  UPDATE_CURRENT_PATH_ITEMS,
  SET_CURRENT_PATH_PRODUCTS_LENGTH,
  ADD_CURRENT_PATH_PRODUCT,
  RESET_CURRENT_PATH_PRODUCTS,
  REQUEST_PRODUCTS_FAILED,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  SET_CANAL_TREEVIEW_EXPANDED,
  SET_CURRENT_CANAL_CLASSIFICATION_ID,
  RECEIVE_CLASSIFICATION_IDS,
  SET_IDS_TO_IMAGES
} from './types'

export const createArborescenceList = (data, locale) =>
  data.map((item) =>
    item.children !== undefined && 0 < item.children.length
      ? [item.fieldData.quable_classification_name.values[locale], createArborescenceList(item.children, locale)]
      : item.fieldData.quable_classification_name.values[locale]
  )

export const createArborescenceListOfIds = (data, locale) =>
  data.map((item) =>
    item.children !== undefined && 0 < item.children.length
      ? [
          {
            value: item.id,
            label: item.fieldData.quable_classification_name.values[locale]
          },
          createArborescenceListOfIds(item.children, locale)
        ]
      : {
          value: item.id,
          label: item.fieldData.quable_classification_name.values[locale]
        }
  )

export const fetchCanal = (locale, canalId) => (dispatch) => {
  dispatch(requestCanal())
  dispatch(setCanalTreeViewExpanded([]))
  return axios
    .get(`/classifications/tree-view`)
    .then((response) => {
      const newButtons = buttonsSetup(response.data['hydra:member'][0].children, '', locale)
      const newPath = pathSetup([], locale)
      dispatch(updateCurrentPathItems([['Produits', newButtons]]))
      dispatch(updateVisiblePathItems(newButtons))
      dispatch(updateCanalPath(newPath))
      dispatch(
        receiveClassificationIds(
          createArborescenceListOfIds(response.data['hydra:member'][0].children, locale).flat(Infinity)
        )
      )
      dispatch(receiveCanal(createArborescenceList(response.data['hydra:member'][0].children, locale)))
      return axios.get(`/classifications?catalogs.id=${canalId}`)
    })
    .then((response) =>
      axios.get(`/classifications?catalogs.id=${canalId}&limit=${response.data['hydra:totalItems']}&pagination=false`)
    )
    .then((response) => {
      const idsToImages = {}
      response.data['hydra:member'].map((classification) => {
        idsToImages[classification.id] = classification.mainAssetUrl
      })
      dispatch(setIdsToImages(idsToImages))
    })
    .catch((err) => {
      dispatch(requestCanalFailed(err))
    })
}

export const updateCanalPath = (path) => ({
  type: UPDATE_PATH,
  payload: path,
  changedAt: Date.now()
})

export const updateVisiblePathItems = (items) => ({
  type: UPDATE_VISIBLE_PATH_ITEMS,
  payload: items,
  changedAt: Date.now()
})

export const updateCurrentPathItems = (items) => ({
  type: UPDATE_CURRENT_PATH_ITEMS,
  payload: items,
  changedAt: Date.now()
})

export const initializeItemsContainerAction = (id, nbPage, nbValues, locale, instancename) => (dispatch) => {
  dispatch(requestProducts())
  return axios
    .get(`/documents?classifications.id=${id}&page=${nbPage}&limit=${nbValues}`)
    .then(async (response) => {
      if (0 === response.data['hydra:totalItems']) {
        dispatch(receiveProducts())
      }
      dispatch(resetCurrentPathProducts())
      dispatch(setCurrentPathProductsLength(response.data['hydra:totalItems']))
      let lastIndex
      await Promise.all(
        response.data['hydra:member'].map(async (item, index) => {
          lastIndex = index
          dispatch(addCurrentPathProduct(item))
        })
      )

      if (
        lastIndex === nbValues - 1 ||
        nbValues * nbPage - (nbValues - lastIndex) === response.data['hydra:totalItems'] - 1
      ) {
        dispatch(receiveProducts())
      }
    })
    .catch((err) => {
      dispatch(requestProductsFailed(err))
    })
}

export const resetCurrentPathProducts = () => ({
  type: RESET_CURRENT_PATH_PRODUCTS,
  changedAt: Date.now()
})

export const requestProducts = () => ({
  type: REQUEST_PRODUCTS,
  receivedAt: Date.now()
})

export const receiveProducts = () => ({
  type: RECEIVE_PRODUCTS,
  receivedAt: Date.now()
})

export const setCanalTreeViewExpanded = (expanded) => ({
  type: SET_CANAL_TREEVIEW_EXPANDED,
  payload: expanded,
  changedAt: Date.now
})

export const setCurrentCanalClassificationId = (id) => ({
  type: SET_CURRENT_CANAL_CLASSIFICATION_ID,
  payload: id,
  addedAt: Date.now()
})

export const receiveClassificationIds = (data) => ({
  type: RECEIVE_CLASSIFICATION_IDS,
  payload: data,
  receivedAt: Date.now()
})

// Internal acition

const setIdsToImages = (data) => ({
  type: SET_IDS_TO_IMAGES,
  payload: data,
  receivedAt: Date.now()
})

const requestProductsFailed = (err) => ({
  type: REQUEST_PRODUCTS_FAILED,
  payload: err
})

const addCurrentPathProduct = (product) => ({
  type: ADD_CURRENT_PATH_PRODUCT,
  payload: product,
  addedAt: Date.now()
})

const setCurrentPathProductsLength = (totalLength) => ({
  type: SET_CURRENT_PATH_PRODUCTS_LENGTH,
  payload: totalLength,
  addedAt: Date.now()
})

const requestCanalFailed = (err) => ({
  type: REQUEST_CANAL_FAILED,
  payload: err
})

export const receiveCanal = (data) => ({
  type: RECEIVE_CANAL,
  payload: data,
  receivedAt: Date.now()
})

export const requestCanal = () => ({
  type: REQUEST_CANAL
})

const buttonsSetup = (buttons, parentIndex, locale) => {
  const newButtons = buttons.map((item, index) => [
    item.fieldData.quable_classification_name.values[locale],
    '',
    buttonsSetup(item.children, parentIndex.toString() + index.toString(), locale),
    item.id,
    item.dateCreated,
    item.dateModified,
    parentIndex.toString() + index.toString()
  ])
  return newButtons
}

const pathSetup = (followedPath, locale) => {
  const newPath = followedPath.map((item, index) => item.fieldData.quable_classification_name.values[locale])
  return newPath
}
