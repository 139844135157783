import { ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART } from './types'

export const addToCart = (data) => ({
  type: ADD_TO_CART,
  payload: data
})

export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: {
    productId
  }
})

export const emptyCart = () => ({
  type: EMPTY_CART
})
