import React, { useState, useEffect } from 'react';
import '../styles/pages/Parametres.css';
import { Header, SubHeader } from '../components';
import { UserForm } from '../components/authentification';
import { Translate } from 'react-redux-i18n';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faUserPlus,
  faPlusCircle,
  faProjectDiagram,
  faEdit,
  faBan,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import { ParamMenu } from '../components';
import { getUserList, deleteUser } from '../redux/actions/adminAction';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Container, Row, Col } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import Loader from 'react-loader-spinner';
import { AudienceForm } from '../components/authentification';

const Parametres = (props) => {
  var gridApi;
  const initialPageSize = 5;
  const [gridEditApi, setGridEditApi] = useState({});
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridLoaded, setGridLoaded] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [userSelected, setUserSelected] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [createMode, setCreateMode] = useState(false);
  const [audienceSelected, setAudienceSelected] = useState({});

  var I18n = require('react-redux-i18n').I18n;

  const closeModal = () => {
    setGridLoaded(false);
    setOpen(false);
    setTimeout(() => {
      callApi();
    }, 1000);
  };

  useEffect(() => {
    callApi();
  }, []);

  const onGridReady = (params) => {
    gridApi = params.api;
    setGridEditApi(params.api);
    setGridColumnApi(params.columnApi);
    // gridApi.sizeColumnsToFit();
    gridApi.paginationSetPageSize(pageSize);
  };

  const onPageSizeChanged = (e) => {
    setPageSize(Number(e.target.value));
    gridEditApi.paginationSetPageSize(e.target.value);
  };

  function onFilterTextBoxChanged(e) {
    gridEditApi.setQuickFilter(e.target.value);
  }

  function buttonRenderer() {
    return (
      <div>
        <a onClick={onButtonClick} className='action-button'>
          <FontAwesomeIcon className='parametres-table-btn1' icon={faEdit} />
        </a>
        <a onClick={submit} className='action-button'>
          <FontAwesomeIcon className='parametres-table-btn2' icon={faTimes} />
        </a>
      </div>
    );
  }

  const pageNumberMap = () => {
    let numberArray = [];
    for (let i = 10; i <= 50; i += 10) {
      numberArray.push({ value: i });
    }
    return numberArray;
  };

  const callApi = async () => {
    const token = await getIdTokenClaims();
    props.dispatch(getUserList(token)).then(() => {
      setGridLoaded(true);
    });
  };
  const onButtonClick = (e) => {
    const selectedNodes = gridApi.getSelectedNodes();
    // console.log(selectedNodes)
    const selectedData = selectedNodes.map((node) => node.data);
    //console.log(selectedData)
    const selectedDataStringPresentation = selectedData
      .map((node) => node.user + ' ' + node.model)
      .join(', ');
    //alert(`Selected nodes: ${selectedDataStringPresentation}`)
    setUserSelected(selectedData[0]);
    setOpen((o) => !o);
  };

  const onDeleteClick = (e) => {
    const selectedNodes = gridApi.getSelectedNodes();
    //console.log(selectedNodes)
    const selectedData = selectedNodes.map((node) => node.data);
    // console.log(selectedData)
    const selectedDataStringPresentation = selectedData
      .map((node) => node.user + ' ' + node.model)
      .join(', ');
    //alert(`Selected nodes: ${selectedDataStringPresentation}`)

    getIdTokenClaims().then((token) => {
      props.dispatch(deleteUser(token, selectedData[0])).then((o) => {
        callApi();
      });
    });
  };

  // Ouverture de la PopUp en mode création

  const openInCreateMode = () => {
    let newAudience = { name: '', filters: {} };
    newAudience.filters.attributes = { zone_de_diffusion_codes: '' };
    setAudienceSelected(newAudience);
    setCreateMode(true);
    setOpen2((o) => !o);
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirmation',
      message:
        'La suppression de cet élément est irréversible et ne peut être annulée',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => onDeleteClick(),
        },
        {
          label: 'Annuler',
          onClick: () => {},
        },
      ],
    });
  };

  const columnDefs = [
    {
      headerName: 'Nom',
      field: 'familyName',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Prenom',
      field: 'givenName',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Role',
      field: 'role',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },

    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Audience',
      field: 'audience',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: 'buttonRenderer',
    },
  ];

  const gridContent = () => {
    return !gridLoaded ? (
      <div className='parametres-right'>
        <Loader type='TailSpin' color='black' height={100} width={100} />
      </div>
    ) : (
      <div className='parametres-right'>
        <Row className='row_bottom_margin'>
          <Col xs='8'>
            <Translate value='settings.listUsers' />
          </Col>
          <Col xs='4'>
            <input
              type='text'
              id='filter-text-box'
              placeholder={I18n.t('settings.searchUser')}
              onInput={onFilterTextBoxChanged}
              className='grid-search-box'
            />
          </Col>
        </Row>
        <div className='ag-theme-alpine' style={{ width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            frameworkComponents={{ buttonRenderer: buttonRenderer }}
            rowData={props.userlist}
            pagination={true}
            paginationPageSize={initialPageSize}
            domLayout='autoHeight'
            onGridReady={onGridReady}
            rowSelection='single'
          ></AgGridReact>
          <Row className='page-size-select-row'>
            <Col xs='12'>
              <Translate value='settings.show' />
              <select
                onChange={onPageSizeChanged}
                id='page-size'
                className='page-size-select'
              >
                {pageNumberMap().map((increment) =>
                  increment.value == pageSize ? (
                    <option value={increment.value} selected>
                      {increment.value}
                    </option>
                  ) : (
                    <option value={increment.value}>{increment.value}</option>
                  )
                )}
              </select>{' '}
              <Translate value='settings.perPage' />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <SubHeader>
        <Translate value='settings.title' />
      </SubHeader>
      <div className='parametres-container'>
        <div className='parametres-subcontainer'>
          <ParamMenu openInCreateMode={openInCreateMode} />
          {gridContent()}
        </div>
      </div>
      <Footer></Footer>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={{
          width: '33%',
          margin: 'auto',
          border: '1px solid black',
          height: '70%',
          borderRadius: '5px',
        }}
        arrow={true}
      >
        {(close) => <UserForm userSelected={userSelected} closePopUP={close} />}
      </Popup>
      <Popup
        open={open2}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={{
          width: '35%',
          margin: 'auto',
          border: '1px solid black',
          padding: '5px',
          height: '70%',
          borderRadius: '5px',
        }}
        modal
        position='top center'
      >
        {(close) => (
          <AudienceForm
            audienceSelected={audienceSelected}
            createMode={createMode}
            closePopUP={close}
          />
        )}
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  //console.log(state)
  return {
    userlist: state.adminReducer.userlist,
    editeduser: state.adminReducer.editeduser,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Parametres
);
