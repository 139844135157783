import { faThList } from '@fortawesome/free-solid-svg-icons'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { Header, SubHeader, ProductsBlocView, ProductsListView } from '../../components'
import '../../styles/pages/Favoris.css'
import Footer from '../../components/Footer'

const Favorite = ({ wishlist }) => {
  const [viewbloccolor, setViewbloccolor] = useState('black')
  const [viewlistcolor, setViewlistcolor] = useState('lightgrey')
  const [itemview, setItemview] = useState(
    <ProductsBlocView bloctxtcolor={'black'} star={true} tests={wishlist} whereFrom="Favoris" />
  )

  const handleBlocClick = () => {
    setViewbloccolor('black')
    setViewlistcolor('lightgrey')
    setItemview(<ProductsBlocView bloctxtcolor={'black'} star={true} tests={wishlist} whereFrom="Favoris" />)
  }

  const handleListClick = () => {
    setViewbloccolor('lightgrey')
    setViewlistcolor('black')
    setItemview(<ProductsListView star={true} tests={wishlist} whereFrom="Favoris" />)
  }

  return (
    <>
      <Header />
      <SubHeader type="Favourites">
        <Translate value="favourites.title" />
      </SubHeader>
      {0 === wishlist.length ? (
        <div className="favoris-noresult-container">
          <div className="favoris-noresult-subcontainer">
            <p className="favoris-noresult-h1">
              <Translate value="favourites.empty" />
            </p>
            <p className="favoris-noresult-p">
              <Translate value="favourites.favouritesIsEmptyMsg" />
            </p>
            <Link className="favoris-noresult-btn" to="/homepage">
              <Translate value="favourites.return" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="favoris-container">
          <div className="favoris-subcontainer">
            <div className="switchviewcontainer">
              <FontAwesomeIcon
                className="favorisbloc"
                icon={faThLarge}
                style={{ color: viewbloccolor }}
                onClick={handleBlocClick}
              />
              <FontAwesomeIcon
                className="favorislist"
                icon={faThList}
                style={{ color: viewlistcolor }}
                onClick={handleListClick}
              />
            </div>
            <div className="favoris-itemview">{itemview}</div>
          </div>
        </div>
      )}
      <Footer></Footer>
    </>
  )
}

Favorite.propTypes = {
  wishlist: PropTypes.array
}

const mapStateToProps = (state) => ({
  wishlist: state.user.wishlist
})

export default connect(mapStateToProps)(Favorite)
