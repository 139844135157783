import axios from 'axios'
import {
  USER_LIST,
  USER_STATUS,
  ADMIN_INSTANCE_DATA,
  ADMIN_USER_INFO,
  ADMIN_GET_ALL_AUDIENCES,
  ADMIN_UPDATE_AUDIENCE,
  ADMIN_DELETE_USER,
  ADMIN_GET_ALL_ATTRIBUTES,
  ADMIN_UPDATE_ATTRIBUTE
} from './types'

// Exported Action
export const getUserList = () => (dispatch) =>
  axios
    .get(`/user_instances`)
    .then((response) => {
      const instanceMembers = response.data['hydra:member']
      const userPromises = instanceMembers.map((value) => axios.get(value.user))
      return Promise.all(userPromises)
    })
    .then((users) => {
      const result = users
        .map((user) => ({
          givenName: user.data.givenName,
          familyName: user.data.familyName,
          role: user.data.role,
          email: user.data.username
        }))
        .sort((a, b) => a.familyName.localeCompare(b.familyName))

      dispatch(getUserItemsInternal(result))
    })

export const getUserInfo = (token, userID) => (dispatch) =>
  axios.get(userID).then((response) => {
    dispatch(getUserInfoInternal(response.data))
  })

export const updateUserInfo = (token, user) => (dispatch) =>
  axios
    .put(user.user, {
      role: user.role,
      givenName: user.givenName ? user.givenName : '',
      familyName: user.familyName ? user.familyName : ''
    })
    .then(() => {
      const instanceUpdater = { status: user.status, audience: null }
      if (user.audience) instanceUpdater.audience = user.audience
      axios.put(user['@id'], instanceUpdater).then((response) => {
        dispatch(storeUserAudience(token, null !== response.data.audience ? response.data.audience : ''))
      })
    })

export const deleteUser = (token, user) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`
    }
  })
  return (dispatch) =>
    instance
      .delete(user['@id'])
      .then((response) => {
        dispatch(deleteUserInternal(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
}

export const storeUserInfo = (quableuserinstance, quableuser) => (dispatch) => {
  dispatch(setUserInfo(quableuserinstance, quableuser))
}

export const getAudiences = (token, userID) => (dispatch) =>
  axios
    .get('/audiences')
    .then((response) => {
      dispatch(getAllAudiencesInternal(response.data['hydra:member']))
    })
    .catch((err) => {
      console.log(err)
    })

export const storeUserAudience = (token, audienceId) => (dispatch) =>
  axios
    .get(audienceId)
    .then((response) => {
      dispatch(storeUserAudience(response.data))
    })
    .catch((err) => {
      console.log(err)
    })

export const updateAudience = (token, audience) => (dispatch) =>
  axios
    .put(audience['@id'], {
      name: audience.name,
      filters: audience.filters
    })
    .then((response) => {
      dispatch(updateAudienceInternal(response.data))
    })

export const createaudience = (token, audience) => {
  const newAudience = {
    name: audience.name,
    filters: {
      attributes: {
        // eslint-disable-next-line camelcase
        zone_de_diffusion_codes: audience.filters.attributes.zone_de_diffusion_codes
      }
    },
    id: audience.name.replace(/\s/g, '').toLowerCase()
  }
  return (dispatch) =>
    axios
      .post('/audiences', newAudience)
      .then((response) => {
        dispatch(updateAudienceInternal(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
}

export const deleteAudience = (token, audience) => (dispatch) =>
  axios
    .delete(audience['@id'])
    .then((response) => {
      dispatch(updateAudienceInternal(response.data))
    })
    .catch((err) => {
      console.log(err)
    })

export const getAttributes = () => (dispatch) =>
  axios
    .get('/attributes')
    .then((response) => {
      dispatch(getAllattributesInternal(response.data['hydra:member']))
    })
    .catch((err) => {
      console.log(err)
    })

export const updateattribute = (token, attribute) => (dispatch) =>
  axios
    .put(attribute['@id'], {
      isPrimary: attribute.isPrimary,
      isFilterable: attribute.isFilterable
    })
    .then((response) => {
      dispatch(updateAttributeInternal(response.data))
    })
    .catch((err) => {
      console.log(err)
    })

export const storeInstanceData = (instanceData) => ({
  payload: instanceData,
  type: ADMIN_INSTANCE_DATA
})

// Internal Action
const updateAttributeInternal = (attribute) => ({
  payload: attribute,
  type: ADMIN_UPDATE_ATTRIBUTE
})

const getUserItemsInternal = (items) => ({
  payload: items,
  type: USER_LIST
})

const updateAudienceInternal = (audience) => ({
  payload: audience,
  type: ADMIN_UPDATE_AUDIENCE
})

const setUserInfo = (quableuserinstance, quableuser) => ({
  payload: { quableuserinstance, quableuser },
  type: USER_STATUS
})

const deleteUserInternal = (userdeleted) => ({
  payload: userdeleted,
  type: ADMIN_DELETE_USER
})

const getAllAudiencesInternal = (items) => ({
  payload: items,
  type: ADMIN_GET_ALL_AUDIENCES
})
const getAllattributesInternal = (items) => ({
  payload: items,
  type: ADMIN_GET_ALL_ATTRIBUTES
})

const getUserInfoInternal = (user) => ({
  payload: user,
  type: ADMIN_USER_INFO
})

// TODO: clear later
// const updateUser = (updatedUser) => ({
//   payload: updatedUser,
//   type: ADMIN_UPDATE_USER_INFO
// })
// const storeUserAudienceInternal = (userAudience) => ({
//   payload: userAudience,
//   type: ADMIN_STORE_USER_AUDIENCE
// })

// const resetStore = () => ({
//   type: RESET_STORE
// })
