import axios from 'axios'
import { SET_LANGUAGES } from './types'

export const fetchLanguages = () => (dispatch) =>
  axios
    .get(`/locales`)
    .then((response) => {
      const langs = {}
      response.data['hydra:member'].forEach((lang) => {
        if (lang.active) {
          langs[lang.id] = lang.name['en-US']
        }
      })
      dispatch(setLanguages(langs))
    })
    .catch((err) => {
      console.log(`fetchLanguages err ->`, err)
    })

const setLanguages = (langs) => ({
  type: SET_LANGUAGES,
  payload: langs
})
