import React, { useState, useEffect } from 'react';
import {
  Header,
  SubHeader,
  HeadingText1,
  ListButtons,
  ProductsBlocView,
} from '../components';
import '../styles/pages/Homepage.css';
import { Link } from 'react-router-dom';
import Page from '../fromprismic/Page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { fetchCanal } from '../redux/actions/canalActions';
import { fetchMedias } from '../redux/actions/mediasActions';
import { fetchDocumentsMAJ } from '../redux/actions/homepageActions';
import Footer from '../components/Footer';
import { fetchSearchParameters } from '../redux/actions/searchActions';

const Homepage = (props) => {
  var favoris;

  useEffect(() => {
    if (props.instancedata.pimParameters !== undefined) {
      props.dispatch(
        fetchSearchParameters(
          props.locale,
          props.instancedata.id
        )
      );
      props.dispatch(
        fetchDocumentsMAJ(
          props.classificationIds.map((item) => item['value']),
          props.mediasClassificationIds.map((item) => item['value']),
          1,
          20,
          props.instancedata.id
        )
      );
      props.dispatch(
        fetchCanal(
          props.locale,
          props.instancedata.pimParameters.classificationCatalog,
          props.instancedata.id
        )
      );
      props.dispatch(fetchMedias(props.locale, props.instancedata.id));
    }
  }, [props.instancedata.pimParameters]);

  if (props.wishlist.length > 0) {
    favoris = (
      <div>
        <Fade top>
          <HeadingText1 className='heading'>
            <Translate value='homepage.favoris' />
            {/* {t('homepage.products')} */}
          </HeadingText1>
        </Fade>
        <div className='homepage-buttonscontainer'>
          <ProductsBlocView
            whereFrom='Homepage'
            tests={props.wishlist}
            star={true}
          />
          <div style={{ whiteSpace: 'pre' }}></div>
          <Fade top>
            <Link to='/favoris' className='tousfavoris'>
              <FontAwesomeIcon
                style={{ marginRight: 5, color: 'grey' }}
                icon={faPlus}
              ></FontAwesomeIcon>
              <Translate
                className='homepage-headingtxt'
                value='homepage.allfavoris'
              />
              {/* {t('homepage.allproducts')} */}
            </Link>
          </Fade>
        </div>
      </div>
    );
  } else {
    favoris = <div></div>;
  }

  return (
    <div>
      <Header />
      <SubHeader doc={props.pr_home} type='Main' dashboardSelected={true} />
      <div className='homepage-container'>
        <div className='homepage-subcontainer'>
          <Page doc={props.pr_home} />
          {favoris}
          <Fade top>
            <HeadingText1 className='heading'>
              <Translate value='homepage.products' />
              {/* {t('homepage.products')} */}
            </HeadingText1>
          </Fade>
          <div className='homepage-buttonscontainer'>
            <ListButtons elts={props.visibleProduitsItems} whereTo='Produits' />
            {/* <div style={{ whiteSpace: 'pre' }}></div> */}
            <Fade top>
              <Link to='/produits' className='tousproduits'>
                <FontAwesomeIcon
                  style={{ marginRight: 5, color: 'grey' }}
                  icon={faPlus}
                ></FontAwesomeIcon>
                <Translate
                  className='homepage-headingtxt'
                  value='homepage.allproducts'
                />
                {/* {t('homepage.allproducts')} */}
              </Link>
            </Fade>
          </div>
          <Fade top>
            <HeadingText1 className='heading'>
              <Translate value='homepage.medias' />
              {/* {t('homepage.medias')} */}
            </HeadingText1>
          </Fade>
          <div className='homepage-buttonscontainer'>
            <ListButtons elts={props.visibleMediasItems} whereTo='Medias' />
            {/* <div style={{ whiteSpace: 'pre' }}></div> */}
            <Fade top>
              <Link to='/medias' className='tousproduits'>
                <FontAwesomeIcon
                  style={{ marginRight: 5, color: 'grey' }}
                  icon={faPlus}
                ></FontAwesomeIcon>
                <Translate
                  className='homepage-headingtxt'
                  value='homepage.allmedias'
                />
                {/* {t('homepage.allmedias')} */}
              </Link>
            </Fade>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    visibleProduitsItems: state.canal.visiblePathItems,
    visibleMediasItems: state.classification_medias.visiblePathItems,
    wishlist: state.user.wishlist,
    audience: state.adminReducer.useraudience,
    instancedata: state.adminReducer.instancedata,
    classificationIds: state.canal.classificationIds,
    mediasClassificationIds: state.classification_medias.classificationIds,
  };
}

export default connect(mapStateToProps)(Homepage);
