import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/components/ListButtons.css';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';

const ListButtons = (props) => {
  return (
    <div style={props.style} className='produits'>
      {props.elts.map((elt, index) => {
        if (index < 5) {
          return (
            <Link
              to={{
                pathname:
                  props.whereTo === 'Produits' ? '/produits' : '/medias',
                search: '?' + elt[3],
              }}
              className='lb-link'
            >
              <Fade key={index}>
                <div className='lb-produit-container'>
                  <button
                    className='lb-produit'
                    style={
                      props.whereTo === 'Produits'
                        ? props.idsToImages[elt[3]] !== null
                          ? {
                              backgroundImage:
                                'url(' + props.idsToImages[elt[3]] + ')',
                            }
                          : {}
                        : props.mediasIdsToImages[elt[3]] !== null
                        ? {
                            backgroundImage:
                              'url(' + props.mediasIdsToImages[elt[3]] + ')',
                          }
                        : {}
                    }
                  >
                    <div className='lb-item-info-container'>
                      <div className='lb-item-info'>
                        <p>{elt[0]}</p>
                        <div className='lb-item-dates'>
                          <FontAwesomeIcon
                            className='lb-item-date2'
                            icon={faFile}
                          />
                          <p className='lb-item-date1-p'>
                            {elt[4].split('T')[0]}
                          </p>
                          <FontAwesomeIcon
                            className='lb-item-date1'
                            icon={faSync}
                          />
                          <p>{elt[5].split('T')[0]}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </Fade>
            </Link>
          );
        }
      })}
    </div>
  );
};

ListButtons.propTypes = {
  elts: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    idsToImages: state.canal.idsToImages,
    mediasIdsToImages: state.classification_medias.idsToImages,
  };
}

export default connect(mapStateToProps)(ListButtons);
