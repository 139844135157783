import { useAuth0 } from '@auth0/auth0-react'
import { faSearch, faStar, faBars, faPowerOff, faShoppingBasket, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Translate } from 'react-redux-i18n'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import * as ROUTES from '../constants/routes'
import { requestSearchResults } from '../redux/actions/searchActions'
import { ParameterLink } from './authentification'
import LanguageDropdown from './LanguageDropdown'
import '../styles/components/Header.css'

const Header = ({ dispatch, searchInput }) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { logout } = useAuth0()

  const [dropdownMenuActive, setDropdownMenuActive] = useState(false)
  const [search, setSearch] = useState('')

  const handleBarsClick = () => {
    setDropdownMenuActive(!dropdownMenuActive)
  }

  useEffect(() => {
    if (ROUTES.SEARCH === path) {
      setSearch(searchInput)
    }
  }, [])

  const handleSearchSubmit = (e) => {
    e.preventDefault()

    if (search !== searchInput) {
      dispatch(requestSearchResults(search))
    }

    if (ROUTES.SEARCH !== path) {
      history.push('/search')
    }
  }

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value)
  }

  return (
    <div>
      <div className="header-container">
        <div className="header-subcontainer">
          <div className="header-leftleft">
            <FontAwesomeIcon className="header-bars" icon={faBars} onClick={handleBarsClick} />
          </div>
          <div className="header-left">
            <Link to="/homepage">
              <img className="header-logo" src={window.$logo}></img>
            </Link>
          </div>
          <div className="header-center">
            <form className="header-searchinput" onSubmit={handleSearchSubmit}>
              <input
                id="header-searchinput-input"
                placeholder={I18n.t('search.placeholder')}
                type="text"
                value={search}
                onChange={handleSearchInputChange}
              />
            </form>
          </div>
          <div className="header-right">
            <LanguageDropdown className="header-lng-dropdown langs" color={'white'} />
            <ParameterLink />
            <Link className="header-favoris" to="/favoris">
              <FontAwesomeIcon className="header-favoris-star" icon={faStar} />
            </Link>
            <Link className="header-cart" to="/cart">
              <FontAwesomeIcon className="header-cart-icon" icon={faShoppingBasket} />
            </Link>
            <div className="divider"></div>
            <a
              className="header-logout"
              href="#"
              onClick={() =>
                logout({
                  returnTo: window.location.origin
                })
              }
            >
              <FontAwesomeIcon className="header-logout-icon" icon={faPowerOff} />
              <p className="header-logout-p">
                <Translate value="header.logout" />
              </p>
            </a>
            <Link className="header-search" to="/search">
              <FontAwesomeIcon className="header-searchIcon" icon={faSearch} />
            </Link>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 66 }}></div>
      <div
        id="dropdownheadermenu"
        style={840 > window.innerWidth && dropdownMenuActive ? { display: 'flex' } : { display: 'none' }}
      >
        <div className="header-forsmallscreensize">
          <LanguageDropdown className={'dropdownlangs'} color={'black'} />
          <div className="dropdownoverlay"></div>
          <Link className="dropdown-linkitem" to="/parametres">
            <FontAwesomeIcon className="dropdown-icon" icon={faCog} />
            <p className="dropdown-linkp">
              <Translate value="header.dropdown.settings" />
            </p>
          </Link>
          <Link className="dropdown-linkitem" to="/favoris">
            <FontAwesomeIcon className="dropdown-icon" icon={faStar} />
            <p className="dropdown-linkp">
              <Translate value="header.dropdown.favourites" />
            </p>
          </Link>
          <Link className="dropdown-linkitem" to="/cart">
            <FontAwesomeIcon className="dropdown-icon" icon={faShoppingBasket} />
            <p className="dropdown-linkp">
              <Translate value="header.dropdown.cart" />
            </p>
          </Link>
          <Link className="dropdown-linkitem" to="/login">
            <FontAwesomeIcon className="dropdown-icon" icon={faPowerOff} />
            <p className="dropdown-linkp">
              <Translate value="header.dropdown.logout" />
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func,
  searchInput: PropTypes.string
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  searchInput: state.search.searchInput
})

export default connect(mapStateToProps)(Header)
