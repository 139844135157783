import React, { useState, useEffect } from 'react';
import { ProductsBlocView, ProductsListView } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import {
  faThList,
  faThLarge,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/components/ItemsContainer.css';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import '../styles/pages/Search.css';
import Loader from 'react-loader-spinner';
import DocumentLinks from './ProductPage/DocumentLinks';
import { emptyCart } from '../redux/actions/cartActions';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import arrowDown from '../images/icons/iconfinder_caret-down_383170_black.png';
import axios from 'axios';
import {
  fetchProductsMAJ,
  fetchMediasMAJ,
} from '../redux/actions/homepageActions';
import { useAuth0 } from '@auth0/auth0-react';
import { Translate } from 'react-redux-i18n';

const ItemsContainer = (props) => {
  const [viewblocopacity, setViewblocopacity] = useState(1);
  const [viewlistopacity, setViewlistopacity] = useState(0.3);
  const [medias, setMedias] = useState([]);
  const [itemview, setItemview] = useState();
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [dropdownValue, setDropdownValue] = useState(
    props.whereFrom === 'DocumentLinks' ? '8' : '20'
  );
  const [dropdownSelectedIndex, setDropdownSelectedIndex] = useState(0);
  const [activeView, setActiveView] = useState('bloc');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const [documentsChecked, setDocumentsChecked] = useState(true);
  const [photosChecked, setPhotosChecked] = useState(true);
  const [videosChecked, setVideosChecked] = useState(true);

  const [dlOptions, setDLOptions] = useState([]);
  const [dlOptionsIds, setdlOptionsIds] = useState([]);
  const [dlSelectedOption, setDLSelectedOption] = useState();
  const [dlLength, setDLLength] = useState();

  const { getIdTokenClaims } = useAuth0();

  var I18n = require('react-redux-i18n').I18n;

  useEffect(() => {
    setActivePageNumber(1);
  }, [props.historyLocationSearch]);

  function handleDLChange(selectedOption) {
    setDLSelectedOption(selectedOption.value);
    setActivePageNumber(1);
  }

  function handleDocumentLinksNumberOfItemsPerPage(selectedOption) {
    setDropdownSelectedIndex(
      numberOptionsForDocumentLinks
        .map((item) => item.value)
        .indexOf(selectedOption.value)
    );
    setDropdownValue(selectedOption.value);
    setActivePageNumber(1);
  }

  const dlCustomStyles = {
    container: (provided, state) => ({
      ...provided,
      maxWidth: 500,
      width: '100%',
      marginRight: 20,
      marginLeft: 20,
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      // '&:hover': {
      //   boxShadow: state.isFocused ? 'none' : 'none',
      // },
    }),
  };

  useEffect(() => {
    async function setTheOptions() {
      if (props.whereFrom === 'DocumentLinks') {
        let options = [];
        let optionsIdsToSet = [];
        Object.entries(
          props.product.documentLinksData.documentLinksNamesToCodesMapping
        ).map((linkType) => {
          if (
            props.product.documentLinksData.documentLinks[linkType[0]]
              .productsShortcut.length > 0
          ) {
            optionsIdsToSet.push(linkType[0]);
            options.push({
              value: options.length,
              label: linkType[1],
            });
          }
        });

        setdlOptionsIds(optionsIdsToSet);
        setDLOptions(options);

        setDLSelectedOption(0);
      }
    }

    setTheOptions();
  }, []);

  useEffect(() => {
    if (props.whereFrom === 'Cart') {
      if (activeView === 'bloc') {
        setItemview(
          <ProductsBlocView
            whereFrom='Cart'
            tests={props.cart.items}
            bloctxtcolor={'white'}
            bloctxtcolor={'black'}
          />
        );
      } else {
        setItemview(
          <ProductsListView
            whereFrom='Cart'
            tests={props.cart.items}
            backgroundColor1={'rgb(242, 246, 248)'}
            backgroundColor2={'white'}
          />
        );
      }
    }
  }, [props.cart.items]);

  useEffect(() => {
    if (props.whereFrom === 'RelatedMedia') {
      if (document.getElementById('ic-documents') !== null) {
        document.getElementById('ic-documents').checked = true;
        document.getElementById('ic-photos').checked = true;
        document.getElementById('ic-videos').checked = true;
      }
      setMedias(props.currentElements);
      let imgs = [];
      let si = {};
      let mgsi = [];
      Object.keys(props.currentElements).map((mediaCategory, index) => {
        si[mediaCategory] = [];
      });
      setSelectedItems({ ...si });
      props.setSelectedItems({ ...si });
      setRelatedMediaItemview(
        activeView,
        props.currentElements,
        si,
        selectedCategory,
        false
      );
    } else if (props.whereFrom === 'Medias') {
      let imgs = [];
      if (!documentsChecked && photosChecked) {
        imgs = props.currentElements.filter((currentElement, index) => {
          let extension = currentElement['url'].substring(
            currentElement['url'].lastIndexOf('.') + 1,
            currentElement['url'].length
          );
          return (
            extension !== 'pptx' &&
            extension !== 'pdf' &&
            extension !== 'xlsx' &&
            (extension === 'png' || extension === 'jpg' || extension === 'jpeg')
          );
        });
        // imgs = imgs.map((assetData) => [
        //   assetData['name'],
        //   assetData['url'],
        //   assetData['id'],
        // ]);
      } else if (documentsChecked && !photosChecked) {
        imgs = props.currentElements.filter((currentElement, index) => {
          let extension = currentElement['url'].substring(
            currentElement['url'].lastIndexOf('.') + 1,
            currentElement['url'].length
          );
          return (
            extension !== 'jpg' &&
            extension !== 'png' &&
            extension !== 'jpeg' &&
            (extension === 'pptx' ||
              extension === 'pdf' ||
              extension === 'xlsx')
          );
        });
        // imgs = imgs.map((assetData) => [
        //   assetData['name'],
        //   assetData['url'],
        //   assetData['id'],
        // ]);
      } else {
        // imgs = props.currentElements.map((assetData) => [
        //   assetData['name'],
        //   assetData['url'],
        //   assetData['id'],
        // ]);
        imgs = [...props.currentElements];
      }

      setMedias(imgs);
      setMediasItemview(activeView, imgs, selectedItems, false);
    } else if (props.whereFrom === 'Cart') {
      if (activeView === 'bloc') {
        setItemview(
          <ProductsBlocView
            whereFrom='Cart'
            tests={props.cart.items}
            bloctxtcolor={'white'}
            bloctxtcolor={'black'}
          />
        );
      } else {
        setItemview(
          <ProductsListView
            whereFrom='Cart'
            tests={props.cart.items}
            backgroundColor1={'rgb(242, 246, 248)'}
            backgroundColor2={'white'}
          />
        );
      }
    } else if (
      props.whereFrom === 'ProductsMAJ' ||
      props.whereFrom === 'MediasMAJ'
    ) {
      if (activeView === 'bloc') {
        setItemview(
          <ProductsBlocView
            whereFrom={props.whereFrom}
            tests={
              props.whereFrom === 'ProductsMAJ'
                ? props.productsMAJ
                : props.mediasMAJ
            }
            bloctxtcolor={'white'}
            bloctxtcolor={'black'}
          />
        );
      } else {
        setItemview(
          <ProductsListView
            whereFrom={props.whereFrom}
            tests={
              props.whereFrom === 'ProductsMAJ'
                ? props.productsMAJ
                : props.mediasMAJ
            }
            backgroundColor1={'rgb(242, 246, 248)'}
            backgroundColor2={'white'}
          />
        );
      }
    } else if (props.whereFrom === 'DocumentLinks') {
      // setDropdownValue('8');
      let docLinks = [];
      if (dlOptions.length > 0) {
        docLinks =
          props.product.documentLinksData.documentLinks[
            dlOptionsIds[dlSelectedOption]
          ]['productsShortcut'];
      }
      setDLLength(docLinks.length);
      docLinks = docLinks.slice(
        activePageNumber * parseInt(dropdownValue, 10) -
          parseInt(dropdownValue, 10),
        activePageNumber * parseInt(dropdownValue, 10)
      );
      setMedias(docLinks);
      if (activeView === 'bloc') {
        setItemview(
          <ProductsBlocView
            whereFrom='DocumentLinks'
            tests={docLinks}
            bloctxtcolor={'white'}
            bloctxtcolor={'black'}
          />
        );
      } else {
        setItemview(
          <ProductsListView
            whereFrom='DocumentLinks'
            tests={docLinks}
            backgroundColor1={'rgb(242, 246, 248)'}
            backgroundColor2={'white'}
          />
        );
      }
    } else {
      // let imgs = props.currentElements.map((assetData) => [
      //   assetData['name'],
      //   assetData['url'],
      //   assetData['id'],
      // ]);
      setMedias(props.currentElements);
      if (activeView === 'bloc') {
        setItemview(
          <ProductsBlocView
            star={props.star}
            whereFrom={props.whereFrom}
            tests={props.currentElements}
            bloctxtcolor={'black'}
          />
        );
      } else {
        setItemview(
          <ProductsListView
            star={props.star}
            whereFrom={props.whereFrom}
            tests={props.currentElements}
            backgroundColor1={'rgb(242, 246, 248)'}
            backgroundColor2={'white'}
          />
        );
      }
    }
    if (
      props.whereFrom !== 'RelatedMedia' &&
      props.whereFrom !== 'Cart' &&
      props.whereFrom !== 'DocumentLinks' &&
      props.whereFrom !== 'ProductsMAJ' &&
      props.whereFrom !== 'MediasMAJ'
    ) {
      props.setApiCallNumberOfPageAndNumberOfValues(
        activePageNumber,
        dropdownValue
      );
    }
  }, [
    props.currentElements,
    activePageNumber,
    dropdownValue,
    dlOptions,
    dlSelectedOption,
    props.productsMAJ,
    props.mediasMAJ,
  ]);

  function setRelatedMediaItemview(view, medias, si, index, selectionMode) {
    if (view === 'bloc') {
      setItemview(
        <ProductsBlocView
          whereFrom='RelatedMedia'
          tests={medias}
          star={false}
          bloctxtcolor={'white'}
          selectedItems={si}
          selectedCategory={index}
          selectionMode={selectionMode}
          setSelectedItems={setSelectedItemsFromChild}
          setSelectedCategory={setSelectedCategoryFromChild}
        />
      );
    } else {
      setItemview(
        <ProductsListView
          whereFrom='RelatedMedia'
          tests={medias}
          star={false}
          selectedItems={si}
          selectedCategory={index}
          selectionMode={selectionMode}
          setSelectedItems={setSelectedItemsFromChild}
          setSelectedCategory={setSelectedCategoryFromChild}
          backgroundColor1={'rgb(242, 246, 248)'}
          backgroundColor2={'rgb(32,32,32)'}
        />
      );
    }
  }

  function setMediasItemview(view, medias, si, selectionMode) {
    if (view === 'bloc') {
      setItemview(
        <ProductsBlocView
          whereFrom='Medias'
          tests={medias}
          star={false}
          bloctxtcolor={'black'}
          selectedItems={si}
          selectionMode={selectionMode}
          setSelectedItems={setSelectedItemsFromChild}
        />
      );
    } else {
      setItemview(
        <ProductsListView
          whereFrom='Medias'
          tests={medias}
          star={false}
          selectedItems={si}
          selectionMode={selectionMode}
          setSelectedItems={setSelectedItemsFromChild}
          backgroundColor1={'rgb(242, 246, 248)'}
          backgroundColor2={'white'}
        />
      );
    }
  }

  function setSelectedItemsFromChild(newItems) {
    setSelectedItems(newItems);
    props.setSelectedItems(newItems);
  }

  function setSelectedCategoryFromChild(index, view) {
    setSelectedCategory(index);
    if (
      document.getElementById('ic-check').checked &&
      document.getElementById('ic-allcheck').checked
    ) {
      let mds = Object.entries(medias);
      let si = {};
      let mgsi = [];
      mds[index][1].forEach((media, index) => {
        mgsi.push(index);
      });
      si[mds[index][0]] = mgsi;
      setSelectedItems({ ...si });
      props.setSelectedItems({ ...si });
      setRelatedMediaItemview(
        view,
        medias,
        si,
        index,
        document.getElementById('ic-check').checked
      );
    }
  }

  function setNewPath(index) {
    props.updateHistory(props.path.slice(1, index + 1).join('+'));
  }

  function handleBlocClick() {
    if (props.whereFrom === 'RelatedMedia') {
      setRelatedMediaItemview(
        'bloc',
        medias,
        selectedItems,
        selectedCategory,
        document.getElementById('ic-check').checked
      );
    } else if (props.whereFrom === 'Medias') {
      setMediasItemview(
        'bloc',
        medias,
        selectedItems,
        document.getElementById('ic-check').checked
      );
    } else if (props.whereFrom === 'Cart') {
      setItemview(
        <ProductsBlocView
          whereFrom={props.whereFrom}
          tests={props.cart.items}
          bloctxtcolor={'black'}
        />
      );
    } else if (
      props.whereFrom === 'ProductsMAJ' ||
      props.whereFrom === 'MediasMAJ'
    ) {
      setItemview(
        <ProductsBlocView
          whereFrom={props.whereFrom}
          tests={
            props.whereFrom === 'ProductsMAJ'
              ? props.productsMAJ
              : props.mediasMAJ
          }
          bloctxtcolor={'white'}
          bloctxtcolor={'black'}
        />
      );
    } else {
      setItemview(
        <ProductsBlocView
          star={props.star}
          whereFrom={props.whereFrom}
          tests={medias}
          bloctxtcolor={'black'}
        />
      );
    }
    setViewblocopacity(1);
    setViewlistopacity(0.3);
    setActiveView('bloc');
  }

  function handleListClick() {
    if (props.whereFrom === 'RelatedMedia' || props.whereFrom === 'Medias') {
      if (props.whereFrom === 'RelatedMedia') {
        setRelatedMediaItemview(
          'list',
          medias,
          selectedItems,
          selectedCategory,
          document.getElementById('ic-check').checked
        );
      } else {
        setMediasItemview(
          'list',
          medias,
          selectedItems,
          document.getElementById('ic-check').checked
        );
      }
    } else if (props.whereFrom === 'Cart') {
      setItemview(
        <ProductsListView
          whereFrom={props.whereFrom}
          tests={props.cart.items}
          backgroundColor1={'rgb(242, 246, 248)'}
          backgroundColor2={'white'}
        />
      );
    } else if (
      props.whereFrom === 'ProductsMAJ' ||
      props.whereFrom === 'MediasMAJ'
    ) {
      setItemview(
        <ProductsListView
          whereFrom={props.whereFrom}
          tests={
            props.whereFrom === 'ProductsMAJ'
              ? props.productsMAJ
              : props.mediasMAJ
          }
          backgroundColor1={'rgb(242, 246, 248)'}
          backgroundColor2={'white'}
        />
      );
    } else {
      setItemview(
        <ProductsListView
          star={props.star}
          whereFrom={props.whereFrom}
          tests={medias}
          backgroundColor1={'rgb(242, 246, 248)'}
          backgroundColor2={'white'}
        />
      );
    }
    setViewblocopacity(0.3);
    setViewlistopacity(1);
    setActiveView('list');
  }

  function handleChange(pageNumber) {
    setActivePageNumber(pageNumber);
    if (props.whereFrom !== 'DocumentLinks') {
      window.scrollTo(0, 0);
    }
    if (props.whereFrom === 'ProductsMAJ') {
      props.dispatch(
        fetchProductsMAJ(
          props.classificationIds.map((item) => item['value']),
          pageNumber,
          parseInt(dropdownValue, 10),
          props.instancedata.id
        )
      );
    } else if (props.whereFrom === 'MediasMAJ') {
      props.dispatch(
        fetchMediasMAJ(
          props.mediasClassificationIds.map((item) => item['value']),
          pageNumber,
          parseInt(dropdownValue, 10),
          props.instancedata.id
        )
      );
    }
  }

  const numberOptions = [
    { value: '20', label: '20' },
    { value: '40', label: '40' },
    { value: '100', label: '100' },
  ];

  const numberOptionsForDocumentLinks = [
    { value: '8', label: '8' },
    { value: '20', label: '20' },
    { value: '40', label: '40' },
    { value: '100', label: '100' },
  ];

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: 10,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 600,
    }),
    control: (provided, state) => ({
      ...provided,
      width: 80,
      height: 44,
      border: '1px solid #f0f0f0',
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: () => ({
      marginRight: 5,
      background:
        'rgba(0,0,0,0.08) url(' + faAngleDown + ') no-repeat center center',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      borderRadius: 0,
      width: 80,
    }),
    option: () => ({
      textAlign: 'left',
      marginLeft: 20,
      cursor: 'pointer',
      color: '#b6b6b6',
      '&:hover': {
        color: '#232323',
      },
      fontSize: 14,
      fontWeight: 600,
    }),
  };

  var pagination;
  if (
    (props.whereFrom === 'Produits' &&
      medias.length * activePageNumber < props.currentProductsLength) ||
    (props.whereFrom === 'Medias' &&
      medias.length * activePageNumber < props.currentAssetsLength) ||
    (props.whereFrom === 'DocumentLinks' &&
      medias.length * activePageNumber <
        props.product.productData.product.data.documentLinks.length) ||
    (props.whereFrom === 'ProductsMAJ' &&
      props.tests.length * activePageNumber < props.productsMAJlength) ||
    (props.whereFrom === 'MediasMAJ' &&
      props.tests.length * activePageNumber < props.mediasMAJlength)
  ) {
    pagination = (
      <Pagination
        activePage={activePageNumber}
        itemsCountPerPage={parseInt(dropdownValue, 10)}
        totalItemsCount={
          props.whereFrom === 'Produits'
            ? props.currentProductsLength
            : props.whereFrom === 'Medias'
            ? props.currentAssetsLength
            : props.whereFrom === 'ProductsMAJ'
            ? props.productsMAJlength
            : props.whereFrom === 'MediasMAJ'
            ? props.mediasMAJlength
            : dlLength
        }
        pageRangeDisplayed={5}
        onChange={handleChange}
        prevPageText={I18n.t('pagination.previous')}
        nextPageText={I18n.t('pagination.next')}
        innerClass='search-pagination-innerClass'
        activeClass='search-pagination-activeClass'
        activeLinkClass='search-pagination-activeLinkClass'
        itemClass='search-pagination-itemClass'
        itemClassFirst='search-pagination-itemClassFirst'
        itemClassPrev='search-pagination-itemClassPrev'
        itemClassNext='search-pagination-itemClassNext'
        itemClassLast='search-pagination-itemClassLast'
        linkClass='search-pagination-linkClass'
        linkClassFirst='search-pagination-linkClassFirst'
        linkClassPrev='search-pagination-linkClassPrev'
        linkClassNext='search-pagination-linkClassNext'
        linkClassLast='search-pagination-linkClassLast'
        disabledClass='search-pagination-disabledClass'
      />
    );
  } else {
    pagination = <div></div>;
  }

  function handleDropdownChange(e) {
    setDropdownSelectedIndex(numberOptions.indexOf(e));
    setDropdownValue(e.value);
    setActivePageNumber(1);
    if (props.whereFrom === 'ProductsMAJ') {
      props.dispatch(
        fetchProductsMAJ(
          props.classificationIds.map((item) => item['value']),
          1,
          parseInt(e.value, 10),
          props.instancedata.id
        )
      );
    } else if (props.whereFrom === 'MediasMAJ') {
      props.dispatch(
        fetchMediasMAJ(
          props.mediasClassificationIds.map((item) => item['value']),
          1,
          parseInt(e.value, 10),
          props.instancedata.id
        )
      );
    }
  }

  function handleInputChange(event) {
    props.handleScroll();
    if (props.whereFrom === 'RelatedMedia') {
      let si = { ...selectedItems };
      if (document.getElementById('ic-allcheck').checked) {
        let mds = Object.entries(medias);
        let mgsi = [];
        mds[selectedCategory][1].forEach((media, index) => {
          mgsi.push(index);
        });
        si[mds[selectedCategory][0]] = mgsi;
        setSelectedItems({ ...si });
        props.setSelectedItems({ ...si });
      }
      setRelatedMediaItemview(
        activeView,
        medias,
        si,
        selectedCategory,
        event.target.checked
      );
    } else if (props.whereFrom === 'Medias') {
      if (event.target.checked) {
        let mediasid = document.getElementById('medias-outercontainer');
        if (
          mediasid.scrollTop ===
          mediasid.scrollHeight - mediasid.offsetHeight
        )
          document.getElementById('ic-footer').classList.add('ic-footerstyle');
      } else {
        document.getElementById('ic-footer').classList.remove('ic-footerstyle');
      }
      setMediasItemview(
        activeView,
        medias,
        selectedItems,
        event.target.checked
      );
    }
    var allselection = document.getElementById(
      'ic-secondrow-selection-allselection-id'
    );
    if (event.target.checked) {
      allselection.classList.remove('ic-secondrow-selection-allselection-hide');
      allselection.classList.add('ic-secondrow-selection-allselection');
    } else {
      allselection.classList.remove('ic-secondrow-selection-allselection');
      allselection.classList.add('ic-secondrow-selection-allselection-hide');
    }
  }

  function handleAllInputChange(event) {
    let si;
    if (event.target.checked) {
      if (props.whereFrom === 'RelatedMedia') {
        let mds = Object.entries(medias);
        si = {};
        let mgsi = [];
        mds[selectedCategory][1].forEach((media, index) => {
          mgsi.push(index);
        });
        si[mds[selectedCategory][0]] = mgsi;
        setSelectedItems({ ...si });
        props.setSelectedItems({ ...si });
      } else {
        si = [];
        for (let i = 0; i < medias.length; i++) {
          si.push(i);
        }
        setSelectedItems([...si]);
        props.setSelectedItems([...si]);
      }
    } else {
      si = [];
      setSelectedItems([...si]);
      props.setSelectedItems([...si]);
    }
    if (props.whereFrom === 'RelatedMedia') {
      setRelatedMediaItemview(activeView, medias, si, selectedCategory, true);
    } else if (props.whereFrom === 'Medias') {
      setMediasItemview(activeView, medias, si, true);
    }
  }

  function filtreDocumentsInRelatedMedia(medias) {
    let newMedias = {};
    medias.map((category, index) => {
      newMedias[category[0]] = category[1].filter((item, index) => {
        if (!document.getElementById('ic-documents').checked) {
          return (
            item['extension'] !== 'pptx' &&
            item['extension'] !== 'pdf' &&
            item['extension'] !== 'xlsx'
          );
        } else return item;
      });
    });
    return newMedias;
  }

  function filtePhotosInRelatedMedia(medias) {
    let newMedias = {};
    medias.map((category, index) => {
      newMedias[category[0]] = category[1].filter((item, index) => {
        if (!document.getElementById('ic-photos').checked) {
          return (
            item['extension'] !== 'png' &&
            item['extension'] !== 'jpg' &&
            item['extension'] !== 'jpeg'
          );
        } else return item;
      });
    });
    return newMedias;
  }

  function filtreVideosInRelatedMedia(medias) {
    let newMedias = {};
    medias.map((category, index) => {
      newMedias[category[0]] = category[1].filter((item, index) => {
        if (!document.getElementById('ic-videos').checked) {
          return (
            item['extension'] !== 'avi' &&
            item['extension'] !== 'mov' &&
            item['extension'] !== 'mp4' &&
            item['extension'] !== 'mpeg' &&
            item['extension'] !== 'flv' &&
            item['extension'] !== 'wmv' &&
            item['extension'] !== 'mkv'
          );
        } else return item;
      });
    });
    return newMedias;
  }

  function filtreMediasInRelatedMedia() {
    let newMedias = {};
    newMedias = filtreDocumentsInRelatedMedia(
      Object.entries(props.currentElements)
    );
    newMedias = filtePhotosInRelatedMedia(Object.entries(newMedias));
    newMedias = filtreVideosInRelatedMedia(Object.entries(newMedias));
    setMedias(newMedias);
    setRelatedMediaItemview(
      activeView,
      newMedias,
      [],
      selectedCategory,
      document.getElementById('ic-check').checked
    );
  }

  function filtreDocumentsInMedias(medias) {
    let newMedias = [];
    newMedias = medias.filter((media, index) => {
      let extension = media['url'].substring(
        media['url'].lastIndexOf('.') + 1,
        media['url'].length
      );
      if (!document.getElementById('ic-documents').checked) {
        return (
          extension !== 'pptx' && extension !== 'pdf' && extension !== 'xlsx'
        );
      } else return media;
    });
    return newMedias;
  }

  function filtePhotosInMedias(medias) {
    let newMedias = [];
    newMedias = medias.filter((media, index) => {
      let extension = media['url'].substring(
        media['url'].lastIndexOf('.') + 1,
        media['url'].length
      );
      if (!document.getElementById('ic-photos').checked) {
        return (
          extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg'
        );
      } else return media;
    });
    return newMedias;
  }

  function filtreVideosInMedias(medias) {
    let newMedias = [];
    newMedias = medias.filter((media, index) => {
      let extension = media['url'].substring(
        media['url'].lastIndexOf('.') + 1,
        media['url'].length
      );
      if (!document.getElementById('ic-videos').checked) {
        return (
          extension !== 'avi' &&
          extension !== 'mov' &&
          extension !== 'mp4' &&
          extension !== 'mpeg' &&
          extension !== 'flv' &&
          extension !== 'wmv' &&
          extension !== 'mkv'
        );
      } else return media;
    });
    return newMedias;
  }

  function filtreMediasInMedias() {
    let newMedias;
    newMedias = filtreDocumentsInMedias(props.currentElements);
    newMedias = filtePhotosInMedias(newMedias);
    newMedias = filtreVideosInMedias(newMedias);
    newMedias = newMedias.map((currentElement, index) => {
      return [
        currentElement['name'],
        currentElement['url'],
        currentElement['id'],
      ];
    });
    setMedias(newMedias);
    setMediasItemview(
      activeView,
      newMedias,
      [],
      document.getElementById('ic-check').checked
    );
  }

  function filtreMedias() {
    if (props.whereFrom === 'RelatedMedia') {
      filtreMediasInRelatedMedia();
    } else if (props.whereFrom === 'Medias') {
      filtreMediasInMedias();
    }
  }

  function handleDocumentsClick(e) {
    setSelectedItemsFromChild([]);
    setDocumentsChecked(e.target.checked);
    filtreMedias();
  }

  function handlePhotosClick(e) {
    setSelectedItemsFromChild([]);
    setPhotosChecked(e.target.checked);
    filtreMedias();
  }

  function handleVideosClick(e) {
    setSelectedItemsFromChild([]);
    setVideosChecked(e.target.checked);
    filtreMedias();
  }

  async function sendByMail() {
    const token = await getIdTokenClaims();
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token.__raw}`,
      },
    });
    let url =
      process.env.REACT_APP_QUABLE_API_PROTOCOL +
      props.instancedata.id +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/assets/export';
    instance
      .post(url, { assetCodes: props.cart.items.map((item) => item.id) })
      .then((response) => {
        props.dispatch(emptyCart());
        props.setShowNotificationFromChild();
      })
      .catch((err) => {});
  }

  async function downloadZip() {
    var zip = new JSZip();
    await Promise.all(
      props.cart.items.map(async (media) => {
        return new Promise((resolve) => {
          JSZipUtils.getBinaryContent(media.url, (err, data) =>
            resolve(zip.file(media.name, data, { binary: true }))
          );
        });
      })
    );
    zip
      .generateAsync({
        type: 'blob',
      })
      .then(function (content) {
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(content));
        element.setAttribute('download', 'medias.zip');
        element.click();
      });
  }

  if (
    props.whereFrom === 'Produits' &&
    ((props.isFetchingProducts && props.currentButtonsLength === 0) ||
      (props.isFetchingProducts && props.currentButtonsLength === undefined))
  ) {
    return (
      <div className='ic-subcontainer'>
        <div className='ic-switchviewcontainer'>
          <Select
            classNamePrefix='select'
            defaultValue={numberOptions[dropdownSelectedIndex]}
            name='number'
            options={numberOptions}
            styles={customStyles}
            isSearchable={false}
            onChange={(e) => handleDropdownChange(e)}
          />
          <div className='ic-view-container'>
            <FontAwesomeIcon
              className='ic-bloc'
              style={{ opacity: viewblocopacity }}
              icon={faThLarge}
              onClick={handleBlocClick}
            />
            <FontAwesomeIcon
              className='ic-list'
              style={{ opacity: viewlistopacity }}
              icon={faThList}
              onClick={handleListClick}
            />
          </div>
        </div>
        <div className='ic-loader'>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      </div>
    );
  } else if (
    props.whereFrom === 'Medias' &&
    ((props.isFetchingMedias && props.currentButtonsLength === 0) ||
      (props.isFetchingMedias && props.currentButtonsLength === undefined))
  ) {
    return (
      <div className='ic-subcontainer'>
        <div className='ic-subsubcontainer'>
          <div id='ic-secondrow-selection'>
            <form>
              <div className='ic-secondrow-selection-selectionmode'>
                <label className='ic-label-check'>
                  <input
                    id='ic-check'
                    type='checkbox'
                    onChange={handleInputChange}
                  />
                  Mode sélection
                </label>
              </div>
            </form>
            <form>
              <div
                id='ic-secondrow-selection-allselection-id'
                className='ic-secondrow-selection-allselection-hide'
              >
                <label className='ic-label-check'>
                  <input
                    id='ic-allcheck'
                    type='checkbox'
                    onChange={(e) => handleAllInputChange(e)}
                  />
                  Sélectionner tout
                </label>
              </div>
            </form>
          </div>
          <div className='ic-switchviewcontainer'>
            {/* <div className='ic-secondrow-showwhat-mediastypes'>
              <div>
                <input
                  checked={documentsChecked}
                  id='ic-documents'
                  type='checkbox'
                  onClick={(e) => handleDocumentsClick(e)}
                ></input>
              </div>
              <p style={{ color: 'black', marginRight: 10 }}>Documents</p>
              <div>
                <input
                  checked={photosChecked}
                  id='ic-photos'
                  type='checkbox'
                  onClick={(e) => handlePhotosClick(e)}
                ></input>
              </div>
              <p style={{ color: 'black', marginRight: 10 }}>Photos</p>
              <div>
                <input
                  checked={videosChecked}
                  id='ic-videos'
                  type='checkbox'
                  onClick={(e) => handleVideosClick(e)}
                ></input>
              </div>
              <p style={{ color: 'black', marginRight: 10 }}>Vidéos</p>
            </div> */}
            <Select
              classNamePrefix='select'
              defaultValue={numberOptions[dropdownSelectedIndex]}
              name='number'
              options={numberOptions}
              styles={customStyles}
              isSearchable={false}
              onChange={(e) => handleDropdownChange(e)}
            />
            <div className='ic-view-container'>
              <FontAwesomeIcon
                className='ic-bloc'
                style={{ opacity: viewblocopacity }}
                icon={faThLarge}
                onClick={handleBlocClick}
              />
              <FontAwesomeIcon
                className='ic-list'
                style={{ opacity: viewlistopacity }}
                icon={faThList}
                onClick={handleListClick}
              />
            </div>
          </div>
        </div>
        <div className='ic-loader'>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      </div>
    );
  } else if (
    (props.currentProductsLength > 0 && props.whereFrom === 'Produits') ||
    (props.currentAssetsLength > 0 && props.whereFrom === 'Medias') ||
    (props.whereFrom === 'RelatedMedia' &&
      Object.entries(props.assets).length > 0) ||
    (props.whereFrom === 'Cart' && props.cart.items.length > 0) ||
    (props.whereFrom === 'DocumentLinks' &&
      props.product.productData.product.data.documentLinks !== undefined &&
      props.product.productData.product.data.documentLinks.length > 0) ||
    props.whereFrom === 'ProductsMAJ' ||
    props.whereFrom === 'MediasMAJ'
  ) {
    if (props.whereFrom === 'Cart') {
      return (
        <div
          id='ic-container-id'
          className='ic-container'
          style={
            props.currentButtonsLength === 0 ||
            props.currentButtonsLength === undefined
              ? { display: 'flex' }
              : { display: 'none' }
          }
        >
          <div className='ic-subcontainer'>
            <div className='ic-subsubcontainer'>
              <div id='ic-secondrow-selection'>
                <button
                  className='ic-cart-download'
                  onClick={() => sendByMail()}
                >
                  <Translate value='cart.download' />
                </button>
                <button
                  className='ic-cart-empty'
                  onClick={() => props.dispatch(emptyCart())}
                >
                  <Translate value='cart.emptyCart' />
                </button>
              </div>
              <div className='ic-switchviewcontainer'>
                <div className='ic-view-container'>
                  <FontAwesomeIcon
                    className='ic-bloc'
                    style={{ opacity: viewblocopacity }}
                    icon={faThLarge}
                    onClick={handleBlocClick}
                  />
                  <FontAwesomeIcon
                    className='ic-list'
                    style={{ opacity: viewlistopacity }}
                    icon={faThList}
                    onClick={handleListClick}
                  />
                </div>
              </div>
            </div>
            <div className='ic-itemview'>{itemview}</div>
            <div style={{ marginBottom: 50 }}>{pagination}</div>
          </div>
        </div>
      );
    } else if (
      props.whereFrom === 'RelatedMedia' ||
      props.whereFrom === 'Medias'
    ) {
      return (
        <div
          id='ic-container-id'
          className='ic-container'
          style={
            props.currentButtonsLength === 0 ||
            props.currentButtonsLength === undefined
              ? { display: 'flex' }
              : { display: 'none' }
          }
        >
          <div className='ic-subcontainer'>
            <div className='ic-subsubcontainer'>
              <div id='ic-secondrow-selection'>
                <form>
                  <div className='ic-secondrow-selection-selectionmode'>
                    <label className='ic-label-check'>
                      <input
                        id='ic-check'
                        type='checkbox'
                        onChange={handleInputChange}
                      />
                      <Translate value='search.filters.doSelect' />
                    </label>
                  </div>
                </form>
                <form>
                  <div
                    id='ic-secondrow-selection-allselection-id'
                    className='ic-secondrow-selection-allselection-hide'
                  >
                    <label className='ic-label-check'>
                      <input
                        id='ic-allcheck'
                        type='checkbox'
                        onChange={(e) => handleAllInputChange(e)}
                      />
                      <Translate value='cart.download' />
                      <Translate value='search.filters.selectAll' />
                    </label>
                  </div>
                </form>
              </div>
              <div className='ic-switchviewcontainer'>
                {/* <div className='ic-secondrow-showwhat-mediastypes'>
                  <form>
                    <input
                      checked={documentsChecked}
                      id='ic-documents'
                      type='checkbox'
                      onClick={(e) => handleDocumentsClick(e)}
                    ></input>
                  </form>
                  <p style={{ color: 'black', marginRight: 10 }}>Documents</p>
                  <form>
                    <input
                      checked={photosChecked}
                      id='ic-photos'
                      type='checkbox'
                      onClick={(e) => handlePhotosClick(e)}
                    ></input>
                  </form>
                  <p style={{ color: 'black', marginRight: 10 }}>Photos</p>
                  <form>
                    <input
                      checked={videosChecked}
                      id='ic-videos'
                      type='checkbox'
                      onClick={(e) => handleVideosClick(e)}
                    ></input>
                  </form>
                  <p style={{ color: 'black', marginRight: 10 }}>Vidéos</p>
                </div> */}
                <Select
                  classNamePrefix='select'
                  defaultValue={numberOptions[dropdownSelectedIndex]}
                  name='number'
                  options={numberOptions}
                  styles={customStyles}
                  isSearchable={false}
                  onChange={(e) => handleDropdownChange(e)}
                />
                <div className='ic-view-container'>
                  <FontAwesomeIcon
                    className={
                      props.whereFrom === 'RelatedMedia'
                        ? 'ic-bloc-rm'
                        : 'ic-bloc'
                    }
                    style={{ opacity: viewblocopacity }}
                    icon={faThLarge}
                    onClick={handleBlocClick}
                  />
                  <FontAwesomeIcon
                    className={
                      props.whereFrom === 'RelatedMedia'
                        ? 'ic-list-rm'
                        : 'ic-list'
                    }
                    style={{ opacity: viewlistopacity }}
                    icon={faThList}
                    onClick={handleListClick}
                  />
                </div>
              </div>
            </div>
            <div className='ic-itemview'>{itemview}</div>
            <div style={{ marginBottom: 50 }}>{pagination}</div>
          </div>
        </div>
      );
    } else if (props.whereFrom === 'DocumentLinks') {
      return (
        <div
          id='ic-container-id'
          className='ic-container'
          style={
            props.currentButtonsLength === 0 ||
            props.currentButtonsLength === undefined
              ? { display: 'flex' }
              : { display: 'none' }
          }
        >
          <div className='ic-subcontainer'>
            <div className='ic-subsubcontainer'>
              <Select
                styles={dlCustomStyles}
                value={dlOptions[dlSelectedOption]}
                onChange={handleDLChange}
                options={dlOptions}
                // onCreateOption={handleCreate}
                // isClearable
              />
              <div className='ic-switchviewcontainer'>
                <Select
                  classNamePrefix='select'
                  value={numberOptionsForDocumentLinks[dropdownSelectedIndex]}
                  onChange={handleDocumentLinksNumberOfItemsPerPage}
                  name='number'
                  options={numberOptionsForDocumentLinks}
                  styles={customStyles}
                  isSearchable={false}
                />
                <div className='ic-view-container'>
                  <FontAwesomeIcon
                    className={
                      props.whereFrom === 'RelatedMedia'
                        ? 'ic-bloc-rm'
                        : 'ic-bloc'
                    }
                    style={{ opacity: viewblocopacity }}
                    icon={faThLarge}
                    onClick={handleBlocClick}
                  />
                  <FontAwesomeIcon
                    className={
                      props.whereFrom === 'RelatedMedia'
                        ? 'ic-list-rm'
                        : 'ic-list'
                    }
                    style={{ opacity: viewlistopacity }}
                    icon={faThList}
                    onClick={handleListClick}
                  />
                </div>
              </div>
            </div>
            <div className='ic-itemview'>{itemview}</div>
            <div style={{ marginBottom: 50 }}>{pagination}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className='ic-container'
          style={
            props.currentButtonsLength === 0 ||
            props.currentButtonsLength === undefined
              ? { display: 'flex' }
              : { display: 'none' }
          }
        >
          <div className='ic-subcontainer'>
            <div className='ic-switchviewcontainer-container'>
              <div className='ic-switchviewcontainer'>
                <Select
                  classNamePrefix='select'
                  defaultValue={numberOptions[dropdownSelectedIndex]}
                  name='number'
                  options={numberOptions}
                  styles={customStyles}
                  isSearchable={false}
                  onChange={(e) => handleDropdownChange(e)}
                />
                <div className='ic-view-container'>
                  <FontAwesomeIcon
                    className='ic-bloc'
                    style={{ opacity: viewblocopacity }}
                    icon={faThLarge}
                    onClick={handleBlocClick}
                  />
                  <FontAwesomeIcon
                    className='ic-list'
                    style={{ opacity: viewlistopacity }}
                    icon={faThList}
                    onClick={handleListClick}
                  />
                </div>
              </div>
            </div>
            <div className='ic-itemview'>{itemview}</div>
            <div style={{ marginBottom: 50 }}>{pagination}</div>
          </div>
        </div>
      );
    }
  } else if (props.currentButtonsLength === 0) {
    return (
      <div className='ic-noresult-container'>
        <h2 className='ic-noresult-h1'>
          <Translate value='documentsEmptyMsg.noResult' />
        </h2>
        <p className='ic-noresult-p'></p>
        <button
          onClick={() => setNewPath(props.path.length - 2)}
          className='ic-noresult-btn'
        >
          <Translate value='documentsEmptyMsg.return' />
        </button>
      </div>
    );
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    currentProductsLength: state.canal.currentProductsLength,
    currentAssetsLength: state.classification_medias.currentAssetsLength,
    isFetchingMedias: state.classification_medias.isFetchingMedias,
    isFetchingProducts: state.canal.isFetchingProducts,
    assets: state.product.assetsData.assets,
    cart: state.cart,
    product: state.product,
    locale: state.i18n.locale,
    productsMAJ: state.homepageReducer.productsMAJ.data,
    mediasMAJ: state.homepageReducer.mediasMAJ.data,
    productsMAJlength: state.homepageReducer.productsMAJ.nbresults,
    mediasMAJlength: state.homepageReducer.mediasMAJ.nbresults,
    user: state.user,
    classificationIds: state.canal.classificationIds,
    mediasClassificationIds: state.classification_medias.classificationIds,
    audience: state.adminReducer.useraudience,
    instancedata: state.adminReducer.instancedata,
  };
}

export default connect(mapStateToProps)(ItemsContainer);
