export const translations = {
  en_GB: {
    loading: 'Loading ...',
    login: {
      accessDenied:
        'The information entered does not allow you to access the portal',
      standby: 'Your account is awaiting validation',
      returnToLogin: 'Back to the login page',
    },
    homepage: {
      slideriteminfo: 'See more',
      products: 'Products',
      allproducts: 'See all',
      medias: 'Assets',
      allmedias: 'See all',
      favoris: 'My favourites',
      allfavoris: 'See all',
      productsMAJ: 'Last updated products',
      mediasMAJ: 'Last updated assets',
      seeall: 'See all',
    },
    favourites: {
      title: 'My favorites',
      empty: 'No item',
      favouritesIsEmptyMsg:
        "Add items to your favourites by clicking on the 'star' icon.",
      return: 'Back',
    },
    settings: {
      title: 'Parameters',
      menuHeading1: 'User management',
      menuHeading2: 'Audience management',
      menuHeading3: 'Attribute management',
      menuHeading4: 'Content management - Prismic',
      listUsers: 'User list',
      listProfiles: 'Profile list',
      listAttributes: 'Attribute list',
      manageProfiles: 'Manage audiences',
      createProfile: 'Add an audience',
      searchUser: 'Search for an user',
      searchProfile: 'Search for an audience',
      show: 'Show',
      perPage: 'items per page',
    },
    header: {
      language: 'Language',
      settings: 'Parameters',
      logout: 'Sign out',
      dropdown: {
        settings: 'My parameters',
        favourites: 'My favourites',
        cart: 'My cart',
        logout: 'Sign out',
      },
    },
    subheader: {
      dashboard: 'Dashboard',
      produits: 'Products',
      medias: 'Assets',
      goback: 'Back',
    },
    tooltips: {
      favourite: 'Add/remove from my favourites',
      cart: 'Add/remove from my cart',
    },
    cart: {
      title: 'My cart',
      download: 'Download',
      empty: 'Your cart is empty',
      emptyCart: 'Empty my cart',
      cartIsEmptyMsg:
        'You can easily add media to the cart by clicking on the "cart" icon on a sheet or on a listing',
      return: 'Back',
      addToCartCTA: 'Add to cart',
    },
    maj: {
      productstitle: 'Last updated products',
      mediastitle: 'Last updated assets',
    },
    toast: {
      productNoMedias: 'This product has no asset.',
      addProductMediasToCart: 'Items have been added to your cart.',
      removeProductMediasFromCart: 'Items have been removed to your cart.',
      sendMediasByEmail:
        'You will receive these assets by email within a few minutes.',
      addMediaToCart: 'Item have been added to your cart.',
      removeMediaFromCart: 'Item have been removed to your cart.',
    },
    documentsMenuContainer: {
      selectionMode: '"Selection" mode',
      selectAll: 'Select all',
    },
    productPage: {
      loading: 'Loading...',
      skus: 'Variants',
      relatedMedia: {
        title: 'Assets',
        panorama: 'Open as a panorama',
        selected: 'selected item(s)',
        download: 'Download',
      },
    },
    documentsEmptyMsg: {
      noResult: 'No result',
      return: 'Back',
    },
    search: {
      header: {
        part1: 'Results :',
        part2: 'products',
        part3: 'assets',
        part4: 'for',
      },
      placeholder: 'Search for a product, an asset',
      products: 'Products',
      medias: 'Assets',
      allResults: 'See all',
      noQuery: 'No search has been made',
      filters: {
        documentType: 'Type',
        attributeSet: 'Attribute set',
        creationDate: 'Date created',
        updateData: 'Date updated',
        start: 'Start',
        end: 'End',
        classification: 'Categorization',
        refresh: 'Refresh',
        allSelected: 'Tous les éléments sont sélectionnés',
        selectAll: 'Select all',
        doFilter: 'Filtrer',
        doSelect: 'Sélectionner...',
      },
    },
    pagination: {
      previous: 'Previous',
      next: 'Next',
    },
  },
  fr_FR: {
    loading: 'Chargement ...',
    login: {
      accessDenied:
        "Les informations saisies ne vous permettent pas d'accéder au portail ",
      standby: 'Votre compte est en attente de validation ',
      returnToLogin: 'Retour à la page de login',
    },
    homepage: {
      slideriteminfo: 'En savoir plus',
      products: 'Produits',
      allproducts: 'Tous les produits',
      medias: 'Médias',
      allmedias: 'Tous les médias',
      favoris: 'Mes favoris',
      allfavoris: 'Tous mes favoris',
      productsMAJ: 'Derniers produits mis à jour',
      mediasMAJ: 'Derniers médias mis à jour',
      seeall: 'Tout visualiser',
    },
    favourites: {
      title: 'Mes favoris',
      empty: 'Aucun Favoris',
      favouritesIsEmptyMsg:
        "Vous pouvez ajouter facilement des favoris en cliquant sur l'étoile sur une fiche ou sur un listing",
      return: 'Retour',
    },
    settings: {
      title: 'Paramètres',
      menuHeading1: 'Gestion des utilisateurs',
      menuHeading2: 'Gestion des profils',
      menuHeading3: 'Gestion des attributs',
      menuHeading4: "Gestion de l'éditorial - Prismic",
      listUsers: 'Liste des utilisateurs',
      listProfiles: 'Liste des audiences',
      listAttributes: 'Liste des attributs',
      manageProfiles: 'Gestion des audiences',
      createProfile: 'Créer une audience',
      searchUser: 'Rechercher un utilisateur',
      searchProfile: 'Rechercher une audience',
      show: 'Afficher',
      perPage: 'éléments par page',
    },
    header: {
      language: 'Langue du contenu',
      settings: 'Paramètres',
      logout: 'Se déconnecter',
      dropdown: {
        settings: 'Mes paramètres',
        favourites: 'Mes favoris',
        cart: 'Mon panier',
        logout: 'Se déconnecter',
      },
    },
    subheader: {
      dashboard: 'Tableau de bord',
      produits: 'Produits',
      medias: 'Médias',
      goback: 'Retour',
    },
    tooltips: {
      favourite: 'Ajouter/retirer des favoris',
      cart: 'Ajouter/retirer du panier les médias',
    },
    cart: {
      title: 'Mon Panier',
      download: 'Télécharger',
      empty: 'Votre panier est vide',
      emptyCart: 'Vider mon panier',
      cartIsEmptyMsg:
        'Vous pouvez ajouter facilement des médias au panier en cliquant sur le panier sur une fiche ou sur un listing',
      return: 'Retour',
      addToCartCTA: 'Ajouter au panier',
    },
    maj: {
      productstitle: 'Derniers produits mis à jour',
      mediastitle: 'Derniers médias mis à jour',
    },
    toast: {
      productNoMedias: "Il n'y a pas de médias sur ce produit",
      addProductMediasToCart: 'Les médias ont été ajoutés dans le panier',
      removeProductMediasFromCart: 'Les médias ont été supprimés du panier',
      sendMediasByEmail:
        "Vous recevrez ces médias par mail d'ici quelques minutes.",
      addMediaToCart: 'Le média a été ajouté dans le panier',
      removeMediaFromCart: 'Le média a été supprimé du panier',
    },
    documentsMenuContainer: {
      selectionMode: 'Mode "sélection"',
      selectAll: 'Tout sélectionner',
    },
    productPage: {
      loading: 'Chargement du produit',
      skus: 'Variants',
      relatedMedia: {
        title: 'Médias',
        panorama: 'Ouvrir le panorama',
        selected: 'éléments(s) sélectionné(s)',
        download: 'Télécharger',
      },
    },
    documentsEmptyMsg: {
      noResult: 'Pas de résultat',
      return: 'Retour',
    },
    search: {
      header: {
        part1: 'Nous avons trouvé',
        part2: 'produits',
        part3: 'médias',
        part4: 'pour',
      },
      placeholder: 'Rechercher un produit, un média',
      products: 'Produits',
      medias: 'Médias',
      allResults: 'Tous les résultats',
      noQuery: 'Aucune recherche n\'a été faite.',
      filters: {
        documentType: 'Type de Document',
        attributeSet: "Jeu d'attribut",
        creationDate: 'Date de création',
        updateData: 'Date de mise à jour',
        start: 'Début',
        end: 'Fin',
        classification: 'Classification',
        refresh: 'Rafraîchir',
        allSelected: 'Tous les éléments sont sélectionnés',
        selectAll: 'Select all',
        doFilter: 'Filtrer',
        doSelect: 'Sélectionner...',
      },
    },
    pagination: {
      previous: 'Précédent',
      next: 'Suivant',
    },
  }
};

export default translations;
