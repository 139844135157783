import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import Prismic from 'prismic-javascript'
import React, { useEffect, Fragment, useState } from 'react'
import { useCookies } from 'react-cookie'
import Favicon from 'react-favicon'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { setLocale } from 'react-redux-i18n'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute, Authenticate } from './components/authentification'
import * as ROUTES from './constants/routes'
import history from './history'
import {
  AccessDenied,
  Attributes,
  Cart,
  Favorite,
  Help,
  Homepage,
  Loading,
  MajPage,
  MediaInfo,
  MediaPage,
  Medias,
  NotFound,
  Parametres,
  Preview,
  ProductPage,
  Produits,
  Profils,
  Search,
  StandBy
} from './pages'
import { storeInstanceData } from './redux/actions/adminAction'
import { getAttributes } from './redux/actions/adminAction'
import { fetchCanal } from './redux/actions/canalActions'
import { fetchLanguages } from './redux/actions/languagesActions'
import { fetchMedias } from './redux/actions/mediasActions'
import { fetchDocumentTypeInfo } from './redux/actions/productActions'

/**
 * Main application component
 */
const App = (props) => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0()

  const [cookies, setCookie, removeCookie] = useCookies(['user'])

  const [pr_home, setPr_home] = useState()
  const [repoName, setRepoName] = useState()
  const [prismicParametersSet, setPrismicParametersSet] = useState(false)

  const [interceptorsHaveBeenSet, setInterceptorsHaveBeenSet] = useState(false)
  let instancename = window.location.hostname.split('.')[0]
  //si on est sur localhost on force la connexion sur sisley pour éviter d'avoir des erreurs de connexion en dev
  if ('localhost' === instancename || 'recette-quable-portail' == instancename || 'dev-quable-portail' == instancename)
    instancename = 'sisley'

  const updateInstanceData = async () => {
    const token = await getIdTokenClaims()
    props.dispatch(getAttributes(token))
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token.__raw}`
      }
    })

    const instance_data_url = `${
      process.env.REACT_APP_QUABLE_API_PROTOCOL + instancename + process.env.REACT_APP_QUABLE_API_HOST
    }/instances/${props.quableuserinstance.instance}`
    instance
      .get(instance_data_url)
      .then((resp) => {
        props.dispatch(storeInstanceData(resp.data))
        props.dispatch(
          fetchCanal(props.locale, props.instancedata.pimParameters.classificationCatalog, props.instancedata.id)
        )
        props.dispatch(fetchMedias(props.locale, props.instancedata.id))
        props.dispatch(fetchDocumentTypeInfo(props.locale, props.instancedata.id))
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((token) => {
        const AuthStr = `Bearer ${token.__raw}`
        axios.interceptors.request.use((request) => {
          //TODO temporary work around, clear when axios refactor is done
          if (!request.url.includes('http') && !request.url.includes('https')) {
            request.url = `${process.env.REACT_APP_QUABLE_API_PROTOCOL}${instancename}${process.env.REACT_APP_QUABLE_API_HOST}${request.url}`
          }
          request.headers['Authorization'] = AuthStr
          request.headers['Content-Type'] = 'application/ld+json'
          return request
        })
        setInterceptorsHaveBeenSet(true)
        props.dispatch(getAttributes(token))
      })
    }
  }, [props.instancedata.pimParameters])

  useEffect(() => {
    if (interceptorsHaveBeenSet && isAuthenticated && props.instancedata.id !== undefined) {
      if (props.locale === undefined) {
        console.log('SET LANGUAGE')
        props.dispatch(setLocale('fr_FR'))
      }
      props.dispatch(fetchLanguages(props.instancedata.id))
    }
  }, [interceptorsHaveBeenSet])

  useEffect(() => {
    removeCookie()

    if (props.locale !== undefined && isAuthenticated && interceptorsHaveBeenSet) {
      if (
        props.instancedata !== undefined &&
        props.instancedata.pimParameters !== undefined &&
        props.instancedata.prismicParameters !== undefined
      ) {
        const client = Prismic.client(
          `${props.instancedata.prismicParameters.apiUrl}?access_token=${props.instancedata.prismicParameters.accessToken}`
        )

        const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(
          `${props.instancedata.prismicParameters.apiUrl}?access_token=${props.instancedata.prismicParameters.accessToken}`
        )
        setRepoName(repoNameArray[1])
        client
          .query(Prismic.Predicates.at('document.type', 'homepage'), {
            lang: props.locale.replace('_', '-').toLowerCase()
          })
          .then((res) => {
            if (0 < res.results.length) {
              setPr_home(res.results[0])
            } else {
              client
                .query(Prismic.Predicates.at('document.type', 'homepage'), {
                  lang: 'en-gb'
                })
                .then((res) => {
                  setPr_home(res.results[0])
                })
            }
          })

        client
          .query(Prismic.Predicates.at('document.type', 'parametres'), {
            lang: props.locale.replace('_', '-').toLowerCase()
          })
          .then((res) => {
            if (0 < res.results.length) {
              window.$login_logo = res.results[0].data.login_logo.url
              window.$logo = res.results[0].data.logo.url
              window.$favicon = res.results[0].data.favicon.url
              window.$bandeau = res.results[0].data.bandeau.url
              window.$image_connexion = res.results[0].data.image_connexion.url
              window.$visuel_defaut = res.results[0].data.visuel_par_defaut.url
              window.$contenu = res.results[0].data.contenu
              document.title = res.results[0].data.document_title[0].text
              document.documentElement.style.setProperty('--global-color-1', res.results[0].data.global_color_1)
              document.documentElement.style.setProperty('--global-color-2', res.results[0].data.global_color_2)
              document.documentElement.style.setProperty('--global-color-3', res.results[0].data.global_color_3)
              document.documentElement.style.setProperty('--entete', res.results[0].data.entete)
              setPrismicParametersSet(true)
            } else {
              client
                .query(Prismic.Predicates.at('document.type', 'parametres'), {
                  lang: 'en-gb'
                })
                .then((res) => {
                  window.$login_logo = res.results[0].data.login_logo.url
                  window.$logo = res.results[0].data.logo.url
                  window.$favicon = res.results[0].data.favicon.url
                  window.$bandeau = res.results[0].data.bandeau.url
                  window.$image_connexion = res.results[0].data.image_connexion.url
                  window.$visuel_defaut = res.results[0].data.visuel_par_defaut.url
                  window.$contenu = res.results[0].data.contenu
                  document.title = res.results[0].data.document_title[0].text
                  document.documentElement.style.setProperty('--global-color-1', res.results[0].data.global_color_1)
                  document.documentElement.style.setProperty('--global-color-2', res.results[0].data.global_color_2)
                  document.documentElement.style.setProperty('--global-color-3', res.results[0].data.global_color_3)
                  document.documentElement.style.setProperty('--entete', res.results[0].data.entete)
                  setPrismicParametersSet(true)
                })
            }
          })

        props.dispatch(
          fetchCanal(props.locale, props.instancedata.pimParameters.classificationCatalog, props.instancedata.id)
        )
        props.dispatch(fetchMedias(props.locale, props.instancedata.id))
        props.dispatch(fetchDocumentTypeInfo(props.locale, props.instancedata.id))
      } else {
        updateInstanceData()
      }
    }
    return () => {
      removeCookie()
    }
  }, [props.locale, props.instancedata.pimParameters, interceptorsHaveBeenSet])

  if (
    isAuthenticated &&
    repoName !== undefined &&
    pr_home !== undefined &&
    prismicParametersSet &&
    interceptorsHaveBeenSet
  ) {
    return (
      <div>
        <Favicon url={window.$favicon} />
        <Fragment>
          <Helmet>
            <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
          </Helmet>
          <BrowserRouter history={history}>
            <Switch>
              <PrivateRoute exact component={Authenticate} path="/" />
              <PrivateRoute exact component={Produits} path="/produits" />
              <PrivateRoute exact component={Medias} path="/medias" />
              <PrivateRoute exact component={Parametres} path="/parametres" />
              <PrivateRoute exact component={Profils} path="/profils" />
              <PrivateRoute exact component={Favorite} path={ROUTES.FAVORITE} />
              <PrivateRoute exact component={Cart} path="/cart" />
              <PrivateRoute exact component={Search} path={ROUTES.SEARCH} />
              <PrivateRoute exact component={ProductPage} path="/product" />
              <PrivateRoute exact component={MediaPage} path="/mediapage" />
              <PrivateRoute exact component={MediaInfo} path="/media-info" />
              <PrivateRoute exact component={MajPage} path="/majpage" />
              <PrivateRoute exact component={Help} path="/help" />
              <PrivateRoute exact component={Preview} path="/preview" />
              <PrivateRoute exact component={Attributes} path="/attributes" />
              <PrivateRoute exact component={AccessDenied} path="/accessdenied" />
              <PrivateRoute exact component={StandBy} path="/standby" />

              <PrivateRoute exact component={() => <Homepage {...props} pr_home={pr_home} />} path="/homepage" />
              <Redirect from="/" to="/homepage" />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </Fragment>
      </div>
    )
  }
  return (
    <Fragment>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
      <BrowserRouter history={history}>
        <Switch>
          <PrivateRoute exact component={Authenticate} path="/" />
          <PrivateRoute exact component={Loading} path="/produits" />
          <PrivateRoute exact component={Loading} path="/medias" />
          <PrivateRoute exact component={Loading} path="/parametres" />
          <PrivateRoute exact component={Loading} path="/profils" />
          <PrivateRoute exact component={Loading} path={ROUTES.FAVORITE} />
          <PrivateRoute exact component={Loading} path="/cart" />
          <PrivateRoute exact component={Loading} path={ROUTES.FAVORITE} />
          <PrivateRoute exact component={Loading} path="/product" />
          <PrivateRoute exact component={Loading} path="/mediapage" />
          <PrivateRoute exact component={Loading} path="/media-info" />
          <PrivateRoute exact component={Loading} path="/majpage" />
          <PrivateRoute exact component={Loading} path="/help" />
          <PrivateRoute exact component={Loading} path="/preview" />
          <PrivateRoute exact component={Loading} path="/attributes" />
          <PrivateRoute exact component={AccessDenied} path="/accessdenied" />
          <PrivateRoute exact component={StandBy} path="/standby" />

          <PrivateRoute exact component={Loading} path="/homepage" />
          <Redirect from="/" to="/homepage" />
          <Route exact component={NotFound} path="/error" />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  )
}

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    instancedata: state.adminReducer.instancedata,
    quableuserinstance: state.adminReducer.quableuserinstance
  }
}

export default connect(mapStateToProps)(App)
