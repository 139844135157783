import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-redux-i18n';
import '../styles/components/TreeViewSelectedPath.css';
import history from '../history';
import { connect } from 'react-redux';

const TreeViewSelectedPath = (props) => {
  function setNewPath(index) {
    props.updateHistory(props.path.slice(1, index + 1).join('+'));
  }

  return (
    <div
      className='tvsp-root'
      style={
        props.path.length === 1 ? { display: 'none' } : { display: 'flex' }
      }
    >
      <div className='tvsp-retour' onClick={() => history.go(-1)}>
        <FontAwesomeIcon className='icon' icon={faArrowLeft} />
        <Translate className='tvsp-retour-txt' value='subheader.goback' />
      </div>
      <div className='tvsp-path'>
        {props.path.length > 1 ? (
          props.path.map((el, index) => (
            <div key={'tvsp-' + index} className='tvsp-el'>
              <button
                className='tvsp-el-button'
                onClick={() => setNewPath(index)}
              >
                {props.whereFrom === 'Produits' ||
                props.whereFrom === 'ProductPage'
                  ? props.classificationIds.map((ci, i) => {
                      if (index === 0 && i === 0) {
                        return <span>{el}</span>;
                      } else if (ci.value === el) {
                        return <span>{ci.label}</span>;
                      }
                    })
                  : props.mediasClassificationIds.map((ci, i) => {
                      if (index === 0 && i === 0) {
                        return <span>{el}</span>;
                      } else if (ci.value === el) {
                        return <span>{ci.label}</span>;
                      }
                    })}
              </button>
              <FontAwesomeIcon
                className='tvsp-el-arrow'
                icon={faChevronRight}
              />
            </div>
          ))
        ) : (
          <div></div>
        )}
        <p
          className='tvsp-product-name'
          style={
            props.whereFrom === 'ProductPage'
              ? { display: 'flex' }
              : { display: 'none' }
          }
        >
          {props.product.productData.product !== undefined &&
          props.product.productData.product.data.attributes[
            props.product.productData.product.data.documentType.id + '_name'
          ] !== undefined
            ? props.product.productData.product.data.attributes[
                props.product.productData.product.data.documentType.id + '_name'
              ][props.locale]
            : 'none'}
        </p>
      </div>
      <div className='tvsp-end'></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    classificationIds: state.canal.classificationIds,
    mediasClassificationIds: state.classification_medias.classificationIds,
    product: state.product,
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(TreeViewSelectedPath);
