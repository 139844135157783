/**
 * Created by mnassih on 25/09/2020.
 */
import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/pages/AcessDenied.css';
import {useAuth0} from '@auth0/auth0-react';
import {Translate} from 'react-redux-i18n';

/**
 * Page not found (404) componenet
 */
const StandBy = () => {

    const logoUrl = localStorage.getItem('logoUrl');
    const colorG = localStorage.getItem('colorG');
    const {logout} = useAuth0();

    return (
        <div >

            <div className="denied_header">
              <img src={logoUrl} width="150px" />
            </div>
            <div className="denied_msg">
                <Translate value='login.standby'/>
                <div className="denied_btn-container">
                    <button className='denied-footerstyle-b'
                            onClick={() =>
                                logout({
                                    returnTo: window.location.origin,
                                })
                            }>
                        <Translate value='login.returnToLogin'/>
                    </button>
                </div>

            </div>
        </div>
    )
}


export default StandBy
