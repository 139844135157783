import React, { useState, useEffect } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync,
  faDownload,
  faChevronRight,
  faChevronLeft,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import '../../styles/components/ProductPage/VisualizeMedia.css';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import Downloader from 'js-file-downloader';
import { addToCart } from '../../redux/actions/cartActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiProductImageCarousel } from '../';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { Translate } from 'react-redux-i18n';

const VisualizeMedia = (props) => {
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const [assetsData, setAssetsData] = useState([]);
  const [value, setValue] = useState(0);
  const [definition, setDefinition] = useState('hd');

  const [slides, setSlides] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);

  var I18n = require('react-redux-i18n').I18n;

  const notify = () => toast(I18n.t('toast.addMediaToCart'));
  useEffect(() => {
    let assetsData = [];
    let slds = [];
    let thmnails = [];
    Object.values(props.assetsData).forEach((assets, index) => {
      assets.map((assetData) => assetsData.push(assetData));
      assets.map((assetData) => slds.push(assetData['slide']));
      assets.map((assetData) => thmnails.push(assetData['thumbnail']));
    });
    setAssetsData(assetsData);
    setSlides(slds);
    setThumbnails(thmnails);
  }, [props]);

  function setCurrentImageUrlFromChild(url) {
    setCurrentImageUrl(url);
  }

  function setAddToCartButton(value) {
    for (let i = 0; i < props.cart.length; i++) {
      if (props.cart[i].name === assetsData[value]['name']) {
        document.getElementById('sendToCartButton').disabled = true;
        break;
      } else {
        document.getElementById('sendToCartButton').disabled = false;
      }
    }
  }

  function setValueFromChild(value) {
    setValue(value);
    setAddToCartButton(value);
  }

  function downloadFile() {
    new Downloader({
      url: assetsData[value]['url'],
    })
      .then(function () {})
      .catch(function (error) {});
  }

  function sendMediaToCart() {
    props.dispatch(addToCart(assetsData[value]));
    notify();

    document.getElementById('sendToCartButton').innerHTML = 'Ajouté au panier';
    document.getElementById('sendToCartButton').disabled = true;
  }

  function handleClick(def) {
    setDefinition(def);
  }

  let extension = currentImageUrl.substring(
    currentImageUrl.lastIndexOf('.') + 1,
    currentImageUrl.length
  );

  return (
    <Popup
      trigger={props.trigger}
      modal
      position='center center'
      closeOnDocumentClick
      contentStyle={{
        width: 1024,
        maxWidth: '95%',
        background: 'rgb(32,32,32)',
        border: 'none',
      }}
    >
      {(close) => (
        <div className='vm-container'>
          <ToastContainer hideProgressBar />
          <FontAwesomeIcon
            className='vm-close'
            icon={faTimes}
            onClick={close}
          />
          <p className='vm-media-name' style={{ color: 'white' }}>
            {assetsData[value]['name']}
          </p>
          <div className='vm-info-container'>
            <div className='vm-info-subcontainer'>
              <FontAwesomeIcon style={{ marginRight: 5 }} icon={faFile} />
              <p style={{ marginRight: 10 }}>
                {assetsData[value]['dateCreated']}
              </p>
              <FontAwesomeIcon style={{ marginRight: 5 }} icon={faSync} />
              <p style={{ marginRight: 10 }}>
                {assetsData[value]['dateModified']}
              </p>
            </div>
            <p
              style={
                assetsData[value].attributes.media_droits !== undefined
                  ? {}
                  : { display: 'none' }
              }
            >
              {assetsData[value].attributes.media_droits !== undefined
                ? 'Droits : ' +
                  assetsData[value].attributes.media_droits[props.locale]
                : ''}
            </p>
          </div>
          <div className='vm-button-container'>
            <button className='vm-download-btn'>
              {/* <div
                onClick={() => handleClick('hd')}
                className={
                  definition === 'hd'
                    ? 'definition-btn-active'
                    : 'definition-btn'
                }>
                HD
              </div>
              <div
                onClick={() => handleClick('sd')}
                className={
                  definition === 'sd'
                    ? 'definition-btn-active'
                    : 'definition-btn'
                }
                style={{ marginRight: 10 }}>
                SD
              </div> */}
              <a onClick={() => downloadFile()}>
                <Translate value='productPage.relatedMedia.download' />
              </a>
            </button>
            <button
              id='sendToCartButton'
              className='vm-add-btn'
              onClick={sendMediaToCart}
            >
              <Translate value='cart.addToCartCTA' />
            </button>
          </div>
          {/* <div style={{ width: window.innerWidth }}> */}
          <div
            className='vm-image-and-carousel-container'
            style={{ width: '100%' }}
          >
            <div
              className='vm-mainimage'
              style={
                extension !== 'pdf' &&
                extension !== 'mp4' &&
                extension !== 'xlsx' &&
                extension !== 'pptx'
                  ? {
                      backgroundImage: 'url(' + currentImageUrl + ')',
                    }
                  : { display: 'none' }
              }
            ></div>
            <div
              className='vm-mainimage'
              style={
                extension === 'xlsx' || extension === 'pptx'
                  ? {
                      backgroundImage: 'url(' + window.$visuel_defaut + ')',
                    }
                  : { display: 'none' }
              }
            ></div>
            <iframe
              sandbox
              style={extension === 'pdf' ? {} : { display: 'none' }}
              className='vm-mainimage'
              src={
                extension === 'pdf' ? currentImageUrl.replace('jpg', 'pdf') : ''
              }
            ></iframe>
            <div
              className='vm-video'
              style={extension === 'mp4' ? {} : { display: 'none' }}
            >
              <Player src={currentImageUrl.replace('jpg', 'mp4')} />
            </div>
            <div className='vm-carousel-container'>
              <MultiProductImageCarousel
                setCurrentImageUrl={setCurrentImageUrlFromChild}
                whereFrom='VisualizeMedia'
                setValueFromChild={setValueFromChild}
              />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

function mapStateToProps(state) {
  return {
    cart: state.cart.items,
    assetsData: state.product.assetsData.assets,
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(VisualizeMedia);
