import { combineReducers } from 'redux';
import { canalReducer } from './canalReducer';
import { productReducer } from './productReducer';
import { i18nReducer } from 'react-redux-i18n';
import { cartReducer } from './cartReducer';
import { mediasReducer } from './mediasReducer';
import { userReducer } from './userReducer';
import { searchReducer } from './searchReducer';
import { adminReducer } from './adminReducer';
import { homepageReducer } from './homepageReducer';
import { languagesReducer } from './languagesReducer';
import { RESET_STORE } from '../actions/types';

const appReducer = combineReducers({
  i18n: i18nReducer,
  canal: canalReducer,
  product: productReducer,
  cart: cartReducer,
  classification_medias: mediasReducer,
  user: userReducer,
  search: searchReducer,
  adminReducer: adminReducer,
  homepageReducer: homepageReducer,
  languagesReducer: languagesReducer,
});

// reset the state of a redux store
const rootReducer = (state, action) => {

    if (action.type === RESET_STORE) {
        console.log("CALL RESET STORE ", action.type)
        state = undefined;
    }
    return appReducer(state, action)
}

export default rootReducer;
