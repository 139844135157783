import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from './redux/store';
import App from './App';
import './index.css';
import axios from 'axios';
import Auth0ProviderWithHistory from './components/authentification/auth0-provider-with-history';

async function run() {
  // var accessToken = process.env.REACT_APP_API_TOKEN;
  // const AuthStr = 'Bearer '.concat(accessToken);
  // const requestHandler = (request) => {
  //   request.headers['Authorization'] = AuthStr;
  //   return request;
  // };
  // axios.interceptors.request.use((request) => requestHandler(request));

  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
    </Provider>,
    document.getElementById('root')
  );
}

run();
