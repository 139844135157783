import React, { useState, useEffect } from 'react';
import '../styles/pages/Parametres.css';
import { Header, SubHeader } from '../components';
import { AudienceForm } from '../components/authentification';
import { Translate } from 'react-redux-i18n';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ParamMenu } from '../components';
import {
  faUsers,
  faUserPlus,
  faPlusCircle,
  faProjectDiagram,
  faEdit,
  faBan,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import { getAudiences, deleteAudience } from '../redux/actions/adminAction';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const Profils = (props) => {
  const initialPageSize = 5;

  var gridApi;
  const [gridEditApi, setGridEditApi] = useState({});
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [createMode, setCreateMode] = useState(false);
  const [gridLoaded, setGridLoaded] = useState(false);

  const { getIdTokenClaims } = useAuth0();

  const [open, setOpen] = useState(false);
  const [audienceSelected, setAudienceSelected] = useState({});
  const closeModal = () => {
    setCreateMode(false);
    setGridLoaded(false);
    setOpen(false);
    callApi();
  };

  var I18n = require('react-redux-i18n').I18n;

  useEffect(() => {
    callApi();
  }, []);

  const onGridReady = (params) => {
    gridApi = params.api;
    setGridEditApi(params.api);
    setGridColumnApi(params.columnApi);
    sizeToFit();
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridEditApi.paginationSetPageSize(Number(value));
  };

  function onFilterTextBoxChanged(e) {
    gridEditApi.setQuickFilter(e.target.value);
  }

  function buttonRenderer() {
    return (
      <div>
        <a onClick={onButtonClick} className='action-button'>
          <FontAwesomeIcon className='parametres-table-btn1' icon={faEdit} />
        </a>
        <a onClick={submit} className='action-button'>
          <FontAwesomeIcon className='parametres-table-btn2' icon={faTimes} />
        </a>
      </div>
    );
  }

  const callApi = async () => {
    const token = await getIdTokenClaims();
    props.dispatch(getAudiences(token)).then(() => {
      setGridLoaded(true);
    });
  };

  function onButtonClick(e) {
    const selectedNodes = gridApi.getSelectedNodes();
    console.log(selectedNodes);
    const selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.user + ' ' + node.model)
      .join(', ');
    //alert(`Selected nodes: ${selectedDataStringPresentation}`)
    setAudienceSelected(selectedData[0]);
    setOpen((o) => !o);
  }

  function onDeleteClick(e) {
    const selectedNodes = gridApi.getSelectedNodes();
    console.log(selectedNodes);
    const selectedData = selectedNodes.map((node) => node.data);
    console.log(selectedData);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.user + ' ' + node.model)
      .join(', ');
    //alert(`Selected nodes: ${selectedDataStringPresentation}`)
    setAudienceSelected(selectedData[0]);
    getIdTokenClaims().then((token) => {
      props.dispatch(deleteAudience(token, selectedData[0])).then((o) => {
        callApi();
      });
    });
  }

  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // Ouverture de la PopUp en mode création

  const openInCreateMode = () => {
    let newAudience = { name: '', filters: {} };
    newAudience.filters.attributes = { zone_de_diffusion_codes: '' };
    setAudienceSelected(newAudience);
    setCreateMode(true);
    setOpen((o) => !o);
  };

  const submit = () => {
    confirmAlert({
      title: 'Confirmation',
      message:
        'La suppression de cet élément est irréversible et ne peut être annulée',
      buttons: [
        {
          label: 'Confirmer',
          onClick: () => onDeleteClick(),
        },
        {
          label: 'Annuler',
          onClick: () => {},
        },
      ],
    });
  };

  const columnDefs = [
    {
      headerName: 'Nom',
      field: 'name',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Filtre',
      field: 'filters.attributes.zone_de_diffusion_codes',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: 'buttonRenderer',
    },
  ];

  const gridContent = () => {
    return !gridLoaded ? (
      <div className='parametres-right'>
        <Loader type='TailSpin' color='black' height={100} width={100} />
      </div>
    ) : (
      <div className='parametres-right'>
        <Row className='row_bottom_margin'>
          <Col xs='8'>
            <Translate value='settings.listProfiles' />
          </Col>
          <Col xs='4'>
            <input
              type='text'
              id='filter-text-box'
              placeholder={I18n.t('settings.searchProfile')}
              onInput={onFilterTextBoxChanged}
              className='grid-search-box'
            />
          </Col>
        </Row>
        <div className='ag-theme-alpine' style={{ width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            frameworkComponents={{ buttonRenderer: buttonRenderer }}
            rowData={props.audiences}
            pagination={true}
            paginationPageSize={initialPageSize}
            domLayout='autoHeight'
            onGridReady={onGridReady}
            rowSelection='single'
          ></AgGridReact>
          <Row className='page-size-select-row'>
            <Col xs='12'>
              <Translate value='settings.show' />
              <select
                onChange={() => onPageSizeChanged()}
                id='page-size'
                className='page-size-select'
              >
                <option value='10' selected=''>
                  10
                </option>
                <option value='20'>20</option>
                <option value='30'>30</option>
                <option value='40'>40</option>
                <option value='50'>50</option>
                <option value='60'>60</option>
                <option value='70'>70</option>
                <option value='80'>80</option>
                <option value='90'>90</option>
                <option value='100'>100</option>
              </select>{' '}
              <Translate value='settings.perPage' />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <SubHeader>
        <Translate value='settings.title' />
      </SubHeader>
      <div className='parametres-container'>
        <div className='parametres-subcontainer'>
          <ParamMenu openInCreateMode={openInCreateMode} />
          {gridContent()}
        </div>
      </div>
      <Footer></Footer>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={{
          width: '35%',
          margin: 'auto',
          border: '1px solid black',
          padding: '5px',
          height: '70%',
          borderRadius: '5px',
        }}
        modal
        position='top center'
      >
        {(close) => (
          <AudienceForm
            audienceSelected={audienceSelected}
            createMode={createMode}
            closePopUP={close}
          />
        )}
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  console.log(state);
  return {
    audiences: state.adminReducer.audiences,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Profils
);
