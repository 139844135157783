import { SET_USER_CREDENTIALS, ADD_ITEM_TO_WISHLIST, REMOVE_ITEM_FROM_WISHLIST, STORE_USER_INSTANCE } from './types'

export const setUserCredentials = (credentials) => ({
  type: SET_USER_CREDENTIALS,
  payload: {
    username: credentials.username,
    password: credentials.password
  }
})

export const addItemToWishlist = (item) => ({
  type: ADD_ITEM_TO_WISHLIST,
  payload: item
})

export const removeItemFromWishlist = (index) => ({
  type: REMOVE_ITEM_FROM_WISHLIST,
  payload: index
})

export const registerUserInstance = (instancename) => ({
  type: STORE_USER_INSTANCE,
  payload: instancename
})
