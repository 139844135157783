import axios from 'axios'
import {
  REQUEST_SEARCH_RESULTS,
  RECEIVE_PRODUCTS_SEARCH_RESULTS,
  REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED,
  RECEIVE_MEDIAS_SEARCH_RESULTS,
  REQUEST_MEDIAS_SEARCH_RESULTS_FAILED,
  REQUEST_ATTRIBUTE_SETS,
  RECEIVE_ATTRIBUTE_SETS,
  REQUEST_DOCUMENT_TYPES,
  RECEIVE_DOCUMENT_TYPES
} from './types'

// Exported Action

export const requestSearchResults = (searchInput) => ({
  type: REQUEST_SEARCH_RESULTS,
  payload: searchInput
})

export const fetchSearchResults = (
  searchInput,
  dates,
  documentTypesIds,
  attributeSetsIds,
  classificationIds,
  mediasClassificationIds,
  pageNumber,
  locale,
  instancename
) => (dispatch) => {
  dispatch(requestSearchResults(searchInput))
  classificationIds = 130 < classificationIds.length ? classificationIds.slice(0, 130) : classificationIds
  dispatch(
    fetchProductsSearchResults(
      searchInput,
      dates,
      documentTypesIds,
      attributeSetsIds,
      classificationIds,
      pageNumber,
      locale,
      instancename
    )
  )
  mediasClassificationIds =
    130 < mediasClassificationIds.length ? mediasClassificationIds.slice(0, 130) : mediasClassificationIds
  dispatch(fetchMediasSearchResults(searchInput, dates, mediasClassificationIds, pageNumber, instancename))
}

export const fetchMediasSearchResults = (searchInput, dates, classificationIds, pageNumber, instancename) => (
  dispatch
) =>
  axios
    .get(
      dates !== undefined
        ? `/assets?search=${searchInput}${
            null !== dates['dateCreatedAfter'] ? `&dateCreated%5Bafter%5D=${dates['dateCreatedAfter']}` : ''
          }${null !== dates['dateCreatedBefore'] ? `&dateCreated%5Bbefore%5D=${dates['dateCreatedBefore']}` : ''}${
            null !== dates['dateModifiedAfter'] ? `&dateModified%5Bafter%5D=${dates['dateModifiedAfter']}` : ''
          }${
            null !== dates['dateModifiedBefore'] ? `&dateModified%5Bbefore%5D=${dates['dateModifiedBefore']}` : ''
          }&page=${pageNumber}&limit=6&pagination=true`
        : `/assets?search=${searchInput}&page=${pageNumber}&limit=6&pagination=true`
    )
    .then((response) => {
      dispatch(
        receiveMediasSearchResults({
          nbresults: response.data['hydra:totalItems'],
          medias: response.data['hydra:member']
        })
      )
    })
    .catch((err) => {
      dispatch(requestMediasSearchResultsFailed(err))
    })

export const fetchSearchParameters = (locale, instancename) => (dispatch) => {
  dispatch(fetchDocumentTypes(locale, instancename))
}

// Internal Action

const fetchAttributeSets = (locale, instancename, documentType) => (dispatch) => {
  dispatch(requestAttributeSets())
  return axios.get(`/document-types/${documentType}/attribute-sets`).then((response) => {
    response.data['hydra:member']
      .filter((item) => 'document' === item.objectType)
      .map((documentType) => {
        dispatch(fetchAttributeSets(locale, instancename, documentType.id))
      })
    dispatch(
      receiveAttributeSets([
        documentType,
        response.data['hydra:member'].map((attributeSet) => ({
          value: attributeSet.id,
          label: attributeSet.name[locale.replace('_', '-')]
        }))
      ])
    )
  })
}

const fetchDocumentTypes = (locale, instancename) => (dispatch) => {
  dispatch(requestDocumentTypes())
  return axios.get(`/document-types`).then((response) => {
    response.data['hydra:member']
      .filter((item) => 'document' === item.objectType)
      .forEach((documentType) => {
        dispatch(fetchAttributeSets(locale, instancename, documentType.id))
      })
    dispatch(
      receiveDocumentTypes(
        response.data['hydra:member']
          .filter((item) => 'document' === item.objectType)
          .map((documentType) => ({
            value: documentType.id,
            label: documentType.name[locale.replace('_', '-')]
          }))
      )
    )
  })
}

const requestProductsSearchResultsFailed = (err) => ({
  type: REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED,
  payload: err
})

const receiveProductsSearchResults = (search) => ({
  type: RECEIVE_PRODUCTS_SEARCH_RESULTS,
  payload: {
    searchInput: search['searchInput'],
    nbresults: search['nbresults'],
    products: search['products']
  }
})

const requestMediasSearchResultsFailed = (err) => ({
  type: REQUEST_MEDIAS_SEARCH_RESULTS_FAILED,
  payload: err
})

const receiveMediasSearchResults = (search) => ({
  type: RECEIVE_MEDIAS_SEARCH_RESULTS,
  payload: {
    searchInput: search['searchInput'],
    nbresults: search['nbresults'],
    medias: search['medias']
  }
})

const requestAttributeSets = () => ({
  type: REQUEST_ATTRIBUTE_SETS
})

const receiveAttributeSets = (data) => ({
  type: RECEIVE_ATTRIBUTE_SETS,
  payload: data
})

const requestDocumentTypes = () => ({
  type: REQUEST_DOCUMENT_TYPES
})

const receiveDocumentTypes = (data) => ({
  type: RECEIVE_DOCUMENT_TYPES,
  payload: data
})

const fetchProductsSearchResults = (
  searchInput,
  dates,
  documentTypesIds,
  attributeSetsIds,
  classificationIds,
  pageNumber,
  locale,
  instancename
) => (dispatch) => {
  const documentTypesIdsCall =
    documentTypesIds !== undefined ? documentTypesIds.map((item) => `&documentType.id%5B%5D=${item}`) : []
  const attributeSetsIdsCall =
    attributeSetsIds !== undefined ? attributeSetsIds.map((item) => `&attributeSet.id%5B%5D=${item}`) : []
  return axios
    .get(
      dates !== undefined
        ? `/documents?search=${searchInput}${
            null !== dates['dateCreatedAfter'] ? `&dateCreated%5Bafter%5D=${dates['dateCreatedAfter']}` : ''
          }${null !== dates['dateCreatedBefore'] ? `&dateCreated%5Bbefore%5D=${dates['dateCreatedBefore']}` : ''}${
            null !== dates['dateModifiedAfter'] ? `&dateModified%5Bafter%5D=${dates['dateModifiedAfter']}` : ''
          }${
            null !== dates['dateModifiedBefore'] ? `&dateModified%5Bbefore%5D=${dates['dateModifiedBefore']}` : ''
          }${documentTypesIdsCall.join('')}${attributeSetsIdsCall.join('')}&page=${pageNumber}&limit=6&pagination=true`
        : `/documents?search=${searchInput}&page=${pageNumber}&limit=6&pagination=true`
    )
    .then((response) => {
      dispatch(
        receiveProductsSearchResults({
          nbresults: response.data['hydra:totalItems'],
          products: response.data['hydra:member']
        })
      )
    })
    .catch((err) => {
      dispatch(requestProductsSearchResultsFailed(err))
    })
}
