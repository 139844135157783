import {
  REQUEST_SEARCH_RESULTS,
  REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED,
  RECEIVE_PRODUCTS_SEARCH_RESULTS,
  REQUEST_MEDIAS_SEARCH_RESULTS_FAILED,
  RECEIVE_MEDIAS_SEARCH_RESULTS,
  REQUEST_ATTRIBUTE_SETS,
  RECEIVE_ATTRIBUTE_SETS,
  REQUEST_DOCUMENT_TYPES,
  RECEIVE_DOCUMENT_TYPES
} from '../actions/types'

const initialState = {
  searchInput: null,
  documentTypes: null,
  attributeSets: {},
  products: {
    status: null,
    error: null,
    isFetching: false,
    nbresults: null,
    data: []
  },
  medias: {
    status: null,
    error: null,
    isFetching: false,
    nbresults: null,
    data: []
  }
}

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SEARCH_RESULTS:
      return {
        ...state,
        searchInput: action.payload,
        products: {
          ...state.products,
          isFetching: true
        },
        medias: {
          ...state.medias,
          isFetching: true
        }
      }
    case REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          status: 'error',
          error: action.payload.toString(),
          isFetching: false
        }
      }
    case RECEIVE_PRODUCTS_SEARCH_RESULTS:
      return {
        ...state,
        products: {
          ...state.products,
          status: 'ok',
          nbresults: action.payload['nbresults'],
          data: action.payload['products'],
          isFetching: false
        }
      }
    case REQUEST_MEDIAS_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        medias: {
          ...state.medias,
          status: 'error',
          error: action.payload.toString(),
          isFetching: false
        }
      }
    case RECEIVE_MEDIAS_SEARCH_RESULTS:
      return {
        ...state,
        medias: {
          ...state.medias,
          status: 'ok',
          nbresults: action.payload['nbresults'],
          data: action.payload['medias'],
          isFetching: false
        }
      }
    case REQUEST_ATTRIBUTE_SETS:
      return Object.assign({}, state, {
        ...state
      })
    case RECEIVE_ATTRIBUTE_SETS:
      const newAttributeSets = { ...state.attributeSets }
      newAttributeSets[action.payload[0]] = action.payload[1]
      return Object.assign({}, state, {
        ...state,
        attributeSets: newAttributeSets
      })
    case REQUEST_DOCUMENT_TYPES:
      return Object.assign({}, state, {
        ...state
      })
    case RECEIVE_DOCUMENT_TYPES:
      return Object.assign({}, state, {
        ...state,
        documentTypes: action.payload
      })
    default:
      return state
  }
}
