import { loadTranslations, syncTranslationWithStore } from 'react-redux-i18n'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import translations from '../l10n/translations'
import reducer from './reducers'

const saveToLocalStorage = (state) => {
  const serializedState = JSON.stringify(state)
  localStorage.setItem('state', serializedState)
}

const loadFromLocalStorage = () => {
  const serializedState = localStorage.getItem('state')
  if (null === serializedState) return undefined
  return JSON.parse(serializedState)
}

const presistedState = loadFromLocalStorage()
const store = createStore(reducer, presistedState, composeWithDevTools(applyMiddleware(thunk)))

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations))
store.subscribe(() => saveToLocalStorage(store.getState()))

export default store
