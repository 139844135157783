import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCheckCircle,
  faTimes,
  faCaretDown,
  faCaretRight,
  faPaperPlane,
  faShoppingBasket,
  faDownload,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import '../styles/components/ProductsListView.css';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from '../redux/actions/userActions';
import { connect } from 'react-redux';
import { addToCart, removeFromCart } from '../redux/actions/cartActions';
import Downloader from 'js-file-downloader';
import arrowDown from '../images/icons/iconfinder_caret-down_383170.png';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const ProductsListView = (props) => {
  var selected = (
    <div className='plv-selected'>
      <FontAwesomeIcon
        className='listCheckIcon'
        icon={faCheckCircle}
      ></FontAwesomeIcon>
    </div>
  );

  var notSelected = <div></div>;
  const [selectedItems, setSelectedItems] = useState([]);

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const [productsInCart, setProductsInCart] = useState([]);

  var getUrl = window.location;
  var baseUrl = getUrl.protocol + '//' + getUrl.host + '/';

  var I18n = require('react-redux-i18n').I18n;

  const addNotify = () => toast(I18n.t('toast.addProductMediasToCart'));

  const noAssetsNotify = () => toast(I18n.t('toast.productNoMedias'));

  const removeNotify = () => toast(I18n.t('toast.removeProductMediasFromCart'));

  const addMediaNotify = () => toast(I18n.t('toast.addMediaToCart'));

  const removeMediaNotify = () => toast(I18n.t('toast.removeMediaFromCart'));

  useEffect(() => {
    setSelectedItems(props.selectedItems);
    if (
      props.whereFrom === 'RelatedMedia' &&
      props.selectedItems.length === 0
    ) {
      let newSelectedItems = {};
      Object.keys(props.tests).map((key, index) => {
        newSelectedItems[key] = [];
      });
      setSelectedItems(newSelectedItems);
    }
  }, [props.selectedItems]);

  useEffect(() => {
    if (props.whereFrom === 'RelatedMedia') {
      let options = [];
      Object.keys(props.tests).map((test, index) => {
        options.push({
          value: index,
          label: props.mapping[test],
        });
      });
      setOptions(options);
      setSelectedOption(props.selectedCategory);
    }
  }, [props.tests]);

  useEffect(() => {
    updateProductsInCart();
  }, [props.tests]);

  async function updateProductsInCart() {
    if (
      props.whereFrom === 'Produits' ||
      props.whereFrom === 'Favoris' ||
      props.whereFrom === 'Search'
    ) {
      let newProductsInCart = [];
      await Promise.all(
        props.tests.map(async (test, index) => {
          let assetsContained = 0;
          let totalAssets;
          await axios
            .get(
              process.env.REACT_APP_QUABLE_API_PROTOCOL +
                props.instancedata.id +
                process.env.REACT_APP_QUABLE_API_HOST +
                '/documents/' +
                test['id'],
              {}
            )
            .then((response) => {
              totalAssets = response.data.assetLinks.length;
              response.data.assetLinks.map(async (assetLink, index) => {
                props.cart.items
                  .map((item, index) => item['id'])
                  .includes(assetLink['target']['id'])
                  ? assetsContained++
                  : (assetsContained = assetsContained);
              });
              if (assetsContained === totalAssets && totalAssets > 0) {
                newProductsInCart.push(test['id']);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
      );
      setProductsInCart(newProductsInCart);
    }
  }

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginLeft: 20,
      marginRight: 20,
      maxWidth: 500,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgb(32, 32, 32)',
      border: 'none',
      boxShadow: 'none',
      // backgroundColor: 'transparent',
      // border: state.isFocused ? 0 : 0,
      // boxShadow: state.isFocused ? 0 : 0,
      // '&:hover': {
      //   border: state.isFocused ? 0 : 0,
      // },
      // color: 'white',
      // cursor: 'text',
      // whiteSpace: 'nowrap',
    }),
    input: (provided, state) => ({
      ...provided,
      // display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      // fontSize: 40,
      // backgroundColor: 'transparent',
      // color: 'white',
      // paddingLeft: 68,
      // fontFamily: 'OpenSans-Bold',
    }),
    singleValue: (provided, state) => ({ ...provided, color: 'white' }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      // display: 'none',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      // position: 'absolute',
      // textAlign: 'center',
      // right: 50,
      // color: !state.isFocused ? 'transparent' : 'grey',
      // '&:hover': {
      //   color: '#aaaaaa',
      // },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: 'white',
      // '&:hover': {
      //   opacity: state.isFocused ? 0.5 : 0.5,
      // },
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'center',
      // alignItems: 'center',
      // width: 50,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'white',
      '&:hover': {
        opacity: state.isFocused ? 0.9 : 0.9,
      },
      // color: 'transparent',
      // width: '100%',
      // height: '100%',
      // background: 'transparent url(' + arrowDown + ') no-repeat center center',
      // backgroundSize: 'contain',
      // cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      // backgroundColor: 'white',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      // paddingLeft: 68,
      // backgroundColor: isFocused ? 'rgba(0,0,0,0.16)' : null,
      // color: 'black',
      // cursor: 'pointer',
    }),
  };

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption.value);
    let newSelectedItems = {};
    Object.keys(props.tests).map((key, index) => {
      newSelectedItems[key] = [];
    });
    setSelectedItems(newSelectedItems);
    props.setSelectedItems(newSelectedItems);
    props.setSelectedCategory(selectedOption.value, 'list');
  }

  function handleSelectionClick(index) {
    if (!selectedItems.includes(index)) {
      setSelectedItems([...selectedItems, index]);
      props.setSelectedItems([...selectedItems, index]);
    } else {
      let si = selectedItems;
      si.splice(si.indexOf(index), 1);
      setSelectedItems([...si]);
      props.setSelectedItems([...si]);
    }
  }

  function handleSelectionClickFromRelatedMedia(linkType, index) {
    let newSelectedItems = selectedItems;
    if (!newSelectedItems[linkType].includes(index)) {
      newSelectedItems[linkType] = [...newSelectedItems[linkType], index];
      setSelectedItems({ ...newSelectedItems });
      props.setSelectedItems({ ...newSelectedItems });
    } else {
      let si = newSelectedItems[linkType];
      si.splice(si.indexOf(index), 1);
      newSelectedItems[linkType] = [...si];
      setSelectedItems({ ...newSelectedItems });
      props.setSelectedItems({ ...newSelectedItems });
    }
  }

  function handleStarClick(e, media) {
    e.preventDefault();
    e.stopPropagation();
    if (props.wishlist.map((item) => item['id']).includes(media['id'])) {
      props.dispatch(
        removeItemFromWishlist(
          props.wishlist.map((item) => item['id']).indexOf(media['id'])
        )
      );
    } else {
      props.dispatch(addItemToWishlist(media));
    }
  }

  function handleClick() {}

  function downloadMedia(e, url) {
    e.preventDefault();
    e.stopPropagation();
    new Downloader({
      url: url,
    })
      .then(function () {})
      .catch(function (error) {});
  }

  function handleMediaCartClick(e, media) {
    e.preventDefault();
    e.stopPropagation();
    if (props.cart.items.map((item) => item['id']).includes(media['id'])) {
      props.dispatch(removeFromCart(media['id']));
      if (props.whereFrom !== 'Cart') {
        removeMediaNotify();
      }
    } else {
      props.dispatch(addToCart(media));
      addMediaNotify();
    }
  }

  function handleProduitCartClick(e, id) {
    e.preventDefault();
    e.stopPropagation();
    if (!productsInCart.includes(id)) {
      let newProductsInCart = [...productsInCart];
      axios
        .get(
          process.env.REACT_APP_QUABLE_API_PROTOCOL +
            props.instancedata.id +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/documents/' +
            id,
          {}
        )
        .then((response) => {
          if (response.data.assetLinks.length > 0) {
            response.data.assetLinks.map(async (assetLink, index) => {
              axios
                .get(
                  process.env.REACT_APP_QUABLE_API_PROTOCOL +
                    props.instancedata.id +
                    process.env.REACT_APP_QUABLE_API_HOST +
                    '/assets/' +
                    assetLink['target']['id'],
                  {}
                )
                .then((response) => {
                  props.dispatch(addToCart(response.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            });
            addNotify();
            newProductsInCart.push(id);
            setProductsInCart(newProductsInCart);
          } else {
            noAssetsNotify();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let newProductsInCart = [...productsInCart];
      newProductsInCart.splice(newProductsInCart.indexOf(id), 1);
      setProductsInCart(newProductsInCart);
      axios
        .get(
          process.env.REACT_APP_QUABLE_API_PROTOCOL +
            props.instancedata.id +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/documents/' +
            id,
          {}
        )
        .then((response) => {
          response.data.assetLinks.map(async (assetLink, index) => {
            axios
              .get(
                process.env.REACT_APP_QUABLE_API_PROTOCOL +
                  props.instancedata.id +
                  process.env.REACT_APP_QUABLE_API_HOST +
                  '/assets/' +
                  assetLink['target']['id'],
                {}
              )
              .then((response) => {
                props.dispatch(removeFromCart(assetLink['target']['id']));
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      removeNotify();
    }
  }

  function handleSendClick(e) {
    // e.preventDefault();
    e.stopPropagation();
  }

  if (props.whereFrom === 'Search') {
    return (
      <div className='plv-root-container'>
        <ToastContainer hideProgressBar />
        {props.tests.map((test, index) => {
          return (
            <Fade key={index}>
              <div className='plv-search-container'>
                <Link
                  to={
                    props.whereTo === 'ProductPage'
                      ? {
                          pathname: '/product',
                          search: '?' + props.tests[index]['id'],
                        }
                      : {
                          pathname: '/mediapage',
                          query: 'Search',
                          mediaIndex: index,
                        }
                  }
                  style={
                    index % 2 === 0
                      ? { backgroundColor: 'rgb(242, 246, 248)' }
                      : { backgroundColor: 'white' }
                  }
                  className='plv-search-subcontainer'
                >
                  <div className='plv-subcontainer'>
                    <div
                      style={
                        props.whereTo === 'ProductPage' &&
                        test['mainAssetThumbnailUrl'] !== null &&
                        test['mainAssetThumbnailUrl'] !== undefined
                          ? {
                              backgroundImage:
                                'url(' + test['mainAssetThumbnailUrl'] + ')',
                            }
                          : props.whereTo === 'MediaPage'
                          ? {
                              backgroundImage:
                                'url(' + test['thumbnailUrl'] + ')',
                            }
                          : {
                              backgroundImage:
                                'url(' + window.$visuel_defaut + ')',
                            }
                      }
                      className='plv-search-button'
                    ></div>
                    <div className='plv-subcontainer-container'>
                      <div className='plv-subcontainer-content'>
                        <p className='plv-subcontainer-content-title'>
                          {test.documentType !== undefined
                            ? test.attributes[test.documentType.id + '_name'][
                                props.locale
                              ]
                            : test['name']}
                        </p>
                      </div>
                      <div className='plv-subcontainer-right'>
                        <p className='plv-subcontainer-content-id'>
                          {test['id']}
                        </p>
                        <div className='plv-right-search-container'>
                          <div className='plv-icons-container plv-raccourcis-container'>
                            <div
                              style={{ marginLeft: 10 }}
                              className='plv-star-container'
                            >
                              <FontAwesomeIcon
                                className={
                                  (
                                    props.whereTo === 'ProductPage'
                                      ? productsInCart.includes(test['id'])
                                      : props.cart.items
                                          .map((item) => item['id'])
                                          .includes(test['id'])
                                  )
                                    ? 'plv-star-active'
                                    : 'plv-star'
                                }
                                icon={faShoppingBasket}
                                onClick={(e) =>
                                  props.whereTo === 'ProductPage'
                                    ? handleProduitCartClick(e, test['id'])
                                    : handleMediaCartClick(e, test)
                                }
                              />
                            </div>
                            <Tooltip
                              title={<Translate value='tooltips.favourite' />}
                              arrow
                              placement='top'
                            >
                              <div className='plv-star-container'>
                                <FontAwesomeIcon
                                  className={
                                    props.wishlist
                                      .map((item) => item['id'])
                                      .includes(test['id'])
                                      ? 'plv-star-active'
                                      : 'plv-star'
                                  }
                                  icon={faStar}
                                  onClick={(e) => handleStarClick(e, test)}
                                />
                              </div>
                            </Tooltip>
                            <a
                              style={
                                props.whereTo === 'ProductPage'
                                  ? {}
                                  : { display: 'none' }
                              }
                              className='plv-star-container'
                              href={
                                'mailto:?subject=' +
                                props.admin.quableUser.givenName +
                                ' ' +
                                props.admin.quableUser.familyName +
                                ' vous envoie le document ' +
                                (test.documentType !== undefined
                                  ? test.attributes[
                                      test.documentType.id + '_name'
                                    ][props.locale]
                                  : test['name']) +
                                '&body=Bonjour,\n\n' +
                                props.admin.quableUser.givenName +
                                ' ' +
                                props.admin.quableUser.familyName +
                                ' vous transmet ce lien à consulter :  ' +
                                baseUrl +
                                'product?' +
                                test['id'] +
                                '\n\nMerci' +
                                '\n\nA très bientôt sur le portail.'
                              }
                            >
                              <FontAwesomeIcon
                                className='plv-star'
                                icon={faPaperPlane}
                                onClick={(e) => e.stopPropagation()}
                              ></FontAwesomeIcon>
                            </a>
                            <a
                              style={
                                props.whereTo === 'ProductPage'
                                  ? { display: 'none' }
                                  : {}
                              }
                              className='plv-star-container'
                              href={
                                'mailto:?subject=' +
                                props.admin.quableUser.givenName +
                                ' ' +
                                props.admin.quableUser.familyName +
                                ' vous envoie le document ' +
                                test['name'] +
                                '&body=Bonjour,\n\n' +
                                props.admin.quableUser.givenName +
                                ' ' +
                                props.admin.quableUser.familyName +
                                ' vous transmet ce lien à consulter :  ' +
                                test['url'] +
                                '\n\nMerci' +
                                '\n\nA très bientôt sur le portail.'
                              }
                            >
                              <FontAwesomeIcon
                                className='plv-star'
                                icon={faPaperPlane}
                                onClick={(e) => e.stopPropagation()}
                              ></FontAwesomeIcon>
                            </a>
                            <div className='plv-star-container'>
                              <FontAwesomeIcon
                                style={
                                  props.whereTo === 'ProductPage'
                                    ? { display: 'none' }
                                    : {}
                                }
                                className='plv-star'
                                icon={faDownload}
                                onClick={(e) =>
                                  downloadMedia(
                                    e,
                                    test['mainAssetThumbnailUrl']
                                  )
                                }
                              />
                            </div>
                          </div>
                          <p className='plv-subcontainer-content-type'>
                            {props.whereTo === 'ProductPage'
                              ? test['documentType'].id
                              : 'Média'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Fade>
          );
        })}
      </div>
    );
  } else if (
    props.whereFrom === 'Favoris' ||
    props.whereFrom === 'Produits' ||
    props.whereFrom === 'ProductsMAJ'
  ) {
    return (
      <div className='plv-root-container'>
        <ToastContainer hideProgressBar />
        {props.tests.map((test, index) => {
          let extension;
          if (test['thumbnailUrl'] !== undefined) {
            extension = test['thumbnailUrl']
              .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
              .substring(
                test['thumbnailUrl'].lastIndexOf('-') + 1,
                test['thumbnailUrl'].length
              );
          }
          return (
            <Fade bottom key={index}>
              <div
                style={
                  index % 2 === 0
                    ? { backgroundColor: 'rgb(242, 246, 248)' }
                    : { backgroundColor: 'white' }
                }
                className='plv-container'
              >
                <div className='plv-subcontainer'>
                  <Link
                    style={
                      props.whereFrom === 'Favoris'
                        ? test.documentType === undefined
                          ? {
                              backgroundImage:
                                'url(' + test['thumbnailUrl'] + ')',
                            }
                          : test['mainAssetThumbnailUrl'] !== null &&
                            test['mainAssetThumbnailUrl'] !== undefined
                          ? {
                              backgroundImage:
                                'url(' + test['mainAssetThumbnailUrl'] + ')',
                            }
                          : {
                              backgroundImage:
                                'url(' + window.$visuel_defaut + ')',
                            }
                        : test['mainAssetThumbnailUrl'] !== null &&
                          test['mainAssetThumbnailUrl'] !== undefined
                        ? {
                            backgroundImage:
                              'url(' + test['mainAssetThumbnailUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='plv-button'
                    to={
                      props.whereFrom === 'Favoris' &&
                      test.documentType === undefined
                        ? {
                            pathname: '/mediapage',
                            query: 'Favoris',
                            mediaIndex: 0,
                            media: test,
                          }
                        : {
                            pathname: '/product',
                            search: '?' + test['id'],
                          }
                    }
                  ></Link>
                  <div className='plv-subcontainer-content'>
                    <p className='plv-subcontainer-content-title'>
                      {test.documentType !== undefined &&
                      test.attributes[test.documentType.id + '_name'] !==
                        undefined
                        ? test.attributes[test.documentType.id + '_name'][
                            props.locale
                          ]
                        : test['name']}
                    </p>
                    <p className='plv-subcontainer-content-subtitle'>
                      {test['id']}
                    </p>
                  </div>
                </div>
                <div className='plv-raccourcis-container'>
                  <div className='plv-star-container'>
                    <FontAwesomeIcon
                      className={
                        (test.documentType !== undefined &&
                          productsInCart.includes(test['id'])) ||
                        (test.documentType === undefined &&
                          props.cart.items
                            .map((item) => item['id'])
                            .includes(test['id']))
                          ? 'plv-star-active'
                          : 'plv-star'
                      }
                      icon={faShoppingBasket}
                      onClick={(e) =>
                        test.documentType !== undefined
                          ? handleProduitCartClick(e, test['id'])
                          : handleMediaCartClick(e, test)
                      }
                    />
                  </div>
                  <Tooltip
                    title={<Translate value='tooltips.favourite' />}
                    arrow
                    placement='top'
                  >
                    <div className='plv-star-container'>
                      <FontAwesomeIcon
                        className={
                          props.wishlist
                            .map((item) => item['id'])
                            .includes(test['id'])
                            ? 'plv-star-active'
                            : 'plv-star'
                        }
                        icon={faStar}
                        onClick={(e) => handleStarClick(e, test)}
                      ></FontAwesomeIcon>
                    </div>
                  </Tooltip>
                  <a
                    className='plv-star-container'
                    href={
                      'mailto:?subject=' +
                      props.admin.quableUser.givenName +
                      ' ' +
                      props.admin.quableUser.familyName +
                      ' vous envoie le document ' +
                      (test.documentType !== undefined &&
                      test.attributes[test.documentType.id + '_name'] !==
                        undefined
                        ? test.attributes[test.documentType.id + '_name'][
                            props.locale
                          ]
                        : test['name']) +
                      '&body=Bonjour,\n\n' +
                      props.admin.quableUser.givenName +
                      ' ' +
                      props.admin.quableUser.familyName +
                      ' vous transmet ce lien à consulter :  ' +
                      baseUrl +
                      'product?' +
                      test['id'] +
                      '\n\nMerci' +
                      '\n\nA très bientôt sur le portail.'
                    }
                  >
                    <FontAwesomeIcon
                      className='plv-star'
                      icon={faPaperPlane}
                      onClick={(e) => handleSendClick(e)}
                    ></FontAwesomeIcon>
                  </a>
                  <div
                    className='plv-star-container'
                    style={
                      props.whereFrom === 'Favoris' &&
                      test.documentType === undefined
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <FontAwesomeIcon
                      className='plv-star'
                      icon={faDownload}
                      onClick={(e) => downloadMedia(e, test['url'])}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    );
  } else if (props.whereFrom === 'Medias' || props.whereFrom === 'MediasMAJ') {
    return (
      <div className='plv-root-container'>
        {props.tests.map((test, index) => {
          let extension = test['thumbnailUrl']
            .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
            .substring(
              test['thumbnailUrl'].lastIndexOf('-') + 1,
              test['thumbnailUrl'].length
            );
          return (
            <Fade bottom key={index}>
              <div
                style={
                  index % 2 === 0
                    ? { backgroundColor: props.backgroundColor1 }
                    : { backgroundColor: props.backgroundColor2 }
                }
                className='plv-container'
              >
                <Link
                  onClick={(e) =>
                    props.selectionMode ? e.preventDefault() : null
                  }
                  className='plv-subcontainer-container'
                  to={{
                    pathname: '/mediapage',
                    query:
                      props.whereFrom === 'Medias' ? 'Medias' : 'MediasMAJ',
                    mediaIndex: index,
                  }}
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  <div
                    className='plv-subcontainer'
                    onClick={
                      props.selectionMode
                        ? () => handleSelectionClick(index)
                        : handleClick()
                    }
                  >
                    <div
                      style={
                        test['thumbnailUrl'] !== undefined &&
                        test['thumbnailUrl'] !== ''
                          ? {
                              backgroundImage:
                                'url(' + test['thumbnailUrl'] + ')',
                            }
                          : {
                              backgroundImage:
                                'url(' + window.$visuel_defaut + ')',
                            }
                      }
                      className='plv-button'
                    >
                      {props.selectionMode && selectedItems.includes(index)
                        ? selected
                        : notSelected}
                    </div>
                    <div className='plv-subcontainer-content'>
                      <p
                        style={
                          index % 2 === 0
                            ? { color: 'black' }
                            : props.whereFrom === 'RelatedMedia'
                            ? { color: 'white' }
                            : { color: 'black' }
                        }
                        className='plv-subcontainer-content-title'
                      >
                        {test['name']}
                      </p>
                      <div className='plv-info-container'>
                        <FontAwesomeIcon
                          style={{ marginRight: 5 }}
                          icon={faFile}
                        />
                        <p style={{ marginRight: 10 }}>
                          {test['dateCreated'].split('T')[0]}
                        </p>
                        <FontAwesomeIcon
                          style={{ marginRight: 5 }}
                          icon={faSync}
                        />
                        <p style={{ marginRight: 10 }}>
                          {test['dateModified'].split('T')[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                <div
                  className='plv-icons-container plv-raccourcis-container'
                  style={
                    props.selectionMode && selectedItems.includes(index)
                      ? { display: 'none' }
                      : {}
                  }
                >
                  <div className='plv-star-container'>
                    <FontAwesomeIcon
                      className={
                        props.cart.items
                          .map((item) => item['id'])
                          .includes(test['id'])
                          ? 'plv-star-active'
                          : 'plv-star'
                      }
                      icon={faShoppingBasket}
                      onClick={(e) => handleMediaCartClick(e, test)}
                    />
                  </div>
                  <Tooltip
                    title={<Translate value='tooltips.favourite' />}
                    arrow
                    placement='top'
                  >
                    <div className='plv-star-container'>
                      <FontAwesomeIcon
                        className={
                          props.wishlist
                            .map((item) => item['id'])
                            .includes(test['id'])
                            ? 'plv-star-active'
                            : 'plv-star'
                        }
                        icon={faStar}
                        onClick={(e) => handleStarClick(e, test)}
                      />
                    </div>
                  </Tooltip>
                  <a
                    className='plv-star-container'
                    href={
                      'mailto:?subject=' +
                      props.admin.quableUser.givenName +
                      ' ' +
                      props.admin.quableUser.familyName +
                      ' vous envoie le document ' +
                      test['name'] +
                      '&body=Bonjour,\n\n' +
                      props.admin.quableUser.givenName +
                      ' ' +
                      props.admin.quableUser.familyName +
                      ' vous transmet ce lien à consulter :  ' +
                      test['url'] +
                      '\n\nMerci' +
                      '\n\nA très bientôt sur le portail.'
                    }
                  >
                    <FontAwesomeIcon
                      className='plv-star'
                      icon={faPaperPlane}
                      onClick={(e) => handleSendClick(e)}
                    ></FontAwesomeIcon>
                  </a>
                  <div className='plv-star-container'>
                    <FontAwesomeIcon
                      className='plv-star'
                      icon={faDownload}
                      onClick={(e) => downloadMedia(e, test['url'])}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    );
  } else if (
    props.whereFrom === 'RelatedMedia' &&
    selectedOption !== undefined &&
    options.length > 0
  ) {
    let mergedMedias = Object.values(props.tests);
    mergedMedias = [].concat
      .apply([], mergedMedias)
      .map((media) => media['id']);
    return (
      <div className='plv-root-container-rm'>
        <div className='plv-select-container'>
          <Select
            styles={customStyles}
            value={options[selectedOption]}
            onChange={handleChange}
            options={options}
            // onCreateOption={handleCreate}
            // isClearable
          />
        </div>
        <div className='plv-linktype-container'>
          <div className='plv-button-container'>
            {Object.entries(props.tests)[selectedOption][1].map(
              (media, index) => {
                let extension = media['thumbnailUrl']
                  .substring(0, media['thumbnailUrl'].lastIndexOf('.'))
                  .substring(
                    media['thumbnailUrl'].lastIndexOf('-') + 1,
                    media['thumbnailUrl'].length
                  );
                return (
                  <Fade bottom key={index}>
                    <div
                      style={
                        index % 2 === 0
                          ? { backgroundColor: props.backgroundColor1 }
                          : { backgroundColor: props.backgroundColor2 }
                      }
                      className='plv-container'
                    >
                      <Link
                        className='plv-subcontainer-container'
                        onClick={(e) =>
                          props.selectionMode ? e.preventDefault() : null
                        }
                        to={{
                          pathname: '/mediapage',
                          query: 'RelatedMedia',
                          mediaIndex: mergedMedias.indexOf(media['id']),
                        }}
                        style={{ textDecoration: 'none', color: 'black' }}
                      >
                        <div
                          className='plv-subcontainer'
                          onClick={
                            props.selectionMode
                              ? () =>
                                  handleSelectionClickFromRelatedMedia(
                                    Object.entries(props.tests)[
                                      selectedOption
                                    ][0],
                                    index
                                  )
                              : handleClick()
                          }
                        >
                          <div
                            style={
                              media['thumbnailUrl'] !== undefined &&
                              media['thumbnailUrl'] !== ''
                                ? {
                                    backgroundImage:
                                      'url(' + media['thumbnailUrl'] + ')',
                                  }
                                : {
                                    backgroundImage:
                                      'url(' + window.$visuel_defaut + ')',
                                  }
                            }
                            className='plv-button'
                          >
                            {props.selectionMode &&
                            Object.keys(selectedItems).length !== 0 &&
                            selectedItems[
                              Object.entries(props.tests)[selectedOption][0]
                            ].includes(index)
                              ? selected
                              : notSelected}
                          </div>
                          <div className='plv-subcontainer-content'>
                            <p
                              style={
                                index % 2 === 0
                                  ? { color: 'black' }
                                  : props.whereFrom === 'RelatedMedia'
                                  ? { color: 'white' }
                                  : { color: 'black' }
                              }
                              className='plv-subcontainer-content-title'
                            >
                              {media['name']}
                            </p>
                            <div
                              className='plv-info-container'
                              style={
                                index % 2 === 0
                                  ? { color: 'black' }
                                  : props.whereFrom === 'RelatedMedia'
                                  ? { color: 'white' }
                                  : { color: 'black' }
                              }
                            >
                              <FontAwesomeIcon
                                style={{ marginRight: 5 }}
                                icon={faFile}
                              />
                              <p style={{ marginRight: 10 }}>
                                {media['dateCreated'].split('T')[0]}
                              </p>
                              <FontAwesomeIcon
                                style={{ marginRight: 5 }}
                                icon={faSync}
                              />
                              <p style={{ marginRight: 10 }}>
                                {media['dateModified'].split('T')[0]}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div
                        className='plv-icons-container plv-raccourcis-container'
                        style={
                          props.selectionMode &&
                          selectedItems[
                            Object.entries(props.tests)[selectedOption][0]
                          ].includes(index)
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        <div className='plv-star-container'>
                          <FontAwesomeIcon
                            className={
                              props.cart.items
                                .map((item) => item['id'])
                                .includes(media['id'])
                                ? 'plv-star-active'
                                : 'plv-star'
                            }
                            icon={faShoppingBasket}
                            onClick={(e) => handleMediaCartClick(e, media)}
                          />
                        </div>
                        <Tooltip
                          title={<Translate value='tooltips.favourite' />}
                          arrow
                          placement='top'
                        >
                          <div className='plv-star-container'>
                            <FontAwesomeIcon
                              className={
                                props.wishlist
                                  .map((item) => item['id'])
                                  .includes(media['id'])
                                  ? 'plv-star-active'
                                  : 'plv-star'
                              }
                              icon={faStar}
                              onClick={(e) => handleStarClick(e, media)}
                            />
                          </div>
                        </Tooltip>
                        <a
                          className='plv-star-container'
                          href={
                            'mailto:?subject=' +
                            props.admin.quableUser.givenName +
                            ' ' +
                            props.admin.quableUser.familyName +
                            ' vous envoie le document ' +
                            media['name'] +
                            '&body=Bonjour,\n\n' +
                            props.admin.quableUser.givenName +
                            ' ' +
                            props.admin.quableUser.familyName +
                            ' vous transmet ce lien à consulter :  ' +
                            media['url'] +
                            '\n\nMerci' +
                            '\n\nA très bientôt sur le portail.'
                          }
                        >
                          <FontAwesomeIcon
                            className='plv-star'
                            icon={faPaperPlane}
                            onClick={(e) => handleSendClick(e)}
                          ></FontAwesomeIcon>
                        </a>
                        <div className='plv-star-container'>
                          <FontAwesomeIcon
                            className='plv-star'
                            icon={faDownload}
                            onClick={(e) => downloadMedia(e, media['url'])}
                          />
                        </div>
                      </div>
                    </div>
                  </Fade>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  } else if (props.whereFrom === 'Cart') {
    return (
      <div className='plv-root-container'>
        {props.tests.map((test, index) => {
          let extension = test['thumbnailUrl']
            .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
            .substring(
              test['thumbnailUrl'].lastIndexOf('-') + 1,
              test['thumbnailUrl'].length
            );
          return (
            <Fade bottom key={index}>
              <div className='plv-el-root-container'>
                <div
                  style={
                    index % 2 === 0
                      ? { backgroundColor: 'rgb(242, 246, 248)' }
                      : { backgroundColor: 'white' }
                  }
                  className='plv-container'
                >
                  <div className='plv-subcontainer'>
                    <Link
                      style={
                        test['thumbnailUrl'] !== undefined &&
                        test['thumbnailUrl'] !== ''
                          ? {
                              backgroundImage:
                                'url(' + test['thumbnailUrl'] + ')',
                            }
                          : {
                              backgroundImage:
                                'url(' + window.$visuel_defaut + ')',
                            }
                      }
                      className='plv-button'
                      to={{
                        pathname: '/mediapage',
                        query: 'Cart',
                        mediaIndex: index,
                      }}
                    ></Link>
                    <div className='plv-subcontainer-content'>
                      <p className='plv-subcontainer-content-title'>
                        {test['name']}
                      </p>
                      <p className='plv-subcontainer-content-subtitle'>
                        {test['id']}
                      </p>
                    </div>
                  </div>
                  <div className='plv-icons-container plv-raccourcis-container'>
                    <div className='plv-star-container'>
                      <FontAwesomeIcon
                        className={
                          props.cart.items
                            .map((item) => item['id'])
                            .includes(test['id'])
                            ? 'plv-star-active'
                            : 'plv-star'
                        }
                        icon={faShoppingBasket}
                        onClick={(e) => handleMediaCartClick(e, test)}
                      />
                    </div>
                    <Tooltip
                      title={<Translate value='tooltips.favourite' />}
                      arrow
                      placement='top'
                    >
                      <div className='plv-star-container'>
                        <FontAwesomeIcon
                          className={
                            props.wishlist
                              .map((item) => item['id'])
                              .includes(test['id'])
                              ? 'plv-star-active'
                              : 'plv-star'
                          }
                          icon={faStar}
                          onClick={(e) => handleStarClick(e, test)}
                        />
                      </div>
                    </Tooltip>
                    <a
                      className='plv-star-container'
                      href={
                        'mailto:?subject=' +
                        props.admin.quableUser.givenName +
                        ' ' +
                        props.admin.quableUser.familyName +
                        ' vous envoie le document ' +
                        test['name'] +
                        '&body=Bonjour,\n\n' +
                        props.admin.quableUser.givenName +
                        ' ' +
                        props.admin.quableUser.familyName +
                        ' vous transmet ce lien à consulter :  ' +
                        test['downloadUrl'] +
                        '\n\nMerci' +
                        '\n\nA très bientôt sur le portail.'
                      }
                    >
                      <FontAwesomeIcon
                        className='plv-star'
                        icon={faPaperPlane}
                        onClick={(e) => handleSendClick(e)}
                      ></FontAwesomeIcon>
                    </a>
                    <div className='plv-star-container'>
                      <FontAwesomeIcon
                        className='plv-star'
                        icon={faDownload}
                        onClick={(e) => downloadMedia(e, test['url'])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    );
  } else if (props.whereFrom === 'RelatedMedia') {
    return <div className='plv-root-container-rm'></div>;
  } else if (props.whereFrom === 'DocumentLinks') {
    return (
      <div className='plv-root-container'>
        {props.tests.map((test, index) => {
          let extension;
          if (test['thumbnailUrl'] !== undefined) {
            extension = test['thumbnailUrl']
              .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
              .substring(
                test['thumbnailUrl'].lastIndexOf('-') + 1,
                test['thumbnailUrl'].length
              );
          }
          return (
            <Fade bottom key={index}>
              <div
                style={
                  index % 2 === 0
                    ? { backgroundColor: 'rgb(242, 246, 248)' }
                    : { backgroundColor: 'white' }
                }
                className='plv-container'
              >
                <div className='plv-subcontainer'>
                  <Link
                    style={
                      test['mainAssetThumbnailUrl'] !== null &&
                      test['mainAssetThumbnailUrl'] !== undefined
                        ? {
                            backgroundImage:
                              'url(' + test['mainAssetThumbnailUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='plv-button'
                    to={{
                      pathname: '/product',
                      search: '?' + test['id'],
                    }}
                  ></Link>
                  {/* <div
                    style={{
                      backgroundImage: 'url(' + window.$visuel_defaut + ')',
                    }}
                    className='plv-button'
                  ></div> */}
                  <div className='plv-subcontainer-content'>
                    <p className='plv-subcontainer-content-title'>
                      {
                        test.attributes[test.documentType.id + '_name'][
                          props.locale
                        ]
                      }
                    </p>
                    {/* <p className='plv-subcontainer-content-subtitle'>
                      {'id: ' +
                        test['id'] +
                        ' ean: ' +
                        test.attributes[test.documentType.id + '_EAN'][
                          props.locale
                        ]}
                    </p> */}
                    <div className='plv-subcontainer-content-subtitle-container'>
                      {props.attributes.map((attribute) => {
                        return Object.entries(test.attributes).map((tattr) => {
                          if (
                            attribute.id === tattr[0] &&
                            attribute.isPrimary
                          ) {
                            return (
                              <p className='plv-subcontainer-content-subtitle'>
                                {attribute.name[
                                  props.locale.replace('_', '-')
                                ] +
                                  ': ' +
                                  (test.attributes[attribute.id][
                                    props.locale
                                  ] !== undefined
                                    ? test.attributes[attribute.id][
                                        props.locale
                                      ]
                                    : test.attributes[attribute.id]) +
                                  '\xa0\xa0'}
                              </p>
                            );
                          }
                        });
                      })}
                    </div>
                  </div>
                </div>
                <div className='plv-icons-container plv-raccourcis-container'>
                  <a
                    className='plv-star-container'
                    href={
                      'mailto:?subject=' +
                      props.admin.quableUser.givenName +
                      ' ' +
                      props.admin.quableUser.familyName +
                      ' vous envoie le document ' +
                      test['name'] +
                      '&body=Bonjour,\n\n' +
                      props.admin.quableUser.givenName +
                      ' ' +
                      props.admin.quableUser.familyName +
                      ' vous transmet ce lien à consulter :  ' +
                      test['url'] +
                      '\n\nMerci' +
                      '\n\nA très bientôt sur le portail.'
                    }
                  >
                    <FontAwesomeIcon
                      className='plv-star'
                      icon={faPaperPlane}
                      onClick={(e) => handleSendClick(e)}
                    ></FontAwesomeIcon>
                  </a>
                  <Tooltip
                    title={<Translate value='tooltips.favourite' />}
                    arrow
                    placement='top'
                  >
                    <div className='plv-star-container'>
                      <FontAwesomeIcon
                        className={
                          props.wishlist
                            .map((item) => item['id'])
                            .includes(test['id'])
                            ? 'plv-star-active'
                            : 'plv-star'
                        }
                        icon={faStar}
                        onClick={(e) => handleStarClick(e, test)}
                      />
                    </div>
                  </Tooltip>
                  <div className='plv-star-container'>
                    <FontAwesomeIcon
                      className={
                        props.cart.items
                          .map((item) => item['id'])
                          .includes(test['id'])
                          ? 'plv-star-active'
                          : 'plv-star'
                      }
                      icon={faShoppingBasket}
                      onClick={(e) => handleMediaCartClick(e, test)}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          );
        })}
      </div>
    );
  }
};

ProductsListView.propTypes = {
  tests: PropTypes.array,
  star: PropTypes.bool,
  selectionItems: PropTypes.array,
  selectionMode: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    wishlist: state.user.wishlist,
    mapping: state.product.assetsLinkTypesMapping,
    cart: state.cart,
    admin: state.adminReducer,
    locale: state.i18n.locale,
    attributes: state.adminReducer.attributes,
    instancedata: state.adminReducer.instancedata,
  };
}

export default connect(mapStateToProps)(ProductsListView);
