import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Pagination from 'react-js-pagination'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { I18n } from 'react-redux-i18n'
import { Header, ProductsListView, SearchFilters } from '../../components'
import '../../styles/pages/Search.css'
import Footer from '../../components/Footer'
import { fetchSearchResults } from '../../redux/actions/searchActions'

const Search = ({
  dispatch,
  classificationIds,
  mediasClassficationIds,
  searchInput,
  nbProductsResults,
  locale,
  instancedata,
  nbMediasResults,
  isFetchingProducts,
  products,
  medias,
  isFetchingMedias
}) => {
  const [activePageNumber, setActivePageNumber] = useState(1)
  const [viewProducts, setViewProducts] = useState(false)
  const [viewMedias, setViewMedias] = useState(false)

  const [search, setSearch] = useState()

  useEffect(() => {
    if (null !== document.getElementById('search-input-id')) {
      document.getElementById('search-input-id').value = searchInput
    }
  }, [nbProductsResults, nbMediasResults, searchInput])

  useEffect(() => {
    dispatch(
      fetchSearchResults(
        searchInput,
        undefined,
        undefined,
        undefined,
        classificationIds.map((item) => item['value']),
        mediasClassficationIds.map((item) => item['value']),
        activePageNumber,
        locale,
        instancedata.id
      )
    )
    setViewProducts(false)
    setViewMedias(false)
  }, [locale, searchInput])

  const handleChange = (pageNumber) => {
    if (activePageNumber !== pageNumber) {
      setActivePageNumber(pageNumber)
    }
  }

  const handleViewProductsClick = () => {
    setViewProducts(true)
    window.scrollTo(0, 0)
  }

  const handleViewMediasClick = () => {
    setViewMedias(true)
    window.scrollTo(0, 0)
  }

  const handleReturnClick = () => {
    dispatch(
      fetchSearchResults(
        searchInput,
        undefined,
        undefined,
        undefined,
        classificationIds.map((item) => item['value']),
        mediasClassficationIds.map((item) => item['value']),
        1,
        locale,
        instancedata.id
      )
    )
    setActivePageNumber(1)
    setViewProducts(false)
    setViewMedias(false)
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    dispatch(
      fetchSearchResults(
        search,
        undefined,
        undefined,
        undefined,
        classificationIds.map((item) => item['value']),
        mediasClassficationIds.map((item) => item['value']),
        1,
        locale,
        instancedata.id
      )
    )
  }

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value)
  }

  const setPageNumberFromChild = (pageNumber) => {
    setActivePageNumber(pageNumber)
  }

  if (null !== searchInput) {
    if ((isFetchingProducts || isFetchingMedias) && false === viewProducts && false === viewMedias) {
      return (
        <div>
          <Header></Header>
          <div className="search-loader">
            <Loader color="black" height={100} type="TailSpin" width={100} />
          </div>
        </div>
      )
    }
    if (viewProducts || viewMedias) {
      return (
        <div>
          <Header></Header>
          <div className="search-input-container">
            <div className="search-input-subcontainer">
              <form onSubmit={() => handleSearchSubmit()}>
                <input
                  className="search-input"
                  id="search-input-id"
                  placeholder={I18n.t('search.placeholder')}
                  type="text"
                  onChange={() => handleSearchInputChange()}
                ></input>
              </form>
            </div>
          </div>
          <div className="search-container">
            <div className="search-subcontainer">
              <div className="search-heading-container">
                <div className="search-retour" role="button" onClick={handleReturnClick}>
                  <FontAwesomeIcon className="icon" icon={faArrowLeft} />
                  <Translate className="search-retour-txt" value="subheader.goback" />
                </div>
                <h1 className="search-heading">
                  <Translate value="search.header.part1" />
                </h1>
                <div className="search-divider"></div>
                <h1 className="search-heading-b">
                  {nbProductsResults} <Translate value="search.header.part2" />,
                </h1>
                <div className="search-divider"></div>
                <h1 className="search-heading-b">
                  {nbMediasResults} <Translate value="search.header.part3" />
                </h1>
                <div className="search-divider"></div>
                <h1 className="search-heading">
                  <Translate value="search.header.part4" />
                  {`"${searchInput}"`}
                </h1>
              </div>
              <SearchFilters
                pageNumber={activePageNumber}
                setPageNumberFromChild={setPageNumberFromChild}
                type={viewProducts ? 'Products' : 'Medias'}
              />
              {viewProducts ? (
                <div style={{ height: 450, display: 'block' }}>
                  <p className="search-type-heading">
                    <Translate value="search.header.part2" /> ({nbProductsResults})
                  </p>
                  <ProductsListView tests={products} whereFrom="Search" whereTo="ProductPage" />
                </div>
              ) : (
                <div></div>
              )}
              {viewMedias ? (
                <div style={{ height: 450, display: 'block' }}>
                  <p className="search-type-heading">
                    <Translate value="search.header.part3" /> ({nbMediasResults})
                  </p>
                  <ProductsListView tests={medias} whereFrom="Search" whereTo="MediaPage" />
                </div>
              ) : (
                <div></div>
              )}
              <Pagination
                activeClass="search-pagination-activeClass"
                activeLinkClass="search-pagination-activeLinkClass"
                activePage={activePageNumber}
                disabledClass="search-pagination-disabledClass"
                innerClass="search-pagination-innerClass"
                itemClass="search-pagination-itemClass"
                itemClassFirst="search-pagination-itemClassFirst"
                itemClassLast="search-pagination-itemClassLast"
                itemClassNext="search-pagination-itemClassNext"
                itemClassPrev="search-pagination-itemClassPrev"
                itemsCountPerPage={6}
                linkClass="search-pagination-linkClass"
                linkClassFirst="search-pagination-linkClassFirst"
                linkClassLast="search-pagination-linkClassLast"
                linkClassNext="search-pagination-linkClassNext"
                linkClassPrev="search-pagination-linkClassPrev"
                nextPageText={I18n.t('pagination.next')}
                pageRangeDisplayed={5}
                prevPageText={I18n.t('pagination.previous')}
                totalItemsCount={viewProducts ? nbProductsResults : nbMediasResults}
                onChange={handleChange}
              />
            </div>
          </div>
          <Footer></Footer>
        </div>
      )
    } else if (null !== nbProductsResults && null !== nbMediasResults) {
      return (
        <div>
          <Header></Header>
          <div className="search-input-container">
            <div className="search-input-subcontainer">
              <form onSubmit={handleSearchSubmit}>
                <input
                  className="search-input"
                  id="search-input-id"
                  placeholder={I18n.t('search.placeholder')}
                  type="text"
                  onChange={handleSearchInputChange}
                ></input>
              </form>
            </div>
          </div>
          <div className="search-container">
            <div className="search-subcontainer">
              <div className="search-heading-container">
                <h1 className="search-heading">
                  <Translate value="search.header.part1" />
                </h1>
                <div className="search-divider"></div>
                <h1 className="search-heading-b">
                  {nbProductsResults} <Translate value="search.header.part2" />,
                </h1>
                <div className="search-divider"></div>
                <h1 className="search-heading-b">
                  {nbMediasResults} <Translate value="search.header.part3" />
                </h1>
                <div className="search-divider"></div>
                <h1 className="search-heading">
                  <Translate value="search.header.part4" /> {`"${searchInput}"`}
                </h1>
              </div>
              <div style={0 < nbProductsResults ? { display: 'block' } : { display: 'none' }}>
                <p className="search-type-heading">
                  <Translate value="search.header.part2" /> ({nbProductsResults})
                </p>
                <ProductsListView tests={products} whereFrom="Search" whereTo="ProductPage" />
                <div className="search-btn-container">
                  <button className="search-btn" onClick={() => handleViewProductsClick()}>
                    <Translate value="search.allResults" />
                  </button>
                </div>
              </div>
              <div style={0 < nbMediasResults ? { display: 'block' } : { display: 'none' }}>
                <p className="search-type-heading">
                  <Translate value="search.header.part3" /> ({nbMediasResults})
                </p>
                <ProductsListView tests={medias} whereFrom="Search" whereTo="MediaPage" />
                <div className="search-btn-container">
                  <button className="search-btn" onClick={() => handleViewMediasClick()}>
                    <Translate value="search.allResults" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      )
    }
    return <div></div>
  }
  return (
    <div>
      <Header />
      <div className="search-input-container">
        <div className="search-input-subcontainer">
          <form onSubmit={handleSearchSubmit}>
            <input
              className="search-input"
              id="search-input-id"
              placeholder={I18n.t('search.placeholder')}
              type="text"
              onChange={handleSearchInputChange}
            ></input>
          </form>
        </div>
      </div>
      <p style={{ textAlign: 'center', marginTop: 50 }}>
        <Translate value="search.noQuery" />
      </p>
    </div>
  )
}

Search.propTypes = {
  classificationIds: PropTypes.array,
  dispatch: PropTypes.func,
  instancedata: PropTypes.string,
  isFetchingMedias: PropTypes.bool,
  isFetchingProducts: PropTypes.bool,
  locale: PropTypes.string,
  medias: PropTypes.array,
  mediasClassficationIds: PropTypes.array,
  nbMediasResults: PropTypes.number,
  nbProductsResults: PropTypes.number,
  products: PropTypes.array,
  searchInput: PropTypes.string
}

const mapStateToProps = (state) => ({
  locale: state.i18n.locale,
  searchInput: state.search.searchInput,
  nbProductsResults: state.search.products.nbresults,
  products: state.search.products.data,
  nbMediasResults: state.search.medias.nbresults,
  medias: state.search.medias.data,
  isFetchingProducts: state.search.products.isFetching,
  isFetchingMedias: state.search.medias.isFetching,
  audience: state.adminReducer.useraudience,
  classificationIds: state.canal.classificationIds,
  mediasClassficationIds: state.classification_medias.classificationIds,
  instancedata: state.adminReducer.instancedata
})

export default connect(mapStateToProps)(Search)
