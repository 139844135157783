import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import '../../styles/components/ProductPage/MultiProductImageCarousel.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const MultiProductImageCarousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.whereFrom !== 'VisualizeMedia') {
      let imgs = [];
      Object.entries(props.assetsData).forEach((assets, index) => {
        if (assets[0] === 'article_images_media') {
          assets[1].map((assetData) =>
            imgs.push([
              assetData['name'],
              assetData['url'],
              assetData['thumbnailUrl'],
            ])
          );
        }
      });
      setImages(imgs);
      if (imgs.length > 0) {
        props.setCurrentImageUrl(imgs[currentIndex][1]);
      }
    } else {
      let imgs = [];
      Object.entries(props.assetsData).forEach((assets, index) => {
        assets[1].map((assetData) =>
          imgs.push([
            assetData['name'],
            assetData['url'],
            assetData['thumbnailUrl'],
          ])
        );
      });
      setImages(imgs);
      if (imgs.length > 0) {
        props.setCurrentImageUrl(imgs[currentIndex][1]);
      }
    }
  }, [props]);

  function handleImageClick(image, index) {
    setCurrentIndex(index);
    props.setCurrentImageUrl(image[1]);
  }

  function handleLeftArrowClick() {
    if (currentIndex === 0) {
      props.setCurrentImageUrl(images[images.length - 1][1]);
      setCurrentIndex(images.length - 1);
      if (props.whereFrom === 'VisualizeMedia') {
        props.setValueFromChild(images.length - 1);
      }
    } else {
      props.setCurrentImageUrl(images[currentIndex - 1][1]);
      setCurrentIndex(currentIndex - 1);
      if (props.whereFrom === 'VisualizeMedia') {
        props.setValueFromChild(currentIndex - 1);
      }
    }
  }

  function handleRightArrowClick() {
    if (currentIndex === images.length - 1) {
      props.setCurrentImageUrl(images[0][1]);
      setCurrentIndex(0);
      if (props.whereFrom === 'VisualizeMedia') {
        props.setValueFromChild(0);
      }
    } else {
      props.setCurrentImageUrl(images[currentIndex + 1][1]);
      setCurrentIndex(currentIndex + 1);
      if (props.whereFrom === 'VisualizeMedia') {
        props.setValueFromChild(currentIndex + 1);
      }
    }
  }

  return (
    <div
      className={
        props.whereFrom !== 'VisualizeMedia'
          ? 'mpic-container'
          : 'mpic-container-secondary'
      }
      style={images.length === 0 ? { display: 'none' } : {}}
    >
      <Carousel
        itemWidth={props.whereFrom !== 'VisualizeMedia' ? 85 : 77}
        arrowLeft={
          <div
            className={
              props.whereFrom !== 'VisualizeMedia'
                ? 'mpic-arrow'
                : 'mpic-arrow-secondary'
            }
            onClick={handleLeftArrowClick}
          >
            <FontAwesomeIcon style={{ color: 'white' }} icon={faChevronLeft} />
          </div>
        }
        arrowRight={
          <div
            className={
              props.whereFrom !== 'VisualizeMedia'
                ? 'mpic-arrow'
                : 'mpic-arrow-secondary'
            }
            onClick={handleRightArrowClick}
          >
            <FontAwesomeIcon style={{ color: 'white' }} icon={faChevronRight} />
          </div>
        }
        addArrowClickHandler
        clickToChange
        infinite={images.length > 1}
        centered={images.length === 1}
      >
        {images.map((image, index) => {
          let extension = image[1]
            .substring(0, image[1].lastIndexOf('.'))
            .substring(image[1].lastIndexOf('-') + 1, image[1].length);
          return index === currentIndex ? (
            <img
              onClick={() => handleImageClick(image, index)}
              key={index}
              className={
                props.whereFrom !== 'VisualizeMedia'
                  ? 'mpic-carouselimg-active'
                  : 'mpic-carouselimg-active-secondary'
              }
              src={image[2]}
            />
          ) : (
            <img
              onClick={() => handleImageClick(image, index)}
              key={index}
              className={
                props.whereFrom !== 'VisualizeMedia'
                  ? 'mpic-carouselimg'
                  : 'mpic-carouselimg-secondary'
              }
              src={image[2]}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    assetsData: state.product.assetsData.assets,
  };
}

export default connect(mapStateToProps)(MultiProductImageCarousel);
