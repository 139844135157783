import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../../styles/components/Search/SearchFilters.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { fetchSearchResults } from '../../redux/actions/searchActions';
import MultiSelect from '@khanacademy/react-multi-select';
import { Translate } from 'react-redux-i18n';

const SearchFilters = (props) => {
  const [documentTypesOptions, setDocumentTypesOptions] = useState();
  const [attributeSetsOptions, setAttributeSetsOptions] = useState();
  const [classificationsOptions, setClassificationsOptions] = useState();
  const [
    mediasClassificationsOptions,
    setMediasClassificationsOptions,
  ] = useState();
  const [startCreationDate, setStartCreationDate] = useState(null);
  const [endCreationDate, setEndCreationDate] = useState(null);
  const [startUpdateDate, setStartUpdateDate] = useState(null);
  const [endUpdateDate, setEndUpdateDate] = useState(null);
  const [selectedDocumentTypesIds, setSelectedDocumentTypesIds] = useState([]);
  const [selectedAttributeSetsIds, setSelectedAttributeSetsIds] = useState([]);
  const [selectedClassificationsIds, setSelectedClassificationsIds] = useState(
    []
  );
  const [
    selectedMediasClassificationsIds,
    setSelectedMediasClassificationsIds,
  ] = useState([]);
  const [initialRender, setInitialRender] = useState(true);

  var I18n = require('react-redux-i18n').I18n;

  useEffect(() => {
    setAttributeSetsOptions([]);
    setDocumentTypesOptions(props.documentTypes);
    setSelectedClassificationsIds(
      props.classificationIds.map((item) => item['value'])
    );
    setSelectedMediasClassificationsIds(
      props.mediasClassificationIds.map((item) => item['value'])
    );
    setClassificationsOptions(props.classificationIds);
    setMediasClassificationsOptions(props.mediasClassificationIds);
  }, []);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      sendSearch(
        props.pageNumber,
        selectedDocumentTypesIds,
        selectedAttributeSetsIds,
        selectedClassificationsIds,
        selectedMediasClassificationsIds
      );
    }
  }, [props.pageNumber]);

  useEffect(() => {
    if (initialRender) {
    } else {
      if (props.pageNumber === 1) {
        sendSearch(
          props.pageNumber,
          selectedDocumentTypesIds,
          selectedAttributeSetsIds,
          selectedClassificationsIds,
          selectedMediasClassificationsIds
        );
      } else {
        props.setPageNumberFromChild(1);
      }
    }
  }, [startCreationDate, endCreationDate, startUpdateDate, endUpdateDate]);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function sendSearch(
    pageNumber,
    documentTypesIds,
    attributeSetsIds,
    classificationsIds,
    mediasClassificationsIds
  ) {
    let dateCreatedAfter =
      startCreationDate !== null ? formatDate(startCreationDate) : null;
    let dateCreatedBefore =
      endCreationDate !== null ? formatDate(endCreationDate) : null;
    let dateModifiedAfter =
      startUpdateDate !== null ? formatDate(startUpdateDate) : null;
    let dateModifiedBefore =
      endUpdateDate !== null ? formatDate(endUpdateDate) : null;
    let dates = {
      dateCreatedAfter: dateCreatedAfter,
      dateCreatedBefore: dateCreatedBefore,
      dateModifiedAfter: dateModifiedAfter,
      dateModifiedBefore: dateModifiedBefore,
    };
    props.dispatch(
      fetchSearchResults(
        props.searchInput,
        dates,
        documentTypesIds,
        attributeSetsIds,
        classificationsIds,
        mediasClassificationsIds,
        pageNumber,
        props.locale,
        props.instancedata.id
      )
    );
  }

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: 225,
    }),
    control: (provided, state) => ({
      ...provided,
      height: 40,
      borderColor: state.isFocused ? 'rgb(120, 192, 8)' : 'hsl(0,0%,80%)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? 'rgb(120, 192, 8)' : 'hsl(0,0%,80%)',
      },
    }),
    input: (provided, state) => ({}),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 14,
      color: 'rgb(170, 170, 170)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      // height: 40,
      alignItems: 'flex-start',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'rgb(51, 51, 51)',
      fontSize: 14,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      height: 30,
      alignItems: 'center',
    }),
    indicatorSeparator: (provided, state) => ({
      // ...provided,
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    dropdownIndicator: (provided, state) => ({
      // ...provided,
      boxSizing: 'border-box',
      borderColor: 'rgb(153, 153, 153) transparent transparent',
      borderStyle: 'solid',
      borderWidth: '5px 5px 2.5px',
      display: 'inline-block',
      height: 0,
      width: 0,
      position: 'relative',
      marginRight: 10,
      marginTop: 3,
      '& svg': { display: 'none' },
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
    }),
  };

  function handleSelectedDocumentTypesChange(selected) {
    props.setPageNumberFromChild(1);
    setSelectedDocumentTypesIds(selected !== null ? [selected.value] : []);
    setAttributeSetsOptions(
      selected !== null ? props.attributeSets[selected.value] : []
    );
    setSelectedAttributeSetsIds([]);
    sendSearch(
      1,
      [selected !== null ? selected.value : []],
      selectedAttributeSetsIds,
      selectedClassificationsIds,
      selectedMediasClassificationsIds
    );
  }

  function handleSelectedAttributeSetsChange(selected) {
    props.setPageNumberFromChild(1);
    setSelectedAttributeSetsIds(selected);
    sendSearch(
      1,
      selectedDocumentTypesIds,
      selected,
      selectedClassificationsIds,
      selectedMediasClassificationsIds
    );
  }

  function handleSelectedClassificationsChange(selected) {
    props.setPageNumberFromChild(1);
    setSelectedClassificationsIds(selected);
    sendSearch(
      1,
      selectedDocumentTypesIds,
      selectedAttributeSetsIds,
      selected,
      selectedMediasClassificationsIds
    );
  }

  function handleSelectedMediasClassificationsChange(selected) {
    props.setPageNumberFromChild(1);
    setSelectedMediasClassificationsIds(selected);
    sendSearch(
      1,
      selectedDocumentTypesIds,
      selectedAttributeSetsIds,
      selectedClassificationsIds,
      selected
    );
  }

  if (
    documentTypesOptions !== undefined &&
    attributeSetsOptions !== undefined &&
    classificationsOptions !== undefined &&
    mediasClassificationsOptions !== undefined
  ) {
    if (props.type === 'Products') {
      return (
        <div className='sf-container'>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.documentType' />
            </p>
            <Select
              styles={customStyles}
              placeholder={I18n.t('search.filters.doSelect')}
              className='basic-single'
              classNamePrefix='select'
              // defaultValue={options[0]}
              isClearable={true}
              isSearchable={true}
              options={documentTypesOptions}
              // styles={customStyles}
              onChange={(e) => handleSelectedDocumentTypesChange(e)}
            />
            {/* <MultiSelect
              options={documentTypesOptions}
              selected={selectedDocumentTypesIds}
              onSelectedChanged={(selected) =>
                handleSelectedDocumentTypesChange(selected)
              }
              overrideStrings={{
                selectSomeItems: 'Sélectionner...',
                allItemsAreSelected: 'Tous les éléments sont sélectionnés',
                selectAll: 'Sélectionner tout',
                search: 'Chercher',
              }}
            /> */}
          </div>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.attributeSet' />
            </p>
            <MultiSelect
              options={attributeSetsOptions}
              selected={selectedAttributeSetsIds}
              onSelectedChanged={(selected) =>
                handleSelectedAttributeSetsChange(selected)
              }
              overrideStrings={{
                selectSomeItems: I18n.t('search.filters.doSelect'),
                allItemsAreSelected: I18n.t('search.filters.allSelected'),
                selectAll: I18n.t('search.filters.selectAll'),
                search: I18n.t('search.filters.doFilter'),
              }}
            />
          </div>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.creationDate' />
            </p>
            <div className='sf-date-container'>
              <DatePicker
                placeholderText={I18n.t('search.filters.start')}
                dateFormat='dd/MM/yyyy'
                selected={startCreationDate}
                onChange={(date) => setStartCreationDate(date)}
              />
              <div style={{ marginLeft: 10 }}></div>
              <DatePicker
                placeholderText={I18n.t('search.filters.end')}
                dateFormat='dd/MM/yyyy'
                selected={endCreationDate}
                onChange={(date) => setEndCreationDate(date)}
              />
            </div>
          </div>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.updateData' />
            </p>
            <div className='sf-date-container'>
              <DatePicker
                placeholderText={I18n.t('search.filters.start')}
                dateFormat='dd/MM/yyyy'
                selected={startUpdateDate}
                onChange={(date) => setStartUpdateDate(date)}
              />
              <div style={{ marginLeft: 10 }}></div>
              <DatePicker
                placeholderText={I18n.t('search.filters.end')}
                dateFormat='dd/MM/yyyy'
                selected={endUpdateDate}
                onChange={(date) => setEndUpdateDate(date)}
              />
            </div>
          </div>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.classification' />
            </p>
            <MultiSelect
              options={classificationsOptions}
              selected={selectedClassificationsIds}
              onSelectedChanged={(selected) =>
                handleSelectedClassificationsChange(selected)
              }
              overrideStrings={{
                selectSomeItems: I18n.t('search.filters.doSelect'),
                allItemsAreSelected: I18n.t('search.filters.allSelected'),
                selectAll: I18n.t('search.filters.selectAll'),
                search: I18n.t('search.filters.doFilter'),
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className='sf-container'>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.creationDate' />
            </p>
            <div className='sf-date-container'>
              <DatePicker
                placeholderText={I18n.t('search.filters.start')}
                dateFormat='dd/MM/yyyy'
                selected={startCreationDate}
                onChange={(date) => setStartCreationDate(date)}
              />
              <div style={{ marginLeft: 10 }}></div>
              <DatePicker
                placeholderText={I18n.t('search.filters.end')}
                dateFormat='dd/MM/yyyy'
                selected={endCreationDate}
                onChange={(date) => setEndCreationDate(date)}
              />
            </div>
          </div>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.updateData' />
            </p>
            <div className='sf-date-container'>
              <DatePicker
                placeholderText={I18n.t('search.filters.start')}
                dateFormat='dd/MM/yyyy'
                selected={startUpdateDate}
                onChange={(date) => setStartUpdateDate(date)}
              />
              <div style={{ marginLeft: 10 }}></div>
              <DatePicker
                placeholderText={I18n.t('search.filters.end')}
                dateFormat='dd/MM/yyyy'
                selected={endUpdateDate}
                onChange={(date) => setEndUpdateDate(date)}
              />
            </div>
          </div>
          <div className='sf-element'>
            <p className='sf-heading'>
              <Translate value='search.filters.classification' />
            </p>
            <MultiSelect
              options={mediasClassificationsOptions}
              selected={selectedMediasClassificationsIds}
              onSelectedChanged={(selected) =>
                handleSelectedMediasClassificationsChange(selected)
              }
              overrideStrings={{
                selectSomeItems: 'search.filters.doSelect',
                allItemsAreSelected: 'search.filters.allSelected',
                selectAll: 'search.filters.selectAll',
                search: 'search.filters.doFilter',
              }}
            />
          </div>
        </div>
      );
    }
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    searchInput: state.search.searchInput,
    locale: state.i18n.locale,
    audience: state.adminReducer.useraudience,
    classificationIds: state.canal.classificationIds,
    mediasClassificationIds: state.classification_medias.classificationIds,
    documentTypes: state.search.documentTypes,
    attributeSets: state.search.attributeSets,
    instancedata: state.adminReducer.instancedata,
  };
}

export default connect(mapStateToProps)(SearchFilters);
