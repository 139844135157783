import axios from 'axios'
import { REQUEST_PRODUCTS_MAJ, REQUEST_MEDIAS_MAJ, RECEIVE_PRODUCTS_MAJ, RECEIVE_MEDIAS_MAJ } from './types'

export const fetchDocumentsMAJ = (classificationIds, mediasClassificationIds, page, limit, instancename) => (
  dispatch
) => {
  dispatch(fetchProductsMAJ(classificationIds, page, limit, instancename))
  dispatch(fetchMediasMAJ(mediasClassificationIds, page, limit, instancename))
}

export const fetchProductsMAJ = (classificationIds, page, limit, instancename) => (dispatch) => {
  classificationIds = 130 < classificationIds.length ? classificationIds.slice(0, 130) : classificationIds
  dispatch(requestProductsMAJ())
  return axios
    .get(`/documents?order%5BdateModified%5D=DESC&page=${page}&limit=${limit}`)
    .then((response) => {
      dispatch(receiveProductsMAJ(response.data['hydra:member'], response.data['hydra:totalItems']))
    })
    .catch((err) => {
      console.log(err)
    })
}

export const fetchMediasMAJ = (mediasClassificationIds, page, limit, instancename) => (dispatch) => {
  mediasClassificationIds =
    130 < mediasClassificationIds.length ? mediasClassificationIds.slice(0, 130) : mediasClassificationIds
  dispatch(requestMediasMAJ())
  return axios
    .get(`/assets?order%5BdateModified%5D=DESC&page=${page}&limit=${limit}`)
    .then((response) => {
      dispatch(receiveMediasMAJ(response.data['hydra:member'], response.data['hydra:totalItems']))
    })
    .catch((err) => {
      console.log(err)
    })
}

const requestProductsMAJ = () => ({
  type: REQUEST_PRODUCTS_MAJ
})

const receiveProductsMAJ = (data, length) => ({
  type: RECEIVE_PRODUCTS_MAJ,
  payload: { data, length }
})

const requestMediasMAJ = () => ({
  type: REQUEST_MEDIAS_MAJ
})

const receiveMediasMAJ = (data, length) => ({
  type: RECEIVE_MEDIAS_MAJ,
  payload: { data, length }
})
