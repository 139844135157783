import axios from 'axios'
import {
  REQUEST_MEDIAS_CLASSIFICATION,
  RECEIVE_MEDIAS_CLASSIFICATION,
  REQUEST_MEDIAS_CLASSIFICATION_FAILED,
  UPDATE_MEDIAS_PATH,
  UPDATE_MEDIAS_VISIBLE_PATH_ITEMS,
  UPDATE_MEDIAS_CURRENT_PATH_ITEMS,
  SET_CURRENT_PATH_ASSETS_LENGTH,
  ADD_CURRENT_PATH_ASSET,
  RESET_CURRENT_PATH_ASSETS,
  SET_CURRENT_ASSETS_CLASSIFICATION_ID,
  SET_MEDIAS_TREEVIEW_EXPANDED,
  REQUEST_MEDIAS,
  RECEIVE_MEDIAS,
  REQUEST_MEDIAS_FAILED,
  ADD_MEDIA_ATTRIBUTE_TO_MAPPING,
  RECEIVE_MEDIAS_CLASSIFICATION_IDS,
  SET_MEDIAS_IDS_TO_IMAGES
} from './types'

export const createArborescenceList = (data, locale) =>
  data.map((item) =>
    !Array.isArray(item.fieldData)
      ? item.children !== undefined && 0 < item.children.length
        ? [item.fieldData.quable_classification_name.values[locale], createArborescenceList(item.children, locale)]
        : item.fieldData.quable_classification_name.values[locale]
      : []
  )

export const createArborescenceListOfIds = (data, locale) =>
  data.map((item) =>
    item.children !== undefined && 0 < item.children.length
      ? [
          {
            value: item.id,
            label: item.fieldData.quable_classification_name.values[locale]
          },
          createArborescenceListOfIds(item.children, locale)
        ]
      : {
          value: item.id,
          label: item.fieldData.quable_classification_name.values[locale]
        }
  )

export const requestMedias = () => ({
  type: REQUEST_MEDIAS,
  receivedAt: Date.now()
})

export const receiveMedias = () => ({
  type: RECEIVE_MEDIAS,
  receivedAt: Date.now()
})

export const fetchMedias = (locale, instancename) => (dispatch) => {
  dispatch(requestMediasClassification())
  return axios
    .get(`/catalogs/dam/classifications?classification_id=dam`)
    .then((response) => {
      const newButtons = buttonsSetup(response.data['hydra:member'][0].children, '', locale)
      const newPath = pathSetup([], locale)
      dispatch(updateMediasCurrentPathItems([['Medias', newButtons]]))
      dispatch(updateMediasVisiblePathItems(newButtons))
      dispatch(updateMediasPath(newPath))
      dispatch(setCurrentAssetsClassificationId(null))
      dispatch(
        receiveClassificationIds(
          createArborescenceListOfIds(response.data['hydra:member'][0].children, locale).flat(Infinity)
        )
      )
      dispatch(receiveMediasClassification(createArborescenceList(response.data['hydra:member'][0].children, locale)))
      dispatch(setMediasAttributesMapping(locale, instancename))
      return axios.get(`/classifications?catalogs.id=` + `dam`)
    })
    .then((response) => axios.get(`dam&limit=${response.data['hydra:totalItems']}&pagination=false`))
    .then((response) => {
      const mediasIdsToImages = {}
      response.data['hydra:member'].map((classification) => {
        mediasIdsToImages[classification.id] = classification.mainAssetUrl
      })
      dispatch(setMediasIdsToImages(mediasIdsToImages))
    })
    .catch((err) => {
      dispatch(requestMediasClassificationFailed(err))
    })
}

export const updateMediasPath = (path) => ({
  type: UPDATE_MEDIAS_PATH,
  payload: path,
  changedAt: Date.now()
})

export const updateMediasVisiblePathItems = (items) => ({
  type: UPDATE_MEDIAS_VISIBLE_PATH_ITEMS,
  payload: items,
  changedAt: Date.now()
})

export const updateMediasCurrentPathItems = (items) => ({
  type: UPDATE_MEDIAS_CURRENT_PATH_ITEMS,
  payload: items,
  changedAt: Date.now()
})

export const initializeItemsContainerAction = (id, nbPage, nbValues, instancename) => (dispatch) => {
  dispatch(requestMedias())
  return axios
    .get(`/assets?classifications.id=${id}&page=${nbPage}&limit=${nbValues}`)
    .then((response) => {
      if (0 === response.data['hydra:totalItems']) {
        dispatch(receiveMedias())
      }
      dispatch(resetCurrentPathAssets())
      dispatch(setCurrentPathAssetsLength(response.data['hydra:totalItems']))

      response.data['hydra:member'].forEach((item, index) => {
        dispatch(addCurrentPathAsset(item))
        if (
          index === response.data['hydra:member'].length - 1 ||
          response.data['hydra:member'].length * nbPage - (response.data['hydra:member'].length - index) ===
            response.data['hydra:totalItems'] - 1
        ) {
          dispatch(receiveMedias())
        }
      })
    })
    .catch((err) => {
      dispatch(requestMediasFailed(err))
    })
}

export const setMediasTreeViewExpanded = (expanded) => ({
  type: SET_MEDIAS_TREEVIEW_EXPANDED,
  payload: expanded,
  changedAt: Date.now
})

export const setCurrentAssetsClassificationId = (id) => ({
  type: SET_CURRENT_ASSETS_CLASSIFICATION_ID,
  payload: id,
  addedAt: Date.now()
})

export const resetCurrentPathAssets = () => ({
  type: RESET_CURRENT_PATH_ASSETS,
  changedAt: Date.now()
})

export const resetMedias = () => ({
  type: 'RESET_MEDIAS',
  updatedAt: Date.now()
})

export const receiveClassificationIds = (data) => ({
  type: RECEIVE_MEDIAS_CLASSIFICATION_IDS,
  payload: data,
  receivedAt: Date.now()
})

// Internal Action

const setMediasIdsToImages = (data) => ({
  type: SET_MEDIAS_IDS_TO_IMAGES,
  payload: data,
  receivedAt: Date.now()
})

const addCurrentPathAsset = (asset) => ({
  type: ADD_CURRENT_PATH_ASSET,
  payload: asset,
  addedAt: Date.now()
})

const setCurrentPathAssetsLength = (totalLength) => ({
  type: SET_CURRENT_PATH_ASSETS_LENGTH,
  payload: totalLength,
  addedAt: Date.now()
})

const setMediasAttributesMapping = (locale, instancename) => (dispatch) =>
  axios.get(`/attributes?catalogs.id=pub&documentTypeId=media.quable_media&type=Asset`).then((response) => {
    dispatch(setTheMapping(response.data['hydra:member'], locale))
  })

const setTheMapping = (response, locale) => (dispatch) => {
  response.forEach((mediaAttribute) => {
    dispatch(addMediaAttributeToMapping([mediaAttribute.id, mediaAttribute.name[locale.replace('_', '-')]]))
  })
}

const addMediaAttributeToMapping = (data) => ({
  type: ADD_MEDIA_ATTRIBUTE_TO_MAPPING,
  payload: data,
  receivedAt: Date.now()
})

const requestMediasFailed = () => ({
  type: REQUEST_MEDIAS_FAILED,
  receivedAt: Date.now()
})

const requestMediasClassificationFailed = (err) => ({
  type: REQUEST_MEDIAS_CLASSIFICATION_FAILED,
  payload: err
})

const receiveMediasClassification = (data) => ({
  type: RECEIVE_MEDIAS_CLASSIFICATION,
  payload: data,
  receivedAt: Date.now()
})

const pathSetup = (followedPath, locale) =>
  followedPath.map((item) => item.fieldData.quable_classification_name.values[locale])

const requestMediasClassification = () => ({
  type: REQUEST_MEDIAS_CLASSIFICATION
})

const buttonsSetup = (buttons, parentIndex, locale) =>
  buttons.map((item, index) =>
    !Array.isArray(item.fieldData)
      ? [
          item.fieldData.quable_classification_name.values[locale],
          '',
          buttonsSetup(item.children, parentIndex.toString() + index.toString(), locale),
          item.id,
          item.dateCreated,
          item.dateModified,
          parentIndex.toString() + index.toString()
        ]
      : []
  )
