import axios from 'axios'

import { bool } from 'prop-types'
import React from 'react'
import {
  updateCanalPath,
  setCanalTreeViewExpanded,
  setCurrentCanalClassificationId,
  updateVisiblePathItems,
  updateCurrentPathItems,
  initializeItemsContainerAction
} from './canalActions'
import {
  ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING,
  RECEIVE_ATTRIBUTES,
  RECEIVE_DOCUMENT_TYPE_INFO,
  RECEIVE_PRODUCT,
  REQUEST_ATTRIBUTES,
  REQUEST_DOCUMENT_TYPE_INFO,
  REQUEST_PATH,
  REQUEST_PRODUCT_FAILED,
  RECEIVE_DOCUMENT_LINKS,
  RECEIVE_PATH,
  REQUEST_PATH_FAILED,
  REQUEST_ASSET_FAILED,
  RECEIVE_ASSETS,
  REQUEST_VARIANTS,
  REQUEST_DOCUMENT_LINKS,
  REQUEST_ASSETS,
  RECEIVE_VARIANTS,
  REQUEST_VARIANT_FAILED,
  REQUEST_DOCUMENT_LINK_FAILED,
  SET_ASSETS_LINK_TYPES_MAPPING,
  REQUEST_PRODUCT
} from './types'

// Exported Action

export const requestProduct = () => ({
  type: REQUEST_PRODUCT
})

export const receiveProduct = (data) => ({
  type: RECEIVE_PRODUCT,
  payload: data,
  receivedAt: Date.now()
})

export const requestAttributes = () => ({
  type: REQUEST_ATTRIBUTES
})

export const receiveAttributes = (data) => ({
  type: RECEIVE_ATTRIBUTES,
  payload: data,
  receivedAt: Date.now()
})

export const fetchDocumentTypeInfo = (locale, instancename) => (dispatch) =>
  axios
    .get(`/document-types?pagination=false`)
    .then((response) =>
      response.data['hydra:member'].forEach((documentType) => {
        if ('document' === documentType.objectType) {
          axios
            .get(`/document-types/${documentType.id}/attributes/tree-view`)
            .then((response) => {
              dispatch(requestDocumentTypeInfo(documentType.id))
              dispatch(setMapping(documentType.id, response.data['hydra:member'], locale, instancename))
              dispatch(receiveDocumentTypeInfo(documentType.id, response.data['hydra:member']))
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    )
    .catch((err) => {
      console.log(err)
    })

export const fetchProduct = (
  id,
  locale,
  pathItems,
  pathCurrentPathItems,
  classificationIds,
  canalId,
  instancename,
  documentTypeInfoMapping
) => (dispatch) => {
  dispatch(requestProduct())
  return axios
    .get(`/documents/${id}`)
    .then((response) => {
      dispatch(receiveProduct(response))
      dispatch(fetchPath(id, locale, pathItems, pathCurrentPathItems, canalId, instancename, classificationIds))
      dispatch(
        fetchAttributes(
          response.data.attributes,
          locale,
          instancename,
          documentTypeInfoMapping,
          response.data.documentType.id
        )
      )
      dispatch(fetchAssets(response.data.assetLinks, locale, instancename))
      dispatch(fetchVariants(response.data.variants, canalId, locale, instancename))
      dispatch(
        fetchDocumentLinks(
          response.data.documentLinks,
          id,
          classificationIds.map((item) => item['value']),
          locale,
          instancename
        )
      )
    })
    .catch((err) => {
      dispatch(requestProductFailed(id, err))
    })
}

// Internal Action

const requestDocumentTypeInfo = (data) => ({
  type: REQUEST_DOCUMENT_TYPE_INFO,
  payload: data,
  addedAt: Date.now()
})

const setMapping = (id, data, locale, instancename) => (dispatch) => {
  data.forEach((item) => {
    if (item.type !== undefined) {
      axios
        .get(`/attributes/${item.id}`)
        .then((response) => {
          dispatch(addDocumentTypeInfoAttributeToMapping(id, item.id, response.data.name[locale.replace('_', '-')]))
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      dispatch(setMapping(id, item.children, locale, instancename))
    }
  })
}

const receiveDocumentTypeInfo = (id, data) => ({
  type: RECEIVE_DOCUMENT_TYPE_INFO,
  payload: { id, data },
  addedAt: Date.now()
})

const addDocumentTypeInfoAttributeToMapping = (id, key, value) => ({
  type: ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING,
  payload: { id, data: [key, value] },
  addedAt: Date.now()
})

const fetchAttributes = (attributes, locale, instancename, documentTypeInfoMapping, documentType) => async (
  dispatch
) => {
  dispatch(requestAttributes())
  const flds = []
  await Promise.all(
    Object.entries(attributes).map(async (attribute) => {
      if (Object.keys(documentTypeInfoMapping[documentType]).includes(attribute[0])) {
        await axios.get(`/attributes/${attribute[0]}`).then((attributeName) => {
          if (!isNaN(attribute[1] || typeof attribute[1] === bool)) {
            flds.push([attributeName.data.name[locale.replace('_', '-')], attribute[1].toString(), attribute[0]])
          } else if (Array.isArray(attribute[1])) {
            flds.push([attributeName.data.name[locale.replace('_', '-')], attribute[1][0].labels[locale], attribute[0]])
          } else {
            flds.push([attributeName.data.name[locale.replace('_', '-')], attribute[1][locale], attribute[0]])
          }
        })
      }
    })
  )
  dispatch(receiveAttributes(flds))
}

const fetchPath = (id, locale, pathItems, pathCurrentPathItems, canalId, instancename, classificationIds) => (
  dispatch
) => {
  dispatch(requestPath())
  return axios
    .get(
      `${
        process.env.REACT_APP_QUABLE_API_PROTOCOL + instancename + process.env.REACT_APP_QUABLE_API_HOST
      }/classifications/tree-view`,
      {}
    )
    .then((response) => {
      dispatch(
        findPath(
          response.data['hydra:member'][0].children,
          locale,
          id,
          [],
          pathItems,
          pathCurrentPathItems,
          instancename,
          classificationIds
        )
      )
    })
}

const requestPath = () => ({
  type: REQUEST_PATH
})

const receivePath = (data) => ({
  type: RECEIVE_PATH,
  payload: data,
  receivedAt: Date.now()
})

const receiveDocumentLinks = (documentLinks) => ({
  type: RECEIVE_DOCUMENT_LINKS,
  payload: documentLinks,
  receivedAt: Date.now()
})

const requestPathFailed = () => ({
  type: REQUEST_PATH_FAILED
})

//TODO: unused code

const requestAssetFailed = () => ({
  type: REQUEST_ASSET_FAILED
})

const setAssetsLinkTypesMapping = (mapping) => ({
  type: SET_ASSETS_LINK_TYPES_MAPPING,
  payload: mapping,
  setAt: Date.now()
})

const requestAssets = () => ({
  type: REQUEST_ASSETS
})

const receiveAssets = (data) => ({
  type: RECEIVE_ASSETS,
  payload: data,
  receivedAt: Date.now()
})

const requestVariants = () => ({
  type: REQUEST_VARIANTS
})

const requestVariantFailed = (id, err) => ({
  type: REQUEST_VARIANT_FAILED,
  payload: [id, err]
})

const receiveVariants = (variants, variantsNamesToCodesMapping) => ({
  type: RECEIVE_VARIANTS,
  payload: {
    variants,
    variantsNamesToCodesMapping
  },
  receivedAt: Date.now()
})

const requestDocumentLinks = () => ({
  type: REQUEST_DOCUMENT_LINKS
})

const requestDocumentLinkFailed = (id, err) => ({
  type: REQUEST_DOCUMENT_LINK_FAILED,
  payload: [id, err]
})

const findExpanded = (pathFollowed, pathItems, expanded) => (dispatch) => {
  if (pathItems !== undefined) {
    for (let index = 0; index < pathItems.length; index++) {
      if (Array.isArray(pathItems[index]) && pathFollowed[0] === pathItems[index][0]) {
        pathFollowed.shift()
        expanded.push(index)
        dispatch(findExpanded(pathFollowed, pathItems[index][1], expanded))
        break
      } else if (pathFollowed[0] === pathItems[index]) {
        pathFollowed.shift()
        expanded.push(index)
        if (0 === pathFollowed.length) {
          dispatch(setCanalTreeViewExpanded(expanded))
        } else {
          dispatch(findExpanded(pathFollowed, pathItems[index], expanded))
        }
        break
      }
    }
  }
}

const findCurrentPathItems = (pathFollowed, pathCurrentPathItems, newCurrentPathItems, locale, instancename) => (
  dispatch
) => {
  if (0 < pathCurrentPathItems.length) {
    for (let i = 0; i < pathCurrentPathItems.length; i++) {
      if (pathCurrentPathItems[i][0] === pathFollowed[0]) {
        pathFollowed.shift()
        newCurrentPathItems.push([pathCurrentPathItems[i][0], pathCurrentPathItems[i][2], pathCurrentPathItems[i][3]])
        dispatch(
          findCurrentPathItems(pathFollowed, pathCurrentPathItems[i][2], newCurrentPathItems, locale, instancename)
        )
        break
      }
    }
  } else {
    dispatch(setCurrentCanalClassificationId(newCurrentPathItems[newCurrentPathItems.length - 1][3]))
    dispatch(updateCurrentPathItems(newCurrentPathItems))
    dispatch(updateVisiblePathItems([]))
    dispatch(
      initializeItemsContainerAction(
        newCurrentPathItems[newCurrentPathItems.length - 1][3],
        1,
        20,
        locale,
        instancename
      )
    )
  }
}

const findPath = (data, locale, id, pathFollowed, pathItems, pathCurrentPathItems, instancename, classificationIds) => (
  dispatch
) => {
  data.map((item) => {
    const newPathFollowed = [...pathFollowed]
    if (0 < item.children.length) {
      let newPathValue
      classificationIds.map((ci) => {
        if (item.fieldData.quable_classification_name.values[locale] === ci.label) {
          newPathValue = ci.value
        }
      })
      newPathFollowed.push(newPathValue)
      dispatch(
        findPath(
          item.children,
          locale,
          id,
          newPathFollowed,
          pathItems,
          pathCurrentPathItems,
          instancename,
          classificationIds
        )
      )
    } else {
      let newPathValue
      classificationIds.map((ci) => {
        if (item.fieldData.quable_classification_name.values[locale] === ci.label) {
          newPathValue = ci.value
        }
      })
      newPathFollowed.push(newPathValue)
      axios.get(`/documents?classifications.id=${item.id}&page=1&limit=1&pagination=true`).then((response) => {
        if (0 < response.data['hydra:totalItems']) {
          axios
            .get(
              `${
                process.env.REACT_APP_QUABLE_API_PROTOCOL + instancename + process.env.REACT_APP_QUABLE_API_HOST
              }/documents?classifications.id=${item.id}&page=1&limit=${
                response.data['hydra:totalItems']
              }pagination=true`,
              {}
            )
            .then((response) => {
              let found = false
              response.data['hydra:member'].map((product, index) => {
                if (product.id === id) {
                  found = true
                  dispatch(updateCanal(newPathFollowed, pathItems, pathCurrentPathItems, locale, instancename))
                  newPathFollowed.unshift('Produits')
                  dispatch(receivePath(newPathFollowed))
                } else if (index === response.data['hydra:member'].length - 1 && false === found) {
                  dispatch(requestPathFailed())
                }
              })
            })
        }
      })
    }
  })
}

const updateCanal = (pathFollowed, pathItems, pathCurrentPathItems, locale, instancename) => (dispatch) => {
  dispatch(updateCanalPath(['Produits', ...pathFollowed]))
  dispatch(findExpanded([...pathFollowed], pathItems, []))
  dispatch(
    findCurrentPathItems([...pathFollowed], pathCurrentPathItems[0][1], [pathCurrentPathItems[0]], locale, instancename)
  )
}

const fetchAssets = (assetLinks, locale, instancename) => async (dispatch) => {
  dispatch(requestAssets())
  // dispatch(requestAssetsLinkTypes());
  const assetLinksTypesMapping = {}
  const assetLinksTypes = {}
  await Promise.all(
    assetLinks.map(async (assetLink) => {
      await axios
        .get(
          `${
            process.env.REACT_APP_QUABLE_API_PROTOCOL + instancename + process.env.REACT_APP_QUABLE_API_HOST
          }/link-types/${assetLink.linkType.id}`,
          {}
        )
        .then((response) => {
          if (Object.keys.assetLinksTypesMapping === undefined) {
            assetLinksTypesMapping[assetLink.linkType.id] = response.data.name[locale.replace('_', '-')]
            assetLinksTypes[assetLink.linkType.id] = []
          } else {
            if (!Object.keys(assetLinksTypesMapping).includes(response.data.name[locale.replace('_', '-')])) {
              assetLinksTypesMapping[assetLink.linkType.id] = response.data.name[locale.replace('_', '-')]
              assetLinksTypes[assetLink.linkType.id] = []
            }
          }
        })
    })
  )
  dispatch(setAssetsLinkTypesMapping(assetLinksTypesMapping))
  await Promise.all(
    assetLinks.map(async (assetLink) => {
      const instance = axios.create()
      await instance
        .get(`${process.env.REACT_APP_API_URL}/assets/${assetLink['target']['id']}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
          }
        })
        .then((asset) => {
          for (let i = 0; i < asset.data.documentLinks.length; i++) {
            if (assetLinksTypes[asset.data.documentLinks[i].linkType.id] !== undefined) {
              assetLinksTypes[asset.data.documentLinks[i].linkType.id].push({
                ...asset.data,
                id: asset.data['id'],
                slide: <img className="vm-image" src={asset.data['url']} />,
                thumbnail: <img className="vm-thumbnail" src={asset.data['url']} />,
                dateCreated: asset.data['dateCreated'].split('T')[0],
                dateModified: asset.data['dateModified'].split('T')[0],
                name: asset.data['name'],
                url: asset.data['url']
              })
            }
          }
        })
        .catch((err) => {
          dispatch(requestAssetFailed(assetLink['target']['id'], err))
        })
    })
  )
  dispatch(receiveAssets(assetLinksTypes))
}

const fetchVariants = (variants, canalId, locale, instancename) => async (dispatch) => {
  dispatch(requestVariants())
  const vnts = []
  const mapping = []
  await axios.get(`/attributes?documentTypeId=Sku`).then(async (response) => {
    response.data['hydra:member'].map((attribute) =>
      mapping.push([attribute.id, attribute.name[locale.replace('_', '-')]])
    )
    await Promise.all(
      variants.map(async (variant) => {
        await axios
          .get(`/variants/${variant.id}`)
          .then(async (response) => {
            if (response !== undefined) {
              vnts.push(response.data.attributes)
            }
          })
          .catch((err) => {
            dispatch(requestVariantFailed(variant.id, err))
          })
      })
    ).then(() => {
      dispatch(receiveVariants(vnts, mapping))
    })
  })
}

const fetchDocumentLinks = (documentLinks, originId, classificationIds, locale, instancename) => async (dispatch) => {
  const classificationIdsCall =
    classificationIds !== undefined ? classificationIds.map((item) => `&classifications.id%5B%5D=${item}`) : []
  dispatch(requestDocumentLinks())
  const docLinks = {}
  const options = {}
  const optionsIds = []
  await Promise.all(
    documentLinks.map(async (documentLink) => {
      if (optionsIds.includes(documentLink.linkType.id)) {
      } else {
        optionsIds.push(documentLink.linkType.id)
        await axios.get(`/link-types/${documentLink.linkType.id}`).then(async (response) => {
          options[documentLink.linkType.id] = response.data.name[locale.replace('_', '-')]
          'modele_vente' === response.data.parent.documentTypeId
            ? await axios
                .get(
                  `/documents?documentLinks.${
                    documentLink.linkType.id
                  }.origin.id=${originId}${classificationIdsCall.join('')}`
                )
                .then(async (response) => {
                  docLinks[documentLink.linkType.id] = {
                    products: response.data['hydra:member'],
                    productsShortcut: response.data['hydra:member']
                  }
                })
            : await axios
                .get(
                  `/documents?documentLinks.${
                    documentLink.linkType.id
                  }.target.id=${originId}${classificationIdsCall.join('')}`
                )
                .then(async (response) => {
                  docLinks[documentLink.linkType.id] = {
                    products: response.data['hydra:member'],
                    productsShortcut: response.data['hydra:member']
                  }
                })
        })
      }
    })
  ).then(() => {
    dispatch(
      receiveDocumentLinks({
        documentLinks: docLinks,
        documentLinksNamesToCodesMapping: options
      })
    )
  })
}

const requestProductFailed = (id, err) => ({
  type: REQUEST_PRODUCT_FAILED,
  payload: [id, err]
})
