// CANAL ACTION TYPES

export const REQUEST_CANAL = 'REQUEST_CANAL'
export const RECEIVE_CANAL = 'RECEIVE_CANAL'
export const REQUEST_CANAL_FAILED = 'REQUEST_CANAL_FAILED'
export const UPDATE_PATH = 'UPDATE_PATH'
export const UPDATE_VISIBLE_PATH_ITEMS = 'UPDATE_VISIBLE_PATH_ITEMS'
export const UPDATE_CURRENT_PATH_ITEMS = 'UPDATE_CURRENT_PATH_ITEMS'
export const SET_CURRENT_CANAL_CLASSIFICATION_ID = 'SET_CURRENT_CANAL_CLASSIFICATION_ID'
export const SET_CURRENT_PATH_PRODUCTS_LENGTH = 'SET_CURRENT_PATH_PRODUCTS_LENGTH'
export const ADD_CURRENT_PATH_PRODUCT = 'ADD_CURRENT_PATH_PRODUCT'
export const RESET_CURRENT_PATH_PRODUCTS = 'RESET_CURRENT_PATH_PRODUCTS'
export const REQUEST_PRODUCTS_FAILED = 'REQUEST_PRODUCTS_FAILED'
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'
export const SET_CANAL_TREEVIEW_EXPANDED = 'SET_CANAL_TREEVIEW_EXPANDED'
export const RECEIVE_CLASSIFICATION_IDS = 'RECEIVE_CLASSIFICATION_IDS'
export const SET_IDS_TO_IMAGES = 'SET_IDS_TO_IMAGES'

// MEDIAS CLASSIFICATION TYPES

export const REQUEST_MEDIAS_CLASSIFICATION = 'REQUEST_MEDIAS_CLASSIFICATION'
export const RECEIVE_MEDIAS_CLASSIFICATION = 'RECEIVE_MEDIAS_CLASSIFICATION'
export const REQUEST_MEDIAS_CLASSIFICATION_FAILED = 'REQUEST_MEDIAS_CLASSIFICATION_FAILED'
export const UPDATE_MEDIAS_PATH = 'UPDATE_MEDIAS_PATH'
export const UPDATE_MEDIAS_VISIBLE_PATH_ITEMS = 'UPDATE_MEDIAS_VISIBLE_PATH_ITEMS'
export const UPDATE_MEDIAS_CURRENT_PATH_ITEMS = 'UPDATE_MEDIAS_CURRENT_PATH_ITEMS'
export const SET_CURRENT_ASSETS_CLASSIFICATION_ID = 'SET_CURRENT_ASSETS_CLASSIFICATION_ID'
export const SET_CURRENT_PATH_ASSETS_LENGTH = 'SET_CURRENT_PATH_ASSETS_LENGTH'
export const ADD_CURRENT_PATH_ASSET = 'ADD_CURRENT_PATH_ASSET'
export const RESET_CURRENT_PATH_ASSETS = 'RESET_CURRENT_PATH_ASSETS'
export const SET_MEDIAS_TREEVIEW_EXPANDED = 'SET_MEDIAS_TREEVIEW_EXPANDED'
export const LAST_ASSET_RECEIVED = 'LAST_ASSET_RECEIVED'
export const REQUEST_MEDIAS = 'REQUEST_MEDIAS'
export const RECEIVE_MEDIAS = 'RECEIVE_MEDIAS'
export const REQUEST_MEDIAS_FAILED = 'REQUEST_MEDIAS_CLASSIFICATION_FAILED'
export const RESET_MEDIAS = 'RESET_MEDIAS'
export const ADD_MEDIA_ATTRIBUTE_TO_MAPPING = 'ADD_MEDIA_ATTRUBUTE_TO_MAPPING'
export const RECEIVE_MEDIAS_CLASSIFICATION_IDS = 'RECEIVE_MEDIAS_CLASSIFICATION_IDS'
export const SET_MEDIAS_IDS_TO_IMAGES = 'SET_MEDIAS_IDS_TO_IMAGES'

// PRODUCT ACTION TYPES

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT'
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT'
export const REQUEST_PRODUCT_FAILED = 'REQUEST_PRODUCT_FAILED'
export const REQUEST_PATH = 'REQUEST_PATH'
export const REQUEST_PATH_FAILED = 'REQUEST_PATH_FAILED'
export const RECEIVE_PATH = 'RECEIVE_PATH'
export const REQUEST_ATTRIBUTES = 'REQUEST_ATTRIBUTES'
export const RECEIVE_ATTRIBUTES = 'RECEIVE_ATTRIBUTES'
export const REQUEST_ATTRIBUTES_FAILED = 'REQUEST_ATTRIBUTES_FAILED'
export const SET_ASSETS_LINK_TYPES_MAPPING = 'SET_ASSETS_LINK_TYPES_MAPPING'
export const REQUEST_ASSETS = 'REQUEST_ASSETS'
export const RECEIVE_ASSETS = 'RECEIVE_ASSETS'
export const REQUEST_ASSET_FAILED = 'REQUEST_ASSETS_FAILED'
export const REQUEST_VARIANTS = 'REQUEST_VARIANTS'
export const RECEIVE_VARIANTS = 'RECEIVE_VARIANTS'
export const REQUEST_VARIANT_FAILED = 'REQUEST_VARIANT_FAILED'
export const REQUEST_DOCUMENT_LINKS = 'REQUEST_DOCUMENT_LINKS'
export const RECEIVE_DOCUMENT_LINKS = 'RECEIVE_DOCUMENT_LINKS'
export const REQUEST_DOCUMENT_LINK_FAILED = 'REQUEST_DOCUMENT_LINK_FAILED'
export const REQUEST_DOCUMENT_TYPE_INFO = 'REQUEST_DOCUMENT_TYPE_INFO'
export const RECEIVE_DOCUMENT_TYPE_INFO = 'RECEIVE_DOCUMENT_TYPE_INFO'
export const ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING = 'ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING'

// CART ACTION TYPES

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const EMPTY_CART = 'EMPTY_CART'

// USER ACTION TYPES

export const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS'
export const ADD_ITEM_TO_WISHLIST = 'ADD_ITEM_TO_WISHLIST'
export const REMOVE_ITEM_FROM_WISHLIST = 'REMOVE_ITEM_FROM_WISHLIST'
export const STORE_USER_INSTANCE = 'STORE_USER_INSTANCE'
export const RESET_STORE = 'RESET_STORE'

// SEARCH ACTION TYPES

export const REQUEST_SEARCH_RESULTS = 'REQUEST_SEARCH_RESULTS'
export const REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED = 'REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED'
export const RECEIVE_PRODUCTS_SEARCH_RESULTS = 'RECEIVE_PRODUCTS_SEARCH_RESULTS'
export const REQUEST_MEDIAS_SEARCH_RESULTS_FAILED = 'REQUEST_MEDIAS_SEARCH_RESULTS_FAILED'
export const RECEIVE_MEDIAS_SEARCH_RESULTS = 'RECEIVE_MEDIAS_SEARCH_RESULTS'
export const REQUEST_ATTRIBUTE_SETS = 'REQUEST_ATTRIBUTE_SETS'
export const RECEIVE_ATTRIBUTE_SETS = 'RECEIVE_ATTRIBUTE_SETS'
export const REQUEST_DOCUMENT_TYPES = 'REQUEST_DOCUMENT_TYPES'
export const RECEIVE_DOCUMENT_TYPES = 'RECEIVE_DOCUMENT_TYPES'

// ADMIN USER ACTION TYPES

export const USER_LIST = 'USER_LIST'
export const USER_STATUS = 'USER_STATUS'
export const ADMIN_USER_INFO = 'ADMIN_USER_INFO'
export const ADMIN_UPDATE_USER_INFO = 'ADMIN_UPDATE_USER_INFO'
export const ADMIN_GET_ALL_AUDIENCES = 'ADMIN_GET_ALL_AUDIENCES'
export const ADMIN_UPDATE_AUDIENCE = 'ADMIN_UPDATE_AUDIENCE'
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER'
export const ADMIN_STORE_USER_AUDIENCE = 'ADMIN_STORE_USER_AUDIENCE'
export const ADMIN_GET_ALL_ATTRIBUTES = 'ADMIN_GET_ALL_ATTRIBUTES'
export const ADMIN_UPDATE_ATTRIBUTE = 'ADMIN_UPDATE_ATTRIBUTE'
export const ADMIN_INSTANCE_DATA = 'ADMIN_INSTANCE_DATA'

// HOMEPAGE TYPES

export const REQUEST_PRODUCTS_MAJ = 'REQUEST_PRODUCTS_MAJ'
export const REQUEST_MEDIAS_MAJ = 'REQUEST_MEDIAS_MAJ'
export const RECEIVE_PRODUCTS_MAJ = 'RECEIVE_PRODUCTS_MAJ'
export const RECEIVE_MEDIAS_MAJ = 'RECEIVE_MEDIAS_MAJ'

// LANGUAGES TYPES

export const SET_LANGUAGES = 'SET_LANGUAGES'
