import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ProductsBlocView, ProductsListView, ItemsContainer } from '../';
import '../../styles/components/ProductPage/RelatedMedia.css';
import { VisualizeMedia } from '../index.js';
import { connect } from 'react-redux';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { Translate } from 'react-redux-i18n';

const RelatedMedia = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [medias, setMedias] = useState([]);
  const [selectedItemsLength, setSelectedItemsLength] = useState(0);

  useEffect(() => {
    setMedias(props.assetsData);

    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, [props]);

  function setSelectedItemsFromChild(newItems) {
    setSelectedItems(newItems);
    let length = 0;
    Object.entries(newItems).map((linkType, index) => {
      linkType[1].map((itemIndex, index) => {
        length += 1;
      });
    });
    setSelectedItemsLength(length);
  }

  async function downloadSelectedMedia() {
    let selectedItemsNewFormat = [];

    Object.entries(selectedItems).map(async (linkType, index) => {
      linkType[1].map(async (itemIndex) => {
        selectedItemsNewFormat.push([linkType[0], itemIndex]);
      });
    });

    var zip = new JSZip();
    await Promise.all(
      selectedItemsNewFormat.map(async (item) => {
        return new Promise((resolve) => {
          JSZipUtils.getBinaryContent(
            process.env.REACT_APP_HOST +
              ':' +
              process.env.REACT_APP_PORT +
              '/' +
              props.assetsData[item[0]][item[1]].url,
            (err, data) =>
              resolve(
                zip.file(props.assetsData[item[0]][item[1]].name, data, {
                  binary: true,
                })
              )
          );
        });
      })
    );

    zip
      .generateAsync({
        type: 'blob',
      })
      .then(function (content) {
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(content));
        element.setAttribute('download', 'medias.zip');
        element.click();
      });
  }

  function handleScroll() {
    if (Object.entries(props.assetsData).length > 0) {
      let footer = document.getElementById('ic-footer');
      if (
        document.getElementById('rm-container-id') !== null &&
        document.getElementById('ic-check') !== null
      ) {
        let rmPositionY = document
          .getElementById('rm-container-id')
          .getBoundingClientRect().y;
        rmPositionY < 350 && document.getElementById('ic-check').checked
          ? footer.classList.add('ic-footerstyle')
          : footer.classList.remove('ic-footerstyle');
      }
    }
  }

  return (
    <div
      id='rm-container-id'
      className='rm-container'
      style={
        Object.keys(props.assetsData).length === 0 ? { display: 'none' } : {}
      }
    >
      <div className='rm-subcontainer'>
        <div className='rm-firstrow'>
          <h5 className='rm-firstrow-title'><Translate value='productPage.relatedMedia.title' /></h5>
          <VisualizeMedia
            trigger={
              <button className='rm-firstrow-link'><Translate value='productPage.relatedMedia.panorama' /></button>
            }
          />
        </div>
        <ItemsContainer
          star={false}
          whereFrom='RelatedMedia'
          currentElements={medias}
          currentButtonsLength={0}
          handleScroll={handleScroll}
          setSelectedItems={setSelectedItemsFromChild}
        />
      </div>
      <div id='ic-footer' className='ic-footer-hide'>
        <p className='ic-footerstyle-p'>
          {selectedItemsLength} <Translate value='productPage.relatedMedia.selected' />
        </p>
        <button
          className='ic-footerstyle-b'
          onClick={() => downloadSelectedMedia()}
        >
          <Translate value='productPage.relatedMedia.download' />
        </button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    assetsLinkTypesMapping: state.product.assetsLinkTypesMapping,
    assetsData: state.product.assetsData.assets,
  };
}

export default connect(mapStateToProps)(RelatedMedia);
