import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import {connect} from 'react-redux';

import {useAuth0} from '@auth0/auth0-react';
import {
    storeUserInfo,
    storeUserAudience,
    storeInstanceData
} from '../../redux/actions/adminAction';
import {registerUserInstance} from '../../redux/actions/userActions';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import '../../styles/components/adminPage/adminstyles.css';

const Authenticate = (props) => {
    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
        getIdTokenClaims,
        loginWithRedirect,
        isAuthenticated,
    } = useAuth0();

    let redirectPage = localStorage.getItem('ztoAppState')
        ? localStorage.getItem('ztoAppState')
        : '/homepage';

    const [isAccesDenied, setIsAccesDenied] = useState(false);
    const [isUnauthorised, setIsUnauthorised] = useState(false);
    const [loading, setLoading]=useState(true)
    const [returnState, setReturnState]=useState('/standby')


    let axiosConnect = null;

    /**
     * Create a new AXIOS connection with Auth0 user token if not already set
     * @param tokenRaw user AUTH0 token
     * @returns {*}
     */
    const initAxiosInstance = (tokenRaw) => {
        if (!axiosConnect)
            axiosConnect = axios.create({
                headers: {
                    Authorization: `Bearer ${tokenRaw}`,
                },
            });
        return axiosConnect;
    }


    /**
     * Get user infos from Quable APi
     * @param sub
     * @returns {Promise.<void>}
     */

    const getUserFromQuable = async(sub) => {
        let url =
            process.env.REACT_APP_QUABLE_API_PROTOCOL +
            instanceName() +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/users/' +
            sub;
        try {
            const userFromQuable = await axiosConnect.get(url);
            return userFromQuable;
        }
        catch (error) {
            if (error.response.status == 404) {
                registerUserInQuable();
            }

            else
                setIsAccesDenied(true);
        }
    }
    /**
     * If user does not exist lets create it in Quable API
     */
    const registerUserInQuable = () => {

        let url =
            process.env.REACT_APP_QUABLE_API_PROTOCOL +
            instanceName() +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/users/register'
        try {
            axiosConnect.post(url, {}, {});
            setReturnState("/standby")
            setLoading(false)
            // //  props.dispatch(storeuserinfo({status: 'standby'}));
        }
        catch (error) {
            console.log("Error ", error.response.status);
        }
    }
    /**
     * Return the target instance from hostname
     * @returns string Quable Instance name
     */

    const instanceName = () => {

        let instancename = window.location.hostname.split('.')[0];

        // Only for developement purpose to bypass hostname detection
        if (instancename == 'localhost' || instancename == 'recette-quable-portail' || instancename == 'dev-quable-portail') {
            instancename = 'sisley';
        }

        return instancename
    }

    /**
     *
     * @param auth0Token
     * @returns {Promise.<*>}
     */

    const getInstanceUserData = async(auth0Token) => {
        let instance_url =
            process.env.REACT_APP_QUABLE_API_PROTOCOL +
            instanceName() +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/user_instances/user=' +
            auth0Token.sub +
            ';instance=' +
            instanceName();

        try {
            const userQuableInstanceInfo = await axiosConnect.get(instance_url);

            if (userQuableInstanceInfo.data.status == "active") {
                const QuableInstanceData = await getInstanceData();
                setReturnState("/homepage");
                setLoading(false)
                return {QuableInstanceData: QuableInstanceData, userQuableInstanceInfo: userQuableInstanceInfo.data}
            }
            else {
                setReturnState("/standby");
                setLoading(false)
            }
        }
        catch (error) {
            setIsAccesDenied(true);
        }

    }

    /**
     *
     * @returns {Promise.<*>}
     */
    const getInstanceData = async() => {
        let instance_data_url = process.env.REACT_APP_QUABLE_API_PROTOCOL +
            instanceName() +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/instances/' + instanceName();

        try {
            const QuableInstanceData = await axiosConnect.get(instance_data_url);
            return QuableInstanceData.data;
        }
        catch (error) {
            console.log("Error ", error.response.status);
        }
    }

    /**
     *
     * @returns {Promise.<void>}
     */
    const getUserQuableInfos = async() => {
        console.log("CALL API")
        const auth0Connect = await getIdTokenClaims()
        console.log('auth0Connect : ',auth0Connect)
        initAxiosInstance(auth0Connect.__raw)
        try {
            const userFromQuable = await getUserFromQuable(auth0Connect.sub);
            if (userFromQuable) {

                const userQuableInfoInstance = await getInstanceUserData(auth0Connect);

                props.dispatch(storeUserInfo(userQuableInfoInstance.userQuableInstanceInfo, userFromQuable.data));
                if (userQuableInfoInstance.userQuableInstanceInfo.audience) {

                    props.dispatch(
                        storeUserAudience(auth0Connect, userQuableInfoInstance.userQuableInstanceInfo.audience)
                    );
                }
                props.dispatch(storeInstanceData(userQuableInfoInstance.QuableInstanceData))

            }

        }
        catch (error) {
            console.log("error : ", error)
        }
    };

    useEffect(() => {
        /*if (isAuthenticated) {
         callApi('redirect to page ');
         }*/
        async function getQuableData() {
            const quableData = await getUserQuableInfos()
        }

        getQuableData()

    }, []);


    return ((loading) ? (
            <div className='center'>
                <Loader type='TailSpin' color='black' height={100} width={100}/>
            </div>
        ) : (<Redirect to={returnState}/>));

    /*

     if ( isAccesDenied){
     returnState = <Redirect to='/accessdenied' />
     }
     if (isUnauthorised ){
     returnState = <Redirect to='/error' />
     }

     if (props.userstatus == 'active'){
     const rawRedirection = redirectPage.split('?')
     if(rawRedirection[0] == '/'){
     redirectPage = '/homepage'
     }
     returnState = <Redirect to={redirectPage} />;
     }
     if (props.userstatus == 'inactive')
     returnState = <Redirect to='/standby' />;
     if (props.userstatus == 'standby')
     returnState = <Redirect to='/standby' />;

     console.log("MOMO LOG :",returnState);
     */

    return returnState;
};

/*function mapStateToProps(state) {
 return {
 userstatus: state.adminReducer.quableuserinstance.status,
 gotQuableInfo: state.adminReducer.gotQuableInfo,
 };
 }*/

function mapStateToProps(state) {
    return {
        //locale: state.i18n.locale,
       //instancedata: state.adminReducer.instancedata,
        //quableuserinstance: state.adminReducer.quableuserinstance,
    };
}

export default connect(mapStateToProps)(Authenticate);
